import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import api from "../../api";
import moment from "moment";
import LoadingUI from "../../elements/LoadingUI";

const Container = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const Title = styled.h1`
  font-size: 18px;
  text-align: center;
  margin: 0;
  padding: 14px 10px;
  background: #1f576f;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  right: 0;
`;

const MessageContainer = styled.div`
  margin-top: 50px;
  flex-grow: 1;
  padding: 4px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  margin-bottom: 120px;
`;

const MessageItem = styled.div`
  margin-bottom: 8px;
  padding: 10px;
  border-radius: ${({ sender }) =>
        sender ? "1px 14px 14px 14px" : "14px 1px 14px 14px"};
  background-color: ${({ sender }) => (sender ? "#6596fe9e" : "#fd7f7f")};
  width: 80%;
  align-self: ${({ sender }) => (sender ? "flex-start" : "flex-end")};
  p {
    margin: 0;
  }
`;

const InputArea = styled.div`
  position: fixed;
  bottom: 0;
  width: 92%;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ddd;
  margin: auto;
`;

const InputField = styled.input`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  margin-top: 14px;
  padding: 14px;
  background-color: #0070f3;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: #005bb5;
  }
`;

export default function TicketSupportDetails() {
    const { id } = useParams();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);
    const messageContainerRef = useRef(null);
    useEffect(() => {
        const sessionKey = localStorage.getItem("sessionKey");
        const supportID = id;
        const fetchTickets = async () => {
            try {
                const response = await api.get("/api/support-query/details", {
                    params: { sessionKey, supportID },
                    headers: { "Cache-Control": "no-cache" },
                });

                if (response.data) {
                    setMessages(response.data.supportTicket);

                    // Scroll to bottom after a small delay to ensure rendering is complete
                    setTimeout(() => {
                        if (messageContainerRef.current) {
                            messageContainerRef.current.scrollTop =
                                messageContainerRef.current.scrollHeight;
                        }
                    }, 100);
                }
            } catch (err) {
                setError("Failed to fetch support chat data.");
            } finally {
                setLoading(false);
            }
        };
        fetchTickets();
    }, []);

    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop =
                messageContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSendMessage = async () => {
        const message = {
            QueryID: messages.length + 1,
            RespondedAt: null,
            RespondedBy: null,
            QueryDescription: newMessage,
            CreatedAt: Date.now(),
        };
        const data = {
            query: newMessage,
            sessionKey: localStorage.getItem("sessionKey"),
            supportID: +messages[0].SupportID,
        };
        setMessages((prev) => [...prev, message]);
        setNewMessage("");
        const response = await api.put("/api/update/support", data, {
            headers: { "Cache-Control": "no-cache" },
        });
        if (response.status === 200) {
            // showNotification("Success", "Support ticket updated successfully!", "success");
            // navigate("/support");
        }
    };
    if (loading) return (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingUI message={"Loading support data...please wait"} />
        </div>
      );
    return (
        <Container>
            <Title>theDataTasker Support</Title>
            <MessageContainer ref={messageContainerRef}>
                {messages.map((msg) => (
                    <MessageItem key={msg.id} sender={msg.RespondedBy}>
                        <p>
                            <strong>{msg.RespondedBy ? "Support" : "You"}:</strong>{" "}
                            {msg.QueryDescription}
                        </p>
                        <p
                            style={{
                                textAlign: "right",
                                padding: "4px 4px 0 4px",
                                fontSize: "14px",
                            }}
                        >
                            {moment(msg.RespondedBy ? msg.RespondedAt : msg.CreatedAt).format(
                                "DD/MM/YY h:mma"
                            )}
                        </p>
                    </MessageItem>
                ))}
                <div ref={messagesEndRef} />
            </MessageContainer>
            <InputArea>
                <InputField
                    type="text"
                    placeholder="Type your message here"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                />
                <SubmitButton onClick={handleSendMessage} disabled={!newMessage.trim()}>
                    Send Message
                </SubmitButton>
            </InputArea>
        </Container>
    );
}