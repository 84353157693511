import React, { useState } from "react";
import styled from "styled-components";
import { showNotification } from "../../actions/index.actions";
import api from "../../api";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  padding: 0px 10px;
`;

const Title = styled.h1`
  font-size: 18px;
  text-align: center;
  margin: 0;
  color: #000000db;
  border-bottom: 1px solid #e8e5e5;
  padding: 14px 10px;
  position: fixed;
  background: #1f576f;
  color: white;
  width: 100%;
  right: 0;
  z-index: 10;
  top: 0;
`;

const QueryList = styled.div`
  margin-top: 60px;
  width: 90%;
  h4 {
    margin: 0;
    padding: 5px 8px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #335cff82;
    color: black;
    background: #eff4ffa1;
    border-radius: 0 14px 14px 14px;
  }
`;

const QueryButton = styled.button`
  display: block;
  width: 100%;
  padding: 14px 10px;
  background-color: #f9f9f9;
  border: 1px solid #335cff26;
  border-radius: 2px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2sease;
  color: #0b78fe;
  font-size: 15px;
  &:hover {
    background-color: #ececec;
  }
`;

const SelectedArea = styled.div`
  margin-top: 14px;
  p {
    margin: 0;
    text-align: left;
    padding: 10px 14px;
    background: #f5f8fe;
    border-radius: 14px 1px 14px 14px;
    min-width: 20%;
    max-width: 80%;
    float: right;
    border: 1px solid #335cff82;
  }
`;

const InputField = styled.input`
padding: 14px 0px 14px 14px;
    width: 95%;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 14px;
    margin-right: 8px;
}
`;

const SubmitButton = styled.button`
  margin-top: 16px;
  padding: 12px 20px;
  background-color: #0564d2;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: #005bb5;
  }
`;

const predefinedQueries = [
    "Account & Login Issues (password resets, profile updates, etc.)",
    "Task-Related Queries (problems with Voice/Image/Video/Text tasks)",
    "Payment & Earnings (delayed payments, withdrawal issues)",
    "Task Acceptance/Rejection Disputes (appeals, clarifications)",
    "Other (anything not covered above)",
];

export default function CreateNewIssue() {
    const navigate = useNavigate();
    const [selectedQuery, setSelectedQuery] = useState(null);
    const [customQuery, setCustomQuery] = useState("");
    const [loading, setLoading] = useState(false);

    const handleQuerySelect = (query) => {
        setSelectedQuery(query);
    };

    const handleSubmit = async () => {
        const data = {
            query: selectedQuery === "Other (anything not covered above)" ? customQuery : selectedQuery,
            sessionKey: localStorage.getItem("sessionKey"),
        };
        if (!data.query) {
            showNotification(
                "Error",
                "Please describe your issue",
                "danger"
            );
            return
        }
        setLoading(true);
        try {
            const response = await api.post("/api/create/support", data, {
                headers: { "Cache-Control": "no-cache" },
            });
            if (response.status === 200) {
                showNotification(
                    "Success",
                    "Support ticket created successfully!",
                    "success"
                );
                // window.open(`/support`, "_self");
                navigate("/support");
            }
        } catch (error) {
            console.error("Error submitting support ticket:", error);
            showNotification(
                "Error",
                "Failed to create support ticket. Try again!",
                "danger"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <Title>theDataTasker support</Title>
            <QueryList>
                <h4>
                    Hi. we're here to help you! Select one of the options below or type
                    your query by clicking on other
                </h4>
                <div style={{ marginTop: "14px" }}>
                    {predefinedQueries.map((query) => (
                        <QueryButton key={query} onClick={() => handleQuerySelect(query)}>
                            {query}
                        </QueryButton>
                    ))}
                </div>
            </QueryList>
            {selectedQuery && (
                <SelectedArea>
                    <p>{selectedQuery}</p>
                    {selectedQuery === "Other (anything not covered above)" && (
                        <InputField
                            type="text"
                            placeholder="Describe your issue"
                            value={customQuery}
                            onChange={(e) => setCustomQuery(e.target.value)}
                        />
                    )}
                    <div>
                        <SubmitButton
                            onClick={handleSubmit}
                            disabled={
                                !selectedQuery ||
                                (selectedQuery === "Other" && !customQuery.trim()) ||
                                loading
                            }
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </SubmitButton>
                    </div>
                </SelectedArea>
            )}
        </Container>
    );
}
