// components/RatingCarousel.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-${(props) => props.activeIndex * 100}%);
`;

const CarouselCard = styled.div`
  flex: 0 0 100%;
  width: 100%;
`;

const CarouselDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#ffffff" : "#666666")};
  cursor: pointer;
  transition: background 0.3s ease;
`;

const RatingCarousel = ({
  totalData,
  voiceData,
  imageData,
  RatingSections,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  // Filter out undefined or empty data
  const cards = [
    { type: "total", data: totalData },
    voiceData?.attemptedTasks > 0 ? { type: "voice", data: voiceData } : null,
    imageData?.attemptedTasks > 0 ? { type: "image", data: imageData } : null,
  ].filter(Boolean);

  // Auto-scroll functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % cards.length);
    }, 5000); // Change card every 5 seconds

    return () => clearInterval(interval);
  }, [cards.length]);

  // Touch handlers for swipe
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 75) {
      // Swipe left
      setActiveIndex((current) =>
        current < cards.length - 1 ? current + 1 : current
      );
    }
    if (touchStart - touchEnd < -75) {
      // Swipe right
      setActiveIndex((current) => (current > 0 ? current - 1 : current));
    }
  };

  const getCardTitle = (type) => {
    switch (type) {
      case "voice":
        return "Voice Tasks Rating";
      case "image":
        return "Image Tasks Rating";
      default:
        return "Overall Rating";
    }
  };

  const handleCardClick = (type) => {
    let view = "notifications"; // default view
    switch (type) {
      case "voice":
        view = "audio";
        break;
      case "image":
        view = "image";
        break;
      default:
        view = "notifications";
    }
    window.open(`/tasker-rating-dashboard?view=${view}`, "_self");
  };

  return (
    <CarouselContainer>
      <CarouselTrack
        activeIndex={activeIndex}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {cards.map(({ type, data }, index) => (
          <CarouselCard key={type}>
            <RatingSections onClick={() => handleCardClick(type)}>
              <h4>
                {getCardTitle(type)}: {data.averageRating}
              </h4>
              <div>
                <div style={{ flexDirection: "column" }}>
                  <p>Attempted Tasks</p>
                  <span>{data.attemptedTasks}</span>
                </div>
                <div style={{ flexDirection: "column" }}>
                  <p>Pending Tasks</p>
                  <span>{data.pendingTaskCount}</span>
                </div>
              </div>
              <div>
                <div style={{ flexDirection: "column" }}>
                  <p>Accepted Tasks</p>
                  <span>{data.acceptedTasks}</span>
                </div>
                <div style={{ flexDirection: "column" }}>
                  <p>Rejected Tasks</p>
                  <span>{data.rejectedTasks}</span>
                </div>
              </div>
            </RatingSections>
          </CarouselCard>
        ))}
      </CarouselTrack>
      <CarouselDots>
        {cards.map((_, index) => (
          <Dot
            key={index}
            active={index === activeIndex}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </CarouselDots>
    </CarouselContainer>
  );
};

export default RatingCarousel;
