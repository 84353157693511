import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import NoDataFound from "../../../elements/NoDataFound";
import AnnotationEditor from "../AnnotationEditor";

const WrapperAnnotationTask = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;

  h4 {
    text-align: center;
    padding: 12px 10px;
    font-size: 30px;
    color: #490c6b;
    margin: 4px;
  }
  h2 {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    color: #062549;
    margin: 4px;
  }
  h3 {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    color: #062549;
    margin: 4px;
  }

  @media (min-width: 577px) {
    .task-content {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
      width: 100%;
      padding: 20px;
      max-width: 100%;
      box-sizing: border-box;
    }

    .instructions-section {
      width: 12%;
      min-width: 150px;
    }

    .image-section {
      width: 100%;
    }
  }

  @media (max-width: 576px) {
    h4 {
      font-size: 24px;
      padding: 4px 12px;
    }
    h3 {
      font-size: 16px;
    }
    h2 {
      font-size: 16px;
    }
  }
`;

const DescriptionSection = styled.div`
  background: #50af52;
  text-align: center;
  margin: 0;
  padding: 0;

  h3 {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    color: white;
    margin: 4px;

    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: white transparent;
    -webkit-overflow-scrolling: touch;
  }

  /* Webkit browsers (Chrome, Safari, etc.) */
  h3::-webkit-scrollbar {
    width: 8px;
  }

  h3::-webkit-scrollbar-thumb {
    background-color: white;
  }

  h3::-webkit-scrollbar-track {
    background-color: transparent;
  }

  h2 {
    font-size: 24px;
    border-radius: 0;
    background: transparent;
    margin: 0;
    padding: 6px 6px 2px 6px;
    color: white;
    font-weight: 600;

    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: white transparent;
  }

  /* Styles for screens up to 576px */
  @media (max-width: 576px) {
    h3 {
      font-size: 17px;
      margin: 0 4px 2px 4px;
      font-weight: 500;
      height: 50px;
      overflow: auto;
    }

    h2 {
      font-size: 18px;
      border-radius: 0;
      background: transparent;
      padding: 6px 6px 2px 6px;
    }
  }

  /* Styles for screens up to 450px */
  @media (max-width: 450px) {
    h3 {
      height: 40px;
      overflow: auto;
    }
  }

  @media (min-width: 577px) {
    .task-content {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
      width: 60%;
      padding: 20px;
      max-width: 80%;
      box-sizing: border-box;
    }

    .instructions-section {
      width: 10%;
      min-width: 150px;
    }

    .image-section {
      width: 100%;
    }
  }
`;

const ImagePreview = styled.div`
  width: 90%;
  max-width: 450px;
  height: calc(100vh - 300px); /* Subtract header + footer height */
  min-height: 200px;
  margin: 10px auto;
  text-align: center;
  background: white;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 2px;
  }

  @media (min-width: 577px) {
    width: 100%;
    max-width: none;
    height: calc(100vh - 180px); /* Increased height */
    margin: 0;
    padding: 20px; /* Increased padding */
  }

  @media (max-width: 576px) {
    width: 92%;
    height: calc(100vh - 320px); /* Slightly more space for smaller screens */
    margin: 8px auto;
    padding: 8px;
  }

  @media (max-height: 600px) {
    height: calc(100vh - 340px); /* Even more compact for very short screens */
    min-height: 150px;
    margin: 5px auto;
  }
`;

const AnnotationTaskContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-bottom: 70px; /* Add padding to prevent overlap with fixed bottom controls */
`;

const BottomControls = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  z-index: 1000;
`;

const AnnotateButton = styled.button`
  width: 100%;
  background: #1b4da3;
  color: white;
  padding: 12px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #153a7a;
  }

  &:active {
    background: #102e63;
  }
`;

const WrapperNextBtn = styled.div`
  width: 100%;
  border-top: 2px solid;
  background: black;

  button {
    margin: 6px;
    padding: 4px 8px;
    font-size: 16px;
    border: none;
    background: transparent;
    color: #0f55ce;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s, color 0.2s;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  p {
    background: transparent;
    color: #df3c79;
    padding: 8px;
    margin: 0;
    text-align: center;
    white-space: nowrap;
  }
`;

const AnnotationTaskUI = ({
  annotationTaskDataList,
  onTaskComplete,
  latitude,
  longitude,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnnotationEditor, setShowAnnotationEditor] = useState(false);
  const [searchParams] = useSearchParams();
  const employeeKey = searchParams.get("key");

  const handlePrev = () => {
    setCurrentIndex((prevState) => Math.max(prevState - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevState) => {
      const maxIndex = annotationTaskDataList.length - 1;
      const newIndex = Math.min(prevState + 1, maxIndex);
      return newIndex;
    });
  };

  const handleAnnotate = () => {
    setShowAnnotationEditor(true);
  };

  const handleEditorClose = () => {
    setShowAnnotationEditor(false);
  };

  const handleTaskComplete = (completedTaskId) => {
    console.log("Task completed in UI:", completedTaskId);

    // If the completed task is the current task and there are more tasks
    if (
      completedTaskId === annotationTaskDataList[currentIndex].AnnotationTaskID
    ) {
      // If we're at the last task, move back one
      if (currentIndex === annotationTaskDataList.length - 1) {
        setCurrentIndex(Math.max(0, currentIndex - 1));
      }
      // Otherwise stay at the same index (which will show the next task after the state updates)
    }

    // Notify parent component
    onTaskComplete(completedTaskId);

    // Close the editor
    setShowAnnotationEditor(false);
  };

  if (
    !Array.isArray(annotationTaskDataList) ||
    annotationTaskDataList.length === 0
  ) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <NoDataFound
          message={"No annotation task is available, try again later"}
        />
      </div>
    );
  }

  const task = annotationTaskDataList[currentIndex];
  if (!task) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <NoDataFound message={"No task data available"} />
      </div>
    );
  }

  return (
    <WrapperAnnotationTask>
      {!showAnnotationEditor && (
        <>
          <div className="task-content">
            <div style={{ width: "100%" }} className="instructions-section">
              <DescriptionSection style={{ background: "#7ab240" }}>
                <h2>Task Instructions</h2>
                <h3>{task.Instruction}</h3>
              </DescriptionSection>
              <DescriptionSection style={{ background: "#50af52" }}>
                <h2>Task Description</h2>
                <h3>{task.TaskDescription}</h3>
              </DescriptionSection>
              <DescriptionSection style={{ background: "#086050" }}>
                <h2>Image Description</h2>
                <h3>{task.ImageDescription}</h3>
              </DescriptionSection>
              {!employeeKey && (
                <DescriptionSection style={{ background: "#086050" }}>
                  <h2 style={{ fontSize: "17px", padding: "5px" }}>
                    💰 Earning for this Task: {task.Sicca ? task.Sicca : 0}{" "}
                    Tasker Sicca Per annotation
                  </h2>
                </DescriptionSection>
              )}
            </div>

            <div className="image-section">
              <AnnotationTaskContainer>
                <ImagePreview>
                  <img src={task.ImageURL} alt="Task" />
                </ImagePreview>
              </AnnotationTaskContainer>
            </div>
          </div>

          <BottomControls>
            <AnnotateButton onClick={handleAnnotate}>
              Annotate Image
            </AnnotateButton>
            <WrapperNextBtn>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 10px",
                }}
              >
                <button onClick={handlePrev} disabled={currentIndex === 0}>
                  ⬅️ Previous
                </button>
                <p>
                  Task {currentIndex + 1} of {annotationTaskDataList.length}
                </p>
                <button
                  onClick={handleNext}
                  disabled={currentIndex === annotationTaskDataList.length - 1}
                >
                  Next ➡️
                </button>
              </div>
            </WrapperNextBtn>
          </BottomControls>
        </>
      )}

      {showAnnotationEditor && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <AnnotationEditor
            taskData={task}
            onComplete={handleTaskComplete}
            onClose={handleEditorClose}
            latitude={latitude}
            longitude={longitude}
          />
        </div>
      )}
    </WrapperAnnotationTask>
  );
};

export default AnnotationTaskUI;
