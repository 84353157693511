import React, { Component } from "react";
import styled from "styled-components";
import NoDataFound from "../../../elements/NoDataFound";
import CameraPhotoButton from "./CamDetails";

const WrapperImageTask = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    text-align: center;
    padding: 24px 10px 20px 10px;
    font-size: 30px;
    color: #490c6b;
    margin: 8px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    color: #c90606;
    padding: 0 0 4px 4px;
    //background: #ffe8e8;
    span {
      color: red;
    }
  }
  h2 {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    color: #062549;
    margin: 10px;
  }
  h3 {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    color: #062549;
    margin: 10px;
  }
  button {
    margin: 0 8px 0 0;
    padding: 8px 14px;
    font-size: 16px;
    border: none;
    background: #521873;
    color: white;
    border-radius: 5px;
  }
  @media (max-width: 576px) {
    h4 {
      font-size: 24px;
      padding: 4px 12px;
    }
    h3 {
      font-size: 16px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }
`;
const WrapperNextBtn = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 2px solid;

  button {
    margin: 6px;
    padding: 4px 8px;
    font-size: 16px;
    border: none;
    background: #1b4da3;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s, color 0.2s;

    &:active {
      background: #eadaf3; /* Lighter background when button is pressed */
      color: #1b4da3; /* Change text color when button is pressed */
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background: #1b4da3;
      color: #868686;
    }
  }

  p {
    background: transparent;
    color: #df3c79;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
    margin: 0 10px;
  }
`;

export const AudioTaskContainer = styled.div`
  //   min-width: 450px;

  h1 {
    text-align: center;
    margin: 4px;
    font-size: 32px;
    border: 1px dashed #0000007d;
    border-radius: 2px;
  }
  h2 {
    text-align: center;
    margin: 4px;
    font-size: 32px;
    color: black;
  }

  @media (max-width: 576px) {
    h1 {
      text-align: center;
      margin: 4px;
      font-size: 18px;
      max-height: 140px;
      overflow: scroll;
      min-height: 36px;
      background: #0b0b0b0f;
      color: #000000d1;
      padding: 4px;
    }
    h2 {
      text-align: center;
      margin: 1px;
      font-size: 20px;
    }
  }

  @media (max-width: 375px) {
    h1 {
      text-align: center;
      margin: 4px;
      font-size: 18px;
      max-height: 120px;
      overflow: scroll;
      min-height: 36px;
      background: #0b0b0b0f;
      color: #000000d1;
      padding: 4px;
    }
    h2 {
      text-align: center;
      margin: 1px;
      font-size: 20px;
    }
  }

  /* Remove native disclosure icon for <details> */
  details summary {
    list-style: none; /* Prevent the default triangle icon from appearing */
  }

  /* For iOS Safari specifically */
  details summary::-webkit-details-marker {
    display: none; /* Hides the marker rendered by WebKit */
  }
`;

const DescriptionSection = styled.div`
  background: #50af52;
  text-align: center;

  h3 {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    color: white;
    margin: 10px;
    /* Make the scrollbar always visible */
    height: 50px;
    overflow: auto; /* Ensure scrollbar appears when content overflows */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: white transparent; /* For Firefox */

    /* Ensure scrollbar is visible in WebKit browsers */
    -webkit-overflow-scrolling: touch;
  }

  /* Webkit browsers (Chrome, Safari, etc.) */
  h3::-webkit-scrollbar {
    width: 8px; /* Adjust the width as needed */
  }

  h3::-webkit-scrollbar-thumb {
    background-color: white; /* Scrollbar thumb color */
  }

  h3::-webkit-scrollbar-track {
    background-color: transparent; /* Background color of the track */
  }

  h2 {
    font-size: 24px;
    border-radius: 0;
    background: transparent;
    margin: 0;
    padding: 6px;
    color: white;
    font-weight: 600;
  }

  /* Styles for screens up to 576px */
  @media (max-width: 576px) {
    h3 {
      font-size: 17px;
      margin: 0 4px 2px 4px;
      font-weight: 500;
      height: 50px;
      overflow: auto; /* Scrollbars visible only when content overflows */
    }

    h4 {
      font-size: 17px;
      margin: 0 4px 2px 4px;
      font-weight: 500;
    }

    h2 {
      font-size: 18px;
      border-radius: 0;
      background: transparent;
      padding: 6px 6px 2px 6px;
    }
  }

  /* Styles for screens up to 450px */
  @media (max-width: 450px) {
    h3 {
      height: 40px;
      overflow: auto; /* Scrollbars visible when content overflows */
    }
  }

  /* Styles for screens up to 375px */
  @media (max-width: 375px) {
    h3 {
      font-size: 16px;
      height: 24px;
      overflow: auto; /* Scrollbars visible when content overflows */
    }
  }
`;

export default class ImageTaskUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: localStorage.getItem("imageIndex")
        ? Number(localStorage.getItem("imageIndex")) + 1
        : 0,
      isChangeClick: false,
      capturedPhotoData: null,
      imagePreview: null,
      isDescriptionOpen: true,
    };
  }
  toggleDescription = () => {
    this.setState((prevState) => ({
      isDescriptionOpen: !prevState.isDescriptionOpen,
    }));
  };

  handlePrev = () => {
    this.setState(
      (prevState) => ({
        currentIndex: Math.max(prevState.currentIndex - 1, 0),
        isChangeClick: !prevState.isChangeClick,
      }),
      () => {
        localStorage.setItem("imageIndex", this.state.currentIndex); // Ensure updated value is stored
      }
    );
  };

  // handleNext = () => {
  //   this.setState((prevState) => {
  //     const maxIndex = this.props.imageTaskDataList.length - 1;
  //     const newIndex = Math.min(prevState.currentIndex + 1, maxIndex);
  //     return {
  //       currentIndex: newIndex,
  //       isChangeClick: !prevState.isChangeClick,
  //     };
  //   }), () => {
  //     localStorage.setItem("imageIndex", this.state.currentIndex)
  //   }
  //   if (this.state.currentIndex === this.props.imageTaskDataList.length - 1) {
  //     this.props.getImageTaskData({ sessionKey: this.props.sessionKey, nextCursor: this.props.nextCursor })
  //   }
  // };

  handleNext = () => {
    this.setState(
      (prevState) => {
        const maxIndex = this.props.imageTaskDataList.length - 1;
        const newIndex = Math.min(prevState.currentIndex + 1, maxIndex);
        return {
          currentIndex: newIndex,
          isChangeClick: !prevState.isChangeClick,
        };
      },
      () => {
        // ✅ Ensure updated value is stored
        localStorage.setItem("imageIndex", this.state.currentIndex);
      }
    );
    // ✅ Use updated `currentIndex` inside setState callback
    if (this.state.currentIndex === this.props.imageTaskDataList.length - 1) {
      this.props.getImageTaskData({
        sessionKey: this.props.sessionKey,
        nextCursor: this.props.nextCursor,
      });
    }
  };

  render() {
    const { imageTaskDataList } = this.props;
    // console.log('imageTaskDataList', imageTaskDataList.length)
    const { currentIndex, isDescriptionOpen, imagePreview } = this.state;
    // console.log('currentIndex', currentIndex)
    if (!Array.isArray(imageTaskDataList) || imageTaskDataList.length === 0) {
      return (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <NoDataFound
            message={
              "There are no tasks available or you have completed all task or you may not have enabled 'Read' for any language."
            }
          />
        </div>
      );
    }
    // Safely get the current question
    const question = imageTaskDataList[currentIndex];
    // console.log("imagePreview", imagePreview, 'isDescriptionOpen', isDescriptionOpen);
    if (!question) {
      return (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <NoDataFound
            message={
              "There are no tasks available or you may not have enabled 'Read' for any language."
            }
          />
        </div>
      );
    }
    return (
      <WrapperImageTask>
        <div style={{ background: "black" }}>
          <DescriptionSection>
            <h2>Task Description</h2>
            <h3>{question.TypeDescTrans || question.Description}</h3>
          </DescriptionSection>
          <DescriptionSection style={{ background: "#7ab240" }}>
            <h2>Task Instructions</h2>
            <h3>{question.NoteDescTrans || question.Note}</h3>
          </DescriptionSection>
          <DescriptionSection style={{ background: "#086050" }}>
            <h2 style={{ fontSize: "17px", padding: "5px" }}>
              💰 Earning for this Task: {question.Sicca ? question.Sicca : 0}{" "}
              Tasker Sicca
            </h2>
          </DescriptionSection>
        </div>
        <div style={{ width: "100%" }}>
          <AudioTaskContainer>
            <h2>
              Your Task{" "}
              {question.PictureMode == "portrait"
                ? "( Click a portrait Image )"
                : question.PictureMode == "landscape"
                  ? "(Click a landscape Image )"
                  : "(Click an Image)"}
            </h2>
            {/* <h1>{question.ImageDescription}</h1> */}
            <details
              open={isDescriptionOpen} // Bind the open attribute to isDescriptionOpen state
              onClick={(e) => this.toggleDescription()} // Update state when toggled
            >
              <summary
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "600",
                  padding: "10px",
                  listStyle: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  background: "#cdd0ffe3",
                  color: "#1c0f43",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <i
                  className={`fa ${isDescriptionOpen ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  style={{ fontSize: "20px" }}
                ></i>
                <span style={{ textAlign: "center" }}>
                  {isDescriptionOpen
                    ? question.ImageDescription
                    : question.ImageDescription.length > 30
                      ? `${question.ImageDescription.slice(0, 30)}...`
                      : question.ImageDescription}
                </span>
              </summary>
            </details>
          </AudioTaskContainer>
          <WrapperNextBtn>
            <div style={{ margin: "0" }}>
              <CameraPhotoButton
                onPhotoCapture={(file, metadata) => {
                  // Create object URL for preview
                  const previewUrl = URL.createObjectURL(file);
                  // Store all the data
                  this.setState({
                    capturedPhotoData: {
                      fileInfo: {
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        lastModified: new Date(file.lastModified).toISOString(),
                      },
                      metadata: metadata,
                      dimensions: metadata.dimensions,
                      deviceInfo: metadata.deviceInfo,
                    },
                    imagePreview: previewUrl,
                  });
                }}
                isChangeClick={this.state.isChangeClick}
                updateImageTaskData={this.props.updateImageTaskData}
                latitude={this.props.latitude}
                longitude={this.props.longitude}
                requestLocation={this.props.requestLocation}
                imageTaskData={question}
                toggleDescription={this.toggleDescription}
                isDescriptionOpen={isDescriptionOpen}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "black",
                alignItems: "baseline",
              }}
            >
              <button
                onClick={this.handlePrev}
                disabled={currentIndex === 0}
                style={{
                  background: "transparent",
                  color: "#0f55ce",
                  fontWeight: "600",
                  margin: "0",
                  padding: "10px",
                }}
              >
                ⬅️ Previous
              </button>
              <p>
                Task {currentIndex + 1} of {imageTaskDataList.length}
              </p>
              <button
                onClick={this.handleNext}
                disabled={
                  currentIndex === imageTaskDataList.length - 1 &&
                  !this.props.nextCursor
                }
                style={{
                  background: "transparent",
                  color: "#0f55ce",
                  fontWeight: "600",
                  margin: "0",
                  padding: "10px",
                }}
              >
                Next ➡️
              </button>
            </div>
          </WrapperNextBtn>
        </div>
      </WrapperImageTask>
    );
  }
}