import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../../api";
import LoadingUI from "../../../elements/LoadingUI";
import NoDataFound from "../../../elements/NoDataFound";
import AnnotationTaskUI from "./AnnotationTaskUI";

const PendingAnnotationTask = () => {
  const [searchParams] = useSearchParams();
  const employeeKey = searchParams.get("key");
  // Only use sessionKey if employeeKey doesn't exist
  const sessionKey = !employeeKey ? localStorage.getItem("sessionKey") : null;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [annotationTaskDataList, setAnnotationTaskDataList] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationGranted, setLocationGranted] = useState(false);
  const [locationBlocked, setLocationBlocked] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isLocationLoading, setLocationLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "There are no tasks available for your selected language, or you may not have enabled 'Read' for it."
  );
  const hasFetchedData = useRef(false);

  // Add handler for task completion
  const handleTaskComplete = (completedTaskId) => {
    console.log("Task completed:", completedTaskId);
    setAnnotationTaskDataList((prevTasks) => {
      const updatedTasks = prevTasks.filter(
        (task) => task.AnnotationTaskID !== completedTaskId
      );
      console.log("Tasks remaining:", updatedTasks.length);
      return updatedTasks;
    });
  };

  const fetchAnnotationTasks = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`/tasker-dashboard/annotation-task`, {
        params: {
          sessionKey,
          employeeKey,
        },
      });
      if (response.data && Array.isArray(response.data.data)) {
        setAnnotationTaskDataList(response.data.data);
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      console.error("Error fetching annotation tasks:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Don't redirect if we're still parsing URL parameters
    if (typeof employeeKey === "string") {
      // Only redirect if explicitly undefined as a string
      if (employeeKey === "undefined") {
        window.location.href = "https://operations.thedatataskers.com/";
        return;
      }
    }

    // Then check if neither key is present
    if (!employeeKey && !sessionKey) {
      window.open("/", "_self");
      return;
    }

    if (!hasFetchedData.current) {
      hasFetchedData.current = true;
      fetchAnnotationTasks();
    }

    // Only handle location if we're using sessionKey (regular user)
    if (!employeeKey && sessionKey) {
      const userLocationPermission = localStorage.getItem("locationGranted");
      if (userLocationPermission === "true") {
        setLocationGranted(true);
        setShowLocationModal(false);
        requestLocation();
      } else {
        setLocationGranted(false);
        setShowLocationModal(true);
      }
    }
    setLocationLoading(false);
  }, [employeeKey, sessionKey]);

  const requestLocation = () => {
    setLocationLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          setLocationGranted(true);
          setLocationBlocked(false);
          setShowLocationModal(false);
          localStorage.setItem("locationGranted", "true");
          localStorage.setItem("locationBlocked", "false");
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setLocationGranted(false);
            setLocationBlocked(true);
            setShowLocationModal(true);
            localStorage.setItem("locationBlocked", "true");
            localStorage.setItem("locationGranted", "false");
          }
          setLocationLoading(false);
        }
      );
    }
  };

  const renderLocationModal = () => {
    if (isLocationLoading) return null;

    return (
      <div style={styles.modal}>
        <div style={styles.modalContent}>
          {!locationBlocked && (
            <React.Fragment>
              <h3>We need your location!</h3>
              <p>
                To provide better services, we require access to your location.
                Please click "Allow Location" to grant us permission.
              </p>
              <p style={{ color: "#1d5dc9" }}>
                Note: Please do not click on "Never" when location popup/dialog
                appears
              </p>
              <button
                onClick={requestLocation}
                style={styles.modalButton}
                disabled={isLocationLoading}
              >
                {isLocationLoading ? "Please Wait..." : "Allow Location"}
              </button>
            </React.Fragment>
          )}
          {locationBlocked && (
            <div
              style={{
                textAlign: "left",
                color: "#ba1d1d",
                background: "#fff8f8",
                padding: "2px 2px",
                borderRadius: "5px",
              }}
            >
              <p style={styles.blockedText}>
                Location access is required to continue. Please enable it in
                your browser settings:
                <br />
                1. Go to your browser settings.
                <br />
                2. Find "Site Settings" or "Permissions".
                <br />
                3. Find "Location" within "Permissions" and check the "Blocked"
                section.
                <br />
                4. You will find this site in your blocked section
                "https://taskers.thedatataskers.com".
                <br />
                5. Click and remove this, and enable location access for this
                site.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LoadingUI message={"Loading annotation tasks for you...please wait"} />
      </div>
    );
  }

  if (
    isError ||
    !Array.isArray(annotationTaskDataList) ||
    annotationTaskDataList.length === 0
  ) {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <NoDataFound message={errorMessage} />
      </div>
    );
  }

  const filteredTasks = annotationTaskDataList.filter(
    (task) => task.ImageURL !== null
  );

  return (
    <div style={styles.container}>
      {showLocationModal && renderLocationModal()}
      {!showLocationModal && (
        <AnnotationTaskUI
          annotationTaskDataList={filteredTasks}
          assignedTaskList={annotationTaskDataList}
          onTaskComplete={handleTaskComplete}
          key="annotationTaskDataList"
          latitude={latitude}
          longitude={longitude}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    // display: "flex",
  },
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#000000e0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    width: "80%",
    maxWidth: "400px",
  },
  modalButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
  blockedText: {
    fontSize: "14px",
    color: "#666",
    padding: "10px",
  },
};

export default PendingAnnotationTask;
