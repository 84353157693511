import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { Stage, Layer, Image } from "react-konva";
import styled from "styled-components";
import RectangleAnnotations from "./RectangleAnnotations";
import MultiAttributeForm from "./MultiAttributeForm";

const CanvasContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  background: #2a2a2a;
  touch-action: none;
`;

const LabelInputModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 300px;

  h3 {
    margin: 0 0 16px 0;
    color: #2a2a2a;
    font-size: 18px;
    font-weight: 600;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  input {
    padding: 12px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.2s;

    &:focus {
      border-color: #4caf50;
    }
  }

  .buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 8px;
  }

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    &[type="submit"] {
      background: #4caf50;
      color: white;

      &:hover {
        background: #43a047;
      }
    }

    &[type="button"] {
      background: #e0e0e0;
      color: #2a2a2a;

      &:hover {
        background: #d0d0d0;
      }
    }
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(2px);
`;

// Confirm rotation button positioned to avoid footer overlap
const ConfirmRotationButton = styled.button`
  position: fixed;
  bottom: 84px; /* Clear space above footer */
  left: 50%;
  transform: translateX(-50%);
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 900; /* Lower than form but above canvas */
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;

  &:hover {
    background: #43a047;
    transform: translateX(-50%) translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  }

  &:active {
    transform: translateX(-50%) translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

// Helper function to generate a unique ID
const generateUniqueId = () => {
  return `rect_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
};

const AnnotationCanvas = forwardRef(
  (
    {
      imageUrl,
      isDrawing,
      isRotatableDrawing,
      annotations,
      onAnnotationsChange,
      category,
      labelName,
      labelId,
      setIsDrawing,
      setIsRotatableDrawing,
      taskFields,
      projectId,
      onFormDataUpdate,
      annotationFormData,
      onEditingRectChange,
      onCopyAnnotation,
      copiedAnnotation,
    },
    ref
  ) => {
    // Stage and image states
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const [initialImageFit, setInitialImageFit] = useState(null);

    // Drawing states
    const [startPoint, setStartPoint] = useState(null);
    const [currentRect, setCurrentRect] = useState(null);
    const [editingRect, setEditingRect] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isDraggingEnabled, setIsDraggingEnabled] = useState(true);
    const [isPinching, setIsPinching] = useState(false);
    const [lastCenter, setLastCenter] = useState(null);
    const [lastDist, setLastDist] = useState(0);
    const [initialScale, setInitialScale] = useState(1);
    const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });

    // Label input modal states
    const [showAttributeForm, setShowAttributeForm] = useState(false);
    const [pendingRect, setPendingRect] = useState(null);
    const [isEditingLabel, setIsEditingLabel] = useState(false);

    // Label search input modal states
    const [showLabelSearch, setShowLabelSearch] = useState(false);

    const [currentAnnotationId, setCurrentAnnotationId] = useState(null);

    const [lastUsedFormData, setLastUsedFormData] = useState(null);

    // State for rotatable boxes workflow
    const [isRotatableEditMode, setIsRotatableEditMode] = useState(false);
    const [rotatableRectId, setRotatableRectId] = useState(null);

    // Debug state - to help see what's happening
    const [debugInfo, setDebugInfo] = useState({});

    const stageRef = useRef(null);
    const containerRef = useRef(null);
    const annotationsRef = useRef(annotations);

    // Keep annotationsRef updated
    useEffect(() => {
      annotationsRef.current = annotations;
    }, [annotations]);

    // Keep parent informed about editing rect changes
    useEffect(() => {
      if (onEditingRectChange) {
        onEditingRectChange(editingRect ? editingRect.id : null);
      }
    }, [editingRect, onEditingRectChange]);

    // Define handleReset at component top level
    const handleReset = useCallback(() => {
      const stage = stageRef.current;
      if (stage && initialImageFit) {
        stage.position({ x: initialImageFit.x, y: initialImageFit.y });
        stage.scale({ x: initialImageFit.scale, y: initialImageFit.scale });
        setScale(initialImageFit.scale);
        setPosition({ x: initialImageFit.x, y: initialImageFit.y });
        stage.batchDraw();
      }
    }, [initialImageFit]);

    const handleDelete = useCallback(() => {
      if (editingRect) {
        // Filter out the editingRect from annotations
        const newAnnotations = annotations.filter(
          (rect) => rect.id !== editingRect.id
        );
        onAnnotationsChange(newAnnotations);

        // Clear the editing state
        setEditingRect(null);

        // If in rotatable edit mode, clear rotatable edit mode too
        if (isRotatableEditMode) {
          setIsRotatableEditMode(false);
          setRotatableRectId(null);
        }
      }
    }, [editingRect, annotations, onAnnotationsChange, isRotatableEditMode]);

    // Handle copying the current selected annotation
    const handleCopy = useCallback(() => {
      if (editingRect) {
        // Find the associated form data
        const formData = annotationFormData[editingRect.id];
        // Create a deep copy of the annotation
        const copiedData = {
          rect: { ...editingRect },
          formData: formData ? JSON.parse(JSON.stringify(formData)) : null,
        };

        // Inform parent component about the copy
        if (onCopyAnnotation) {
          onCopyAnnotation(copiedData);
        }

        console.log("Annotation copied:", copiedData);
      }
    }, [editingRect, annotationFormData, onCopyAnnotation]);

    // Handle pasting the copied annotation
    const handlePaste = useCallback(() => {
      if (copiedAnnotation && image) {
        // Create new rectangle with unique ID
        const newId = generateUniqueId();
        const { rect, formData } = copiedAnnotation;

        // Offset the position slightly to make it visible that a new rectangle was created
        const offsetX = 20;
        const offsetY = 20;

        // Ensure annotation stays within image bounds when pasted
        const newX = Math.min(rect.x + offsetX, image.width - rect.width);
        const newY = Math.min(rect.y + offsetY, image.height - rect.height);

        // Create the new annotation with the offset position and a new ID
        const newRect = {
          ...rect,
          id: newId,
          x: newX,
          y: newY,
        };

        // Add to annotations list
        const newAnnotations = [...annotationsRef.current, newRect];
        onAnnotationsChange(newAnnotations);

        // Also copy the form data if it exists
        if (formData && typeof onFormDataUpdate === "function") {
          onFormDataUpdate(newId, formData);
        }

        // Immediately select the new annotation for editing
        setEditingRect(newRect);
        console.log("Annotation pasted:", newRect);
      }
    }, [copiedAnnotation, image, onAnnotationsChange, onFormDataUpdate]);

    // Keyboard event listener for copy-paste shortcuts
    useEffect(() => {
      const handleKeyDown = (e) => {
        // Copy selected annotation with Ctrl+C or Cmd+C
        if ((e.ctrlKey || e.metaKey) && e.key === "c") {
          handleCopy();
        }
        // Paste copied annotation with Ctrl+V or Cmd+V
        else if ((e.ctrlKey || e.metaKey) && e.key === "v") {
          handlePaste();
        } else if (e.key === "Delete" || e.key === "Backspace") {
          // Only handle delete if no input elements are focused
          if (
            document.activeElement?.tagName !== "INPUT" &&
            document.activeElement?.tagName !== "TEXTAREA"
          ) {
            handleDelete();
          }
        }
      };

      // Add and remove event listener
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleCopy, handlePaste, handleDelete]);

    // Load and fit image
    useEffect(() => {
      if (!imageUrl) return;

      const img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {
        if (containerRef.current) {
          const containerWidth = containerRef.current.offsetWidth;
          const containerHeight = containerRef.current.offsetHeight;

          // Calculate scale to fit image within container
          const scaleX = containerWidth / img.width;
          const scaleY = containerHeight / img.height;
          const initialScale = Math.min(scaleX, scaleY) * 0.9; // 90% of container size

          // Calculate dimensions
          const width = img.width * initialScale;
          const height = img.height * initialScale;

          // Center position
          const x = (containerWidth - width) / 2;
          const y = (containerHeight - height) / 2;

          const fitData = { scale: initialScale, x, y };
          setInitialImageFit(fitData);
          setScale(initialScale);
          setPosition({ x, y });

          // Set stage position and scale
          if (stageRef.current) {
            stageRef.current.position({ x, y });
            stageRef.current.scale({ x: initialScale, y: initialScale });
            stageRef.current.batchDraw();
          }

          setImage({
            element: img,
            width: img.width,
            height: img.height,
          });
        }
      };
    }, [imageUrl]);

    // Update stage size on container resize
    useEffect(() => {
      const updateSize = () => {
        if (containerRef.current) {
          const newSize = {
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight,
          };
          setStageSize(newSize);
        }
      };

      updateSize();
      window.addEventListener("resize", updateSize);
      return () => window.removeEventListener("resize", updateSize);
    }, []);

    // IMPORTANT: This effect handles setting the editing rect for rotatable boxes
    useEffect(() => {
      if (rotatableRectId && isRotatableEditMode) {
        const rectToEdit = annotations.find(
          (rect) => rect.id === rotatableRectId
        );

        // Update debug info
        setDebugInfo((prev) => ({
          ...prev,
          rotatableRect: rectToEdit,
          editingRectSet: !!rectToEdit,
          timeStamp: new Date().toISOString(),
        }));

        if (rectToEdit) {
          // Set a timeout to ensure DOM has updated
          const timeoutId = setTimeout(() => {
            setEditingRect(rectToEdit);
          }, 50);

          return () => clearTimeout(timeoutId);
        }
      }
    }, [rotatableRectId, isRotatableEditMode, annotations]);

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
      handleReset,
      handleCopy,
      handlePaste,
      handleDelete,
      getImageDimensions: () => {
        return image
          ? {
              width: image.width,
              height: image.height,
            }
          : null;
      },
    }));

    const handleFormSubmit = (formData) => {
      // Update debug info
      setDebugInfo((prev) => ({
        ...prev,
        formSubmitted: true,
        pendingRect: pendingRect,
        isRotatableEditMode: isRotatableEditMode,
        timeStamp: new Date().toISOString(),
      }));

      if (pendingRect) {
        // Get fields with selected values
        const fieldValues = Object.entries(formData)
          .map(([key, data]) => ({
            fieldId: key,
            value: data.value,
            type: data.type,
            labelId: data.labelId,
          }))
          .filter((item) => item.value);

        // Just use Label 1, Label 2, etc. format
        const label =
          fieldValues.length > 0
            ? `Label ${annotations.length + 1}`
            : "Unlabeled";

        const labelIdField = fieldValues.find(
          (field) => field.type === "search" && field.labelId
        );

        setLastUsedFormData(formData);

        // If we're in rotatable edit mode, update the existing rectangle
        if (isRotatableEditMode) {
          // Find current rectangle in annotations
          const currentAnnotations = [...annotationsRef.current];

          const rectIndex = currentAnnotations.findIndex(
            (rect) => rect.id === pendingRect.id
          );

          if (rectIndex !== -1) {
            // Update the existing rectangle
            currentAnnotations[rectIndex] = {
              ...currentAnnotations[rectIndex],
              label: label,
              labelId: labelIdField?.labelId || null,
              // Remove temporary visual properties
              stroke: undefined,
              strokeWidth: undefined,
              fill: undefined,
            };

            onAnnotationsChange(currentAnnotations);

            // Update form data
            if (typeof onFormDataUpdate === "function") {
              onFormDataUpdate(pendingRect.id, formData);
            }
          }
        } else {
          // Normal flow - add as new annotation
          const newRect = {
            ...pendingRect,
            label: label,
            labelId: labelIdField?.labelId || null,
          };

          // Add to annotations
          const newAnnotations = [...annotationsRef.current, newRect];
          onAnnotationsChange(newAnnotations);

          // Update form data
          if (typeof onFormDataUpdate === "function") {
            onFormDataUpdate(newRect.id, formData);
          }
        }

        // Reset all states
        setShowAttributeForm(false);
        setPendingRect(null);
        setIsEditingLabel(false);
        setIsRotatableEditMode(false);
        setEditingRect(null);
        setRotatableRectId(null);
      }
    };

    const handleFormEdit = (formData) => {
      if (!currentAnnotationId) return;

      // Get form field values
      const fieldValues = Object.entries(formData)
        .map(([key, data]) => ({
          fieldId: key,
          value: data.value,
          type: data.type,
          labelId: data.labelId,
        }))
        .filter((item) => item.value);

      // Find the current annotation's index to maintain its label number
      const currentIndex = annotations.findIndex(
        (ann) => ann.id === currentAnnotationId
      );
      const label =
        currentIndex >= 0 ? `Label ${currentIndex + 1}` : "Unlabeled";

      const labelIdField = fieldValues.find(
        (field) => field.type === "search" && field.labelId
      );

      // Update existing annotation
      const newAnnotations = annotations.map((ann) =>
        ann.id === currentAnnotationId
          ? {
              ...ann,
              label: label,
              labelId: labelIdField?.labelId || null,
            }
          : ann
      );

      onAnnotationsChange(newAnnotations);
      onFormDataUpdate(currentAnnotationId, formData);

      // Reset all states
      setShowAttributeForm(false);
      setCurrentAnnotationId(null);
      setIsEditingLabel(false);
      setIsRotatableEditMode(false);
      setEditingRect(null);
      setRotatableRectId(null);
    };

    const getRelativePointerPosition = (event) => {
      const stage = stageRef.current;
      if (!stage) return null;

      const transform = stage.getAbsoluteTransform().copy();
      transform.invert();
      const pos = stage.getPointerPosition();
      if (!pos) return null;

      return transform.point(pos);
    };

    // Helper functions for touch events
    const getDistance = (p1, p2) => {
      return Math.sqrt(
        Math.pow(p2.clientX - p1.clientX, 2) +
          Math.pow(p2.clientY - p1.clientY, 2)
      );
    };

    const getCenter = (p1, p2) => {
      const stage = stageRef.current;
      if (!stage) return { x: 0, y: 0 };

      const stageRect = stage.container().getBoundingClientRect();
      return {
        x: (p1.clientX + p2.clientX) / 2 - stageRect.left,
        y: (p1.clientY + p2.clientY) / 2 - stageRect.top,
      };
    };

    const handleTouchStart = (e) => {
      const stage = stageRef.current;
      if (!stage || !image) return;

      // Don't start drawing if any special mode is active
      if (showAttributeForm || isRotatableEditMode) return;

      // Check if it's a touch event
      const isTouchEvent = e.evt.touches !== undefined;

      if (isTouchEvent) {
        const touches = e.evt.touches;
        e.evt.preventDefault();

        if (isDrawing && touches.length === 1) {
          setIsDraggingEnabled(false);
          const pos = getRelativePointerPosition(e);
          if (!pos) return;

          // Constrain initial point within image boundaries
          const constrainedX = Math.min(Math.max(0, pos.x), image.width);
          const constrainedY = Math.min(Math.max(0, pos.y), image.height);

          // Only start drawing if point is within image bounds
          if (
            constrainedX >= 0 &&
            constrainedX <= image.width &&
            constrainedY >= 0 &&
            constrainedY <= image.height
          ) {
            setStartPoint({ x: constrainedX, y: constrainedY });
            setCurrentRect({
              x: constrainedX,
              y: constrainedY,
              width: 0,
              height: 0,
              id: generateUniqueId(),
              label: labelName,
              labelId: labelId,
            });
            setIsDragging(true);
          }
        } else if (isRotatableDrawing && touches.length === 1) {
          // Handle rotatable rectangle drawing
          setIsDraggingEnabled(false);
          const pos = getRelativePointerPosition(e);
          if (!pos) return;

          // Constrain initial point within image boundaries
          const constrainedX = Math.min(Math.max(0, pos.x), image.width);
          const constrainedY = Math.min(Math.max(0, pos.y), image.height);

          // Only start drawing if point is within image bounds
          if (
            constrainedX >= 0 &&
            constrainedX <= image.width &&
            constrainedY >= 0 &&
            constrainedY <= image.height
          ) {
            setStartPoint({ x: constrainedX, y: constrainedY });
            setCurrentRect({
              x: constrainedX,
              y: constrainedY,
              width: 0,
              height: 0,
              rotation: 0,
              isRotatable: true,
              id: generateUniqueId(),
              label: labelName,
              labelId: labelId,
            });
            setIsDragging(true);
          }
        } else if (touches.length === 2) {
          // Store initial scale and position when starting pinch
          setInitialScale(scale);
          setInitialPosition(position);
          setIsPinching(true);
          setIsDraggingEnabled(false);

          const center = getCenter(touches[0], touches[1]);
          const distance = getDistance(touches[0], touches[1]);

          setLastCenter(center);
          setLastDist(distance);
        } else {
          setIsDraggingEnabled(true);
        }
      } else {
        // Handle mouse events
        if (isDrawing) {
          setIsDraggingEnabled(false);
          const pos = getRelativePointerPosition(e);
          if (!pos) return;

          // Constrain initial point within image boundaries for mouse events
          const constrainedX = Math.min(Math.max(0, pos.x), image.width);
          const constrainedY = Math.min(Math.max(0, pos.y), image.height);

          // Only start drawing if point is within image bounds
          if (
            constrainedX >= 0 &&
            constrainedX <= image.width &&
            constrainedY >= 0 &&
            constrainedY <= image.height
          ) {
            setStartPoint({ x: constrainedX, y: constrainedY });
            setCurrentRect({
              x: constrainedX,
              y: constrainedY,
              width: 0,
              height: 0,
              id: generateUniqueId(),
              label: labelName,
              labelId: labelId,
            });
            setIsDragging(true);
          }
        } else if (isRotatableDrawing) {
          // Handle rotatable rectangle drawing with mouse
          setIsDraggingEnabled(false);
          const pos = getRelativePointerPosition(e);
          if (!pos) return;

          // Constrain initial point within image boundaries
          const constrainedX = Math.min(Math.max(0, pos.x), image.width);
          const constrainedY = Math.min(Math.max(0, pos.y), image.height);

          // Only start drawing if point is within image bounds
          if (
            constrainedX >= 0 &&
            constrainedX <= image.width &&
            constrainedY >= 0 &&
            constrainedY <= image.height
          ) {
            setStartPoint({ x: constrainedX, y: constrainedY });
            setCurrentRect({
              x: constrainedX,
              y: constrainedY,
              width: 0,
              height: 0,
              rotation: 0,
              isRotatable: true,
              id: generateUniqueId(),
              label: labelName,
              labelId: labelId,
            });
            setIsDragging(true);
          }
        } else {
          setIsDraggingEnabled(true);
        }
      }
    };

    const handleTouchMove = (e) => {
      const stage = stageRef.current;
      if (!stage || !image) return;

      // If the attribute form is open, don't process any mouse movements
      if (showAttributeForm) return;

      // Check if it's a touch event
      const isTouchEvent = e.evt.touches !== undefined;

      if (isTouchEvent) {
        const touches = e.evt.touches;
        e.evt.preventDefault();

        if (
          (isDrawing || isRotatableDrawing) &&
          touches.length === 1 &&
          startPoint &&
          isDragging
        ) {
          const pos = getRelativePointerPosition(e);
          if (!pos) return;

          // Constrain the position within image boundaries
          const constrainedX = Math.min(Math.max(0, pos.x), image.width);
          const constrainedY = Math.min(Math.max(0, pos.y), image.height);

          setCurrentRect((prev) => {
            if (!prev) return null;

            // Calculate dimensions based on constrained position
            const width = constrainedX - startPoint.x;
            const height = constrainedY - startPoint.y;

            // Calculate final position and dimensions ensuring within bounds
            const finalX = width < 0 ? Math.max(0, constrainedX) : startPoint.x;
            const finalY =
              height < 0 ? Math.max(0, constrainedY) : startPoint.y;

            return {
              ...prev,
              x: finalX,
              y: finalY,
              width: Math.min(Math.abs(width), image.width - finalX),
              height: Math.min(Math.abs(height), image.height - finalY),
            };
          });
        } else if (isPinching && touches.length === 2) {
          const center = getCenter(touches[0], touches[1]);
          const distance = getDistance(touches[0], touches[1]);

          if (!lastCenter || !lastDist || distance === 0) {
            setLastCenter(center);
            setLastDist(distance);
            return;
          }

          // Calculate scale relative to initial touch
          const scaleChange = distance / lastDist;
          const scaleDelta = Math.min(Math.max(scaleChange, 0.95), 1.05);

          // Calculate new scale relative to initial scale
          const newScale = Math.min(
            Math.max(
              scale * scaleDelta,
              initialImageFit ? initialImageFit.scale * 0.2 : 0.2
            ),
            initialImageFit ? initialImageFit.scale * 10 : 10
          );

          // Calculate position relative to initial position
          const pointTo = {
            x: (lastCenter.x - stage.x()) / scale,
            y: (lastCenter.y - stage.y()) / scale,
          };

          const newPos = {
            x: center.x - pointTo.x * newScale,
            y: center.y - pointTo.y * newScale,
          };

          // Update stage
          stage.scale({ x: newScale, y: newScale });
          stage.position(newPos);
          stage.batchDraw();

          // Update state
          setScale(newScale);
          setPosition(newPos);
          setLastDist(distance);
          setLastCenter(center);
        }
      } else {
        // Handle mouse events for drawing with the same boundary constraints
        if ((isDrawing || isRotatableDrawing) && startPoint && isDragging) {
          const pos = getRelativePointerPosition(e);
          if (!pos) return;

          const constrainedX = Math.min(Math.max(0, pos.x), image.width);
          const constrainedY = Math.min(Math.max(0, pos.y), image.height);

          setCurrentRect((prev) => {
            if (!prev) return null;

            const width = constrainedX - startPoint.x;
            const height = constrainedY - startPoint.y;

            const finalX = width < 0 ? Math.max(0, constrainedX) : startPoint.x;
            const finalY =
              height < 0 ? Math.max(0, constrainedY) : startPoint.y;

            return {
              ...prev,
              x: finalX,
              y: finalY,
              width: Math.min(Math.abs(width), image.width - finalX),
              height: Math.min(Math.abs(height), image.height - finalY),
            };
          });
        }
      }
    };

    const handleTouchEnd = (e) => {
      // Reset touch state
      const resetTouchState = () => {
        setIsPinching(false);
        setLastCenter(null);
        setLastDist(0);
        setIsDraggingEnabled(true);
      };

      resetTouchState();

      // If not drawing, ignore
      if ((!isDrawing && !isRotatableDrawing) || !currentRect || !image) return;

      // Ensure final rectangle is within image bounds
      const finalWidth = Math.min(
        Math.abs(currentRect.width),
        image.width - currentRect.x
      );
      const finalHeight = Math.min(
        Math.abs(currentRect.height),
        image.height - currentRect.y
      );

      const newRect = {
        ...currentRect,
        x: Math.max(
          0,
          Math.min(
            currentRect.width < 0
              ? currentRect.x + currentRect.width
              : currentRect.x,
            image.width - finalWidth
          )
        ),
        y: Math.max(
          0,
          Math.min(
            currentRect.height < 0
              ? currentRect.y + currentRect.height
              : currentRect.y,
            image.height - finalHeight
          )
        ),
        width: finalWidth,
        height: finalHeight,
      };

      // Only create rectangle if it meets minimum size requirements and is within bounds
      if (
        newRect.width > 5 &&
        newRect.height > 5 &&
        newRect.x >= 0 &&
        newRect.y >= 0 &&
        newRect.x + newRect.width <= image.width &&
        newRect.y + newRect.height <= image.height
      ) {
        // Create a unique ID if one doesn't exist
        const rectId = newRect.id || generateUniqueId();
        const rectWithId = {
          ...newRect,
          id: rectId,
        };

        // Handle different types of rectangles
        if (category === "controlled") {
          // For controlled category, directly add with the controlled label
          onAnnotationsChange([
            ...annotationsRef.current,
            {
              ...rectWithId,
              label: labelName,
              labelId: labelId,
            },
          ]);
        } else if (isRotatableDrawing) {
          // For rotatable rectangles, create with clear visual properties
          const rotatableRect = {
            ...rectWithId,
            isRotatable: true,
            rotation: 0,
            label: "Adjusting rotation...",
            // Make it highly visible with distinct styling
            stroke: "#FF9800",
            strokeWidth: 3,
            fill: "rgba(255, 152, 0, 0.3)",
          };

          // Add to annotations immediately
          onAnnotationsChange([...annotationsRef.current, rotatableRect]);

          // Update debug info
          setDebugInfo((prev) => ({
            ...prev,
            rotatableRectCreated: true,
            rotatableRect: rotatableRect,
            timeStamp: new Date().toISOString(),
          }));

          // Set up rotatable edit mode with a slight delay
          setTimeout(() => {
            // Set editingRect
            setEditingRect(rotatableRect);

            // Store ID for reference
            setRotatableRectId(rectId);

            // Enable rotatable edit mode
            setIsRotatableEditMode(true);

            // Turn off drawing mode
            setIsRotatableDrawing(false);

            // Store pending rectangle for later
            setPendingRect(rotatableRect);
          }, 50);
        } else {
          // For all other categories, normal flow
          setPendingRect(rectWithId);
          setShowAttributeForm(true);
        }
      }

      // Reset current drawing state
      setCurrentRect(null);
      setStartPoint(null);
      setIsDragging(false);
    };

    const handleWheel = (e) => {
      e.evt.preventDefault();
      const stage = stageRef.current;
      if (!stage) return;

      const oldScale = scale;
      const pointer = stage.getPointerPosition();

      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      // Smaller zoom steps for finer control
      const zoomFactor = e.evt.deltaY < 0 ? 1.05 : 0.95;
      const newScale = Math.min(
        Math.max(
          oldScale * zoomFactor,
          initialImageFit ? initialImageFit.scale * 0.2 : 0.2 // Allow zooming out to 20% of initial fit
        ),
        initialImageFit ? initialImageFit.scale * 10 : 10 // Allow zooming in up to 1000% of initial fit
      );

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };

      stage.scale({ x: newScale, y: newScale });
      stage.position(newPos);
      stage.batchDraw();

      setScale(newScale);
      setPosition(newPos);
    };

    const handleLabelClick = (rect) => {
      if (category !== "controlled") {
        setPendingRect(rect);
        setCurrentAnnotationId(rect.id);
        setShowAttributeForm(true);
        setIsEditingLabel(true);
      }
    };

    const handleRectClick = (rectId) => {
      // Don't handle rect clicks if we're in drawing or rotatable edit mode
      if (isDrawing || isRotatableDrawing || isRotatableEditMode) return;

      const rect = annotations.find((r) => r.id === rectId);
      if (rect) {
        setEditingRect(rect);
      }
    };

    const handleStageClick = (e) => {
      // Get the clicked target
      const clickedOnStage = e.target === e.target.getStage();
      const clickedOnImage = e.target instanceof window.Konva.Image;
      const clickedOnLayer = e.target instanceof window.Konva.Layer;

      // If clicked outside rectangles (on stage/image/layer) and not in rotatable edit mode, exit edit mode
      if (
        (clickedOnStage || clickedOnImage || clickedOnLayer) &&
        !isRotatableEditMode
      ) {
        setEditingRect(null);
      }

      // If not in drawing mode or no image, return
      if ((!isDrawing && !isRotatableDrawing) || !image) return;

      // Get pointer position relative to stage
      const pos = getRelativePointerPosition(e.target.getStage());
      if (!pos) return;

      // Constrain position within image boundaries
      const constrainedX = Math.min(Math.max(0, pos.x), image.width);
      const constrainedY = Math.min(Math.max(0, pos.y), image.height);

      if (!currentRect) {
        // Only start drawing if click is within image bounds
        if (
          constrainedX >= 0 &&
          constrainedX <= image.width &&
          constrainedY >= 0 &&
          constrainedY <= image.height
        ) {
          // Starting to draw - create new rectangle with appropriate type
          const newRect = {
            id: generateUniqueId(),
            x: constrainedX,
            y: constrainedY,
            width: 0,
            height: 0,
            rotation: 0,
            isRotatable: isRotatableDrawing,
          };
          setCurrentRect(newRect);

          // Don't add to annotations yet - we'll add when drawing completes
        }
      } else {
        // Finishing the draw - update rectangle size with boundary constraints
        const width = constrainedX - currentRect.x;
        const height = constrainedY - currentRect.y;

        const finalX = width < 0 ? Math.max(0, constrainedX) : currentRect.x;
        const finalY = height < 0 ? Math.max(0, constrainedY) : currentRect.y;

        const updatedRect = {
          ...currentRect,
          x: finalX,
          y: finalY,
          width: Math.min(Math.abs(width), image.width - finalX),
          height: Math.min(Math.abs(height), image.height - finalY),
        };

        // Only process if rectangle is within bounds
        if (
          updatedRect.x >= 0 &&
          updatedRect.y >= 0 &&
          updatedRect.x + updatedRect.width <= image.width &&
          updatedRect.y + updatedRect.height <= image.height
        ) {
          // Simulate touch end to complete drawing
          handleTouchEnd({
            evt: { preventDefault: () => {} },
            target: { getStage: () => stageRef.current },
          });
        }

        // Turn off the appropriate drawing mode
        if (isDrawing) {
          setIsDrawing(false);
        } else if (isRotatableDrawing) {
          setIsRotatableDrawing(false);
        }
      }
    };

    const handleRectChange = (updatedRect) => {
      // Update debug info
      setDebugInfo((prev) => ({
        ...prev,
        rectChanged: true,
        updatedRect: updatedRect,
        timeStamp: new Date().toISOString(),
      }));

      // Update the rectangle in annotations
      const newAnnotations = annotations.map((rect) =>
        rect.id === updatedRect.id ? updatedRect : rect
      );
      onAnnotationsChange(newAnnotations);
    };

    const handleDragMove = (e) => {
      if (isDrawing || isRotatableDrawing || editingRect) return;
      setPosition({
        x: e.target.x(),
        y: e.target.y(),
      });
    };

    const handleDragEnd = (e) => {
      if (isDrawing || isRotatableDrawing || editingRect) return;
      setPosition({
        x: e.target.x(),
        y: e.target.y(),
      });
    };

    const handleLabelSelect = (label) => {
      if (pendingRect) {
        if (isEditingLabel) {
          // Update existing rectangle while preserving its ID
          const newAnnotations = annotations.map((rect) =>
            rect.id === pendingRect.id
              ? {
                  ...rect,
                  label: label.AnnotationLabelName,
                  labelId: label.AnnotationLabelID,
                }
              : rect
          );
          onAnnotationsChange(newAnnotations);
          setIsDrawing(false); // Only disable drawing when editing existing label
          setIsRotatableDrawing(false);
        } else {
          // Add new rectangle
          onAnnotationsChange([
            ...annotations,
            {
              ...pendingRect,
              label: label.AnnotationLabelName,
              labelId: label.AnnotationLabelID,
            },
          ]);
        }
        setShowLabelSearch(false);
        setPendingRect(null);
        setIsEditingLabel(false);
      }
    };

    // Handler for confirming rotation and proceeding to labeling
    const handleConfirmRotation = () => {
      // Update debug info
      setDebugInfo((prev) => ({
        ...prev,
        confirmRotationClicked: true,
        rotatableRectId: rotatableRectId,
        pendingRect: pendingRect,
        timeStamp: new Date().toISOString(),
      }));

      if (isRotatableEditMode && rotatableRectId) {
        // Find the latest version with all rotation changes
        const currentRect = annotations.find(
          (rect) => rect.id === rotatableRectId
        );

        if (currentRect) {
          // Clear editing rect to stop transformer
          setEditingRect(null);

          // Update pending rect with the latest state
          setPendingRect(currentRect);

          // Open the form for labeling
          setShowAttributeForm(true);
        } else {
          // Error handling
          console.error("Could not find rotatable rectangle in annotations");
          setIsRotatableEditMode(false);
          setRotatableRectId(null);
        }
      }
    };

    // Handle form cancellation
    const handleFormCancel = () => {
      // If canceling from rotatable edit mode, remove the temp rectangle
      if (isRotatableEditMode && rotatableRectId) {
        const newAnnotations = annotations.filter(
          (rect) => rect.id !== rotatableRectId
        );
        onAnnotationsChange(newAnnotations);
      }

      // Reset all states
      setShowAttributeForm(false);
      setPendingRect(null);
      setCurrentAnnotationId(null);
      setIsEditingLabel(false);
      setIsRotatableEditMode(false);
      setEditingRect(null);
      setRotatableRectId(null);
    };

    return (
      <CanvasContainer ref={containerRef}>
        <Stage
          ref={stageRef}
          width={stageSize.width}
          height={stageSize.height}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleTouchStart}
          onMouseMove={handleTouchMove}
          onMouseUp={handleTouchEnd}
          onClick={handleStageClick}
          onTap={handleStageClick}
          draggable={
            !isDrawing &&
            !isRotatableDrawing &&
            !editingRect &&
            isDraggingEnabled &&
            !showAttributeForm &&
            !isRotatableEditMode
          }
          x={position.x}
          y={position.y}
          scaleX={scale}
          scaleY={scale}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onWheel={handleWheel}
          style={{ touchAction: "none" }}
        >
          <Layer>
            {image && (
              <Image
                image={image.element}
                width={image.width}
                height={image.height}
              />
            )}
            <RectangleAnnotations
              rectangles={annotations}
              currentRect={currentRect}
              scale={scale}
              onRectChange={handleRectChange}
              onRectClick={handleRectClick}
              onLabelClick={handleLabelClick}
              editingRect={editingRect}
              isDrawing={isDrawing}
              isRotatableDrawing={isRotatableDrawing || isRotatableEditMode}
              imageWidth={image?.width || 0}
              imageHeight={image?.height || 0}
            />
          </Layer>
        </Stage>

        {/* Confirm rotation button - only visible during rotation mode */}
        {isRotatableEditMode && (
          <ConfirmRotationButton onClick={handleConfirmRotation}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M20 6L9 17l-5-5" />
            </svg>
            Confirm Rotation & Continue
          </ConfirmRotationButton>
        )}

        {/* Attribute form - only visible when labeling */}
        {showAttributeForm && category !== "controlled" && (
          <MultiAttributeForm
            fields={taskFields || []}
            category={category}
            projectId={projectId}
            onFormDataUpdate={onFormDataUpdate}
            onClose={handleFormCancel}
            onSubmit={isEditingLabel ? handleFormEdit : handleFormSubmit}
            initialValues={
              currentAnnotationId
                ? annotationFormData[currentAnnotationId]
                : lastUsedFormData || undefined
            }
            allowClear={true}
          />
        )}
      </CanvasContainer>
    );
  }
);

AnnotationCanvas.displayName = "AnnotationCanvas";

export default AnnotationCanvas;
