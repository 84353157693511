import React, { useEffect, useRef, useState } from "react";
import { Rect, Text, Group, Transformer } from "react-konva";

const RectangleAnnotations = ({
  rectangles,
  currentRect,
  scale,
  onRectChange,
  onRectClick,
  onLabelClick,
  editingRect,
  isDrawing,
  isRotatableDrawing,
  imageWidth,
  imageHeight,
}) => {
  const transformerRef = useRef();
  const shapesRef = useRef({});
  const [isTransforming, setIsTransforming] = useState(false);
  const savedNodeAttrs = useRef(null);

  const isMobile =
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
    window.innerWidth <= 768;

  // Improved handle size calculation - keeps handles a reasonable size
  const HANDLE_SIZE = isMobile
    ? Math.min(Math.max(8, 12 / scale), 24)
    : Math.min(Math.max(5, 8 / scale), 12);

  // Clean up before attaching transformer
  useEffect(() => {
    const tr = transformerRef.current;
    if (tr) {
      tr.nodes([]);
      tr.getLayer()?.batchDraw();
    }

    // Attach transformer if an editingRect exists
    if (editingRect && tr) {
      const node = shapesRef.current[editingRect.id];
      if (node) {
        // Save original node attributes for stable reference
        savedNodeAttrs.current = {
          x: node.x(),
          y: node.y(),
          width: node.width(),
          height: node.height(),
          rotation: node.rotation(),
          scaleX: 1,
          scaleY: 1,
          isRotatable: editingRect.isRotatable,
        };

        // Reset scale to avoid scaling issues
        node.scaleX(1);
        node.scaleY(1);

        // Attach transformer to node
        tr.nodes([node]);
        tr.getLayer()?.batchDraw();
      }
    } else {
      savedNodeAttrs.current = null;
    }
  }, [editingRect]);

  const handleRectEvent = (e, handler) => {
    // Prevent event bubbling to avoid multiple handlers firing
    if (!isTransforming) {
      e.cancelBubble = true;
      if (e.evt) {
        e.evt.preventDefault();
        e.evt.stopPropagation();
      }
      handler && handler(e);
    }
  };

  // Calculate appropriate stroke width based on scale
  const getStrokeWidth = () => {
    // Improved stroke width calculation - more consistent appearance
    return Math.min(Math.max(0.5, 1.5 / scale), 2);
  };

  return (
    <>
      {/* Render all rectangles */}
      {rectangles.map((rect) => {
        const isEditing = editingRect && editingRect.id === rect.id;
        const labelWidth = Math.max(rect.label?.length * 10 + 20, 60) / scale;
        const isRotatable = rect.isRotatable === true;

        // Calculate position correctly based on rectangle type
        const rectX = isRotatable ? rect.x + rect.width / 2 : rect.x;
        const rectY = isRotatable ? rect.y + rect.height / 2 : rect.y;

        return (
          <Group
            key={rect.id}
            onClick={(e) =>
              handleRectEvent(e, () => {
                if (!isDrawing && !isRotatableDrawing) {
                  onRectClick(rect.id);
                }
              })
            }
            onTap={(e) =>
              handleRectEvent(e, () => {
                if (!isDrawing && !isRotatableDrawing) {
                  onRectClick(rect.id);
                }
              })
            }
          >
            {/* Main rectangle */}
            <Rect
              x={rectX}
              y={rectY}
              width={rect.width}
              height={rect.height}
              ref={(node) => {
                if (node) shapesRef.current[rect.id] = node;
              }}
              stroke={isEditing ? "#4CAF50" : "#4CAF50"}
              strokeWidth={getStrokeWidth()}
              fill={
                isEditing ? "rgba(76, 175, 80, 0.3)" : "rgba(76, 175, 80, 0.15)"
              }
              offsetX={isRotatable ? rect.width / 2 : 0}
              offsetY={isRotatable ? rect.height / 2 : 0}
              rotation={isRotatable ? rect.rotation || 0 : 0}
              draggable={isEditing}
              onDragStart={() => {
                setIsTransforming(true);
              }}
              onDragMove={(e) => {
                const node = e.target;
                let newX, newY;

                if (isRotatable) {
                  // For rotated rectangles, use a simpler boundary check
                  const margin = Math.max(rect.width, rect.height) / 2;

                  newX = Math.max(
                    margin,
                    Math.min(node.x(), imageWidth - margin)
                  );
                  newY = Math.max(
                    margin,
                    Math.min(node.y(), imageHeight - margin)
                  );
                } else {
                  // For regular rectangles
                  newX = Math.max(
                    0,
                    Math.min(node.x(), imageWidth - rect.width)
                  );
                  newY = Math.max(
                    0,
                    Math.min(node.y(), imageHeight - rect.height)
                  );
                }

                node.position({ x: newX, y: newY });
              }}
              onDragEnd={(e) => {
                const node = e.target;

                // Calculate the updated rectangle properties
                let updatedRect;
                if (isRotatable) {
                  // For rotatable rectangles, we need to update the top-left corner
                  // based on the center position
                  const centerX = node.x();
                  const centerY = node.y();

                  updatedRect = {
                    ...rect,
                    x: centerX - rect.width / 2,
                    y: centerY - rect.height / 2,
                  };
                } else {
                  // For regular rectangles, just update the position
                  updatedRect = {
                    ...rect,
                    x: node.x(),
                    y: node.y(),
                  };
                }

                onRectChange(updatedRect);
                setIsTransforming(false);
              }}
            />

            {/* Label */}
            {rect.label && (
              <Group
                x={rectX}
                y={rectY - 24 / scale}
                rotation={isRotatable ? rect.rotation || 0 : 0}
                offsetX={isRotatable ? rect.width / 2 : 0}
                offsetY={isRotatable ? rect.height / 2 + 24 / scale : 0}
                onClick={(e) =>
                  handleRectEvent(e, () => onLabelClick && onLabelClick(rect))
                }
                onTap={(e) =>
                  handleRectEvent(e, () => onLabelClick && onLabelClick(rect))
                }
              >
                <Rect
                  width={labelWidth}
                  height={20 / scale}
                  fill="#4CAF50"
                  opacity={0.9}
                  cornerRadius={4 / scale}
                  shadowColor="black"
                  shadowBlur={3 / scale}
                  shadowOpacity={0.3}
                  shadowOffset={{ x: 1 / scale, y: 1 / scale }}
                />
                <Text
                  text={rect.label}
                  fontSize={14 / scale}
                  fill="white"
                  padding={4 / scale}
                  fontStyle="bold"
                  width={labelWidth}
                  align="center"
                  ellipsis={true}
                />
              </Group>
            )}
          </Group>
        );
      })}

      {/* Current rectangle being drawn */}
      {currentRect && (
        <Rect
          x={
            currentRect.isRotatable
              ? currentRect.x + currentRect.width / 2
              : currentRect.x
          }
          y={
            currentRect.isRotatable
              ? currentRect.y + currentRect.height / 2
              : currentRect.y
          }
          width={currentRect.width}
          height={currentRect.height}
          offsetX={currentRect.isRotatable ? currentRect.width / 2 : 0}
          offsetY={currentRect.isRotatable ? currentRect.height / 2 : 0}
          rotation={currentRect.isRotatable ? currentRect.rotation || 0 : 0}
          stroke="#FFD700"
          strokeWidth={getStrokeWidth()}
          dash={[4 / scale, 4 / scale]}
          fillEnabled={false}
        />
      )}

      {/* Transformer for editing */}
      {editingRect && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            // Basic validation
            if (
              isNaN(newBox.x) ||
              isNaN(newBox.y) ||
              isNaN(newBox.width) ||
              isNaN(newBox.height) ||
              newBox.width < 5 ||
              newBox.height < 5
            ) {
              return oldBox;
            }

            // Ensure minimum sizes
            let width = Math.max(5, newBox.width);
            let height = Math.max(5, newBox.height);

            // Apply different constraints based on rectangle type
            if (editingRect.isRotatable) {
              // For rotatable rectangles, use simple size constraints
              const maxDimension = Math.min(imageWidth, imageHeight) * 0.9;
              width = Math.min(width, maxDimension);
              height = Math.min(height, maxDimension);

              return {
                ...newBox,
                width,
                height,
              };
            } else {
              // For regular rectangles, apply strict boundaries
              let x = Math.max(0, Math.min(newBox.x, imageWidth));
              let y = Math.max(0, Math.min(newBox.y, imageHeight));
              width = Math.min(width, imageWidth - x);
              height = Math.min(height, imageHeight - y);

              return {
                x,
                y,
                width,
                height,
                rotation: 0, // Regular rectangles should not rotate
              };
            }
          }}
          anchorDragBoundFunc={(oldPos, newPos, e) => {
            return newPos; // Allow movement but boundBoxFunc will constrain the result
          }}
          enabledAnchors={
            editingRect.isRotatable
              ? ["top-left", "top-right", "bottom-left", "bottom-right"]
              : ["top-left", "top-right", "bottom-left", "bottom-right"]
          }
          rotateEnabled={editingRect.isRotatable}
          rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
          // Visual improvements for transformer
          anchorSize={HANDLE_SIZE}
          anchorCornerRadius={Math.min(HANDLE_SIZE / 2, 4)}
          borderStroke="#4CAF50"
          borderStrokeWidth={getStrokeWidth()}
          // Simplified dash pattern
          borderDash={[3 / Math.sqrt(scale), 3 / Math.sqrt(scale)]}
          anchorStroke="#ffffff"
          anchorFill="#4CAF50"
          anchorStrokeWidth={1} // Constant stroke width for clean appearance
          // Reduced padding to keep borders close to rectangle
          padding={0} // No padding for tight fit
          keepRatio={false}
          centeredScaling={editingRect.isRotatable}
          ignoreStroke={true}
          onTransformStart={() => {
            setIsTransforming(true);

            // Store original state
            const node = shapesRef.current[editingRect.id];
            if (node) {
              savedNodeAttrs.current = {
                x: node.x(),
                y: node.y(),
                width: node.width(),
                height: node.height(),
                rotation: node.rotation(),
                scaleX: 1,
                scaleY: 1,
                isRotatable: editingRect.isRotatable,
              };
            }
          }}
          onTransform={() => {
            // This is intentionally left minimal to avoid interfering with Konva's transform
          }}
          onTransformEnd={(e) => {
            const node = shapesRef.current[editingRect.id];
            if (!node) return;

            // Calculate final values
            const rotation = node.rotation();
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // Calculate final width and height
            const width = Math.max(5, node.width() * scaleX);
            const height = Math.max(5, node.height() * scaleY);

            // Calculate final position
            let finalX, finalY;

            if (editingRect.isRotatable) {
              // For rotatable rectangles
              const centerX = node.x();
              const centerY = node.y();

              // Calculate top-left corner from center
              finalX = centerX - width / 2;
              finalY = centerY - height / 2;
            } else {
              // For regular rectangles
              finalX = Math.max(0, Math.min(node.x(), imageWidth - width));
              finalY = Math.max(0, Math.min(node.y(), imageHeight - height));
            }

            // Reset scales to avoid future scaling issues
            node.scaleX(1);
            node.scaleY(1);
            node.width(width);
            node.height(height);

            // Create updated rectangle
            const updatedRect = {
              ...editingRect,
              x: finalX,
              y: finalY,
              width: width,
              height: height,
              rotation: editingRect.isRotatable ? rotation : 0,
            };

            // Update the rectangle
            onRectChange(updatedRect);
            setIsTransforming(false);
            savedNodeAttrs.current = null;
          }}
        />
      )}
    </>
  );
};

export default RectangleAnnotations;
