import React from "react";
import Modal from "react-modal";
import "./TaskDetailsCss.css";

const TaskDetailsModal = ({ isOpen, onClose, taskDetails, loading }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="modal-container">
        <div className="modal-header">
          <h2>Task Details</h2>
          <button onClick={onClose} className="close-button">
            ×
          </button>
        </div>

        <div className="modal-body">
          {loading ? (
            <div className="modal-loading">Loading...</div>
          ) : taskDetails ? (
            <div className="details-container">
              <div className="detail-item">
                <label>Description</label>
                <p>{taskDetails.description}</p>
              </div>

              {taskDetails.taskUrl && (
                <div className="detail-item">
                  <label>
                    {taskDetails.type === "audio" ? "Audio" : "Image"}
                  </label>
                  {taskDetails.type === "audio" ? (
                    <audio
                      controls
                      src={taskDetails.taskUrl}
                      className="media-player"
                    />
                  ) : (
                    <img
                      src={taskDetails.taskUrl}
                      alt="Task"
                      className="task-image"
                    />
                  )}
                </div>
              )}

              <div className="detail-item">
                <label>Completed At</label>
                <p>
                  {new Date(parseInt(taskDetails.timestamp)).toLocaleString(
                    "en-GB",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    }
                  )}
                </p>
              </div>

              {(taskDetails.latitude || taskDetails.longitude) && (
                <div className="detail-item">
                  <label>Location</label>
                  <p>
                    Latitude: {taskDetails.latitude}, Longitude:{" "}
                    {taskDetails.longitude}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="modal-error">No details available</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TaskDetailsModal;
