import React, { Component, createRef } from "react";
import * as exifr from "exifr";
import api from "../../../api"; // Update to your actual API endpoint
import FullPageLoader from "../../../elements/FullpageLoader";
import { showNotification } from "../../../actions/index.actions";

class CameraPhotoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedFile: null,
      imagePreview: null,
      cameraMetaData: null,
      isUploading: false,
      fileUrl: null,
      savingResponse: false,
      latitude: this.props.latitude,
      longitude: this.props.longitude,
    };
    this.cameraInputRef = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isChangeClick != this.props.isChangeClick) {
      this.handleRemoveImage();
    }
  }

  extractMetadata = async (file) => {
    const fileInfo = {
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: new Date(file.lastModified).toISOString(),
    };
    try {
      // Get specific segments
      const exif = await exifr.parse(file, { tiff: true, exif: true });
      const metadata = {
        fileInfo,
        exif,
      };
      return metadata;
    } catch (error) {
      console.error("Error extracting metadata:", error);
      showNotification("Error", "Error extracting metadata of image", "danger");
      return {};
    }
  };

  handlePhotoCapture = async (e) => {
    if (!e.target.files || !e.target.files[0]) return;
    this.setState({ isLoading: true });
    try {
      const file = e.target.files[0];
      const metadata = await this.extractMetadata(file);
      const previewUrl = URL.createObjectURL(file);
      this.setState({
        selectedFile: file,
        imagePreview: previewUrl,
        cameraMetaData: metadata,
      });

      if (this.props.onPhotoCapture) {
        this.props.onPhotoCapture(file, metadata);
        this.props.toggleDescription()
      }
    } catch (error) {
      console.error("Error capturing photo:", error);
      showNotification("Error", "Error capturing photo", "danger");
    } finally {
      this.setState({ isLoading: false });
    }
  };
  handleRemoveImage = () => {
    this.setState({ selectedFile: null, imagePreview: null });
  };
  handleRetakeImage = () => {
    this.setState({ selectedFile: null, imagePreview: null }, () => {
      // Trigger the file input dialog
      this.cameraInputRef.current?.click();
    });
  };

  handleResumableUpload = async () => {
    if (!this.props.latitude || !this.props.longitude) {
      return showNotification("Error", "Location is required", "danger");
    }
    const userLocationPermission = localStorage.getItem("locationGranted");
    if (userLocationPermission === "true") {
      this.props.requestLocation();
    }
    const { selectedFile, cameraMetaData } = this.state;
    if (!selectedFile) {
      showNotification("Error", "No file selected", "danger");
      return;
    }
    const orientation = cameraMetaData?.exif?.Orientation;
    const pictureMode = this.props.imageTaskData.PictureMode;
    if (!orientation && (pictureMode == "portrait" || pictureMode == "landscape")) {
      showNotification("Warning", "Your image orientation (landscape or portrait) could not be determined automatically and will be reviewed manually.", "warning");
    }
    // Check if the orientation matches the picture mode
    let isPortrait = false;
    let isLandscape = false;
    if (orientation === "Rotate 90 CW" || orientation === "Rotate 270 CW") {
      isPortrait = true; // The image is in portrait orientation
    } else if (orientation === "Horizontal (normal)" || orientation === "Rotate 180 CW") {
      isLandscape = true; // The image is in landscape orientation
    }
    const detectedMode = isPortrait ? "portrait" : isLandscape ? "landscape" : "unknown";
    if (pictureMode !== "either" && pictureMode !== detectedMode) {
      showNotification(
        "Warning",
        `Mismatch: Expected ${pictureMode} but found ${detectedMode}.`,
        "warning"
      );
      // return;
    }
    const formData = new FormData();
    // formData.append("tag", "tdt-test");
    formData.append("tag", "dtd-prod-image");
    formData.append("file", selectedFile);
    this.setState({ ...this.state, isUploading: true });
    try {
      const response = await api.post("/api/image/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.fileName) {
        showNotification("Success", "Image has been uploaded", "success");
        this.setState(
          {
            ...this.state,
            isUploading: false,
            fileUrl: response.data.fileName[0],
            latitude: this.props.latitude,
            longitude: this.props.longitude,
          },
          () => {
            this.saveResponse();
          }
        );
      }
    } catch (error) {
      console.error("Error during upload:", error);
      this.setState({ isUploading: false });
      showNotification("Error", "Error uploading file", "danger");
    }
  };
  saveResponse = () => {
    const { imageTaskData } = this.props;
    const { latitude, longitude, cameraMetaData } = this.state;
    const { fileUrl } = this.state;
    this.setState({ savingResponse: true });
    this.props
      .updateImageTaskData({
        userId: imageTaskData.userId,
        imageTasksID: +imageTaskData.ImageTasksID,
        taskUrl: fileUrl,
        latitude: latitude,
        longitude: longitude,
        imageData: cameraMetaData.fileInfo,
        cameraData: cameraMetaData.exif,
      })
      .then(() => {
        this.setState({ savingResponse: false });
        showNotification("Success", "Response has been saved", "success");
        this.handleRemoveImage();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ savingResponse: false });
        this.handleRemoveImage();
        showNotification(
          "Error",
          "Something went wrong, please retry",
          "danger"
        );
      });
  };
  render() {
    const { isLoading, imagePreview, cameraMetaData } = this.state;
    const { isDescriptionOpen } = this.props;
    // console.log("isDescriptionOpen", isDescriptionOpen);
    // Determine the height for the div based on conditions
    let containerHeight = "auto"; // Default value for when description is open and no image preview
    if (!isDescriptionOpen && imagePreview) {
      containerHeight = "55vh"; // When image preview is shown and description is closed
    } else if (isDescriptionOpen && imagePreview) {
      containerHeight = "50vh"; // When both are true, set a smaller height
    }
    // Add media query logic for responsive behavior
    const isMobile = window.innerWidth <= 375; // Example for small mobile screens
    const isBigMobile = window.innerWidth <= 450; // Example for larger mobile screens

    if (isMobile) {
      if (!isDescriptionOpen && imagePreview) {
        containerHeight = "35vh"; // Mobile-specific height (smaller screens)
      } else if (isDescriptionOpen && imagePreview) {
        containerHeight = "30vh"; // Mobile-specific height (smaller screens)
      }
    } else if (isBigMobile) {
      if (!isDescriptionOpen && imagePreview) {
        containerHeight = "45vh"; // Larger mobile screens
      } else if (isDescriptionOpen && imagePreview) {
        containerHeight = "40vh"; // Larger mobile screens
      }
    } else {
      // For larger screens (default behavior)
      if (!isDescriptionOpen && imagePreview) {
        containerHeight = "50vh"; // When image preview is shown and description is closed
      } else if (isDescriptionOpen && imagePreview) {
        containerHeight = "45vh"; // When both are true, set a smaller height
      }
    }
    return (
      <div style={{ textAlign: "center" }}>
        {!imagePreview && (
          <button
            onClick={() => this.cameraInputRef.current?.click()}
            disabled={isLoading}
            className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <span>{isLoading ? "Processing..." : " 📸 Take Photo"}</span>
          </button>
        )}
        <input
          ref={this.cameraInputRef}
          type="file"
          accept="image/*;capture=camera"
          capture="environment"
          onChange={this.handlePhotoCapture}
          style={{ display: "none" }}
        />
        {imagePreview && (
          <div
            className="mt-4 flex flex-col items-center gap-4"
            style={{
              background: "#e4eefe",
              width: "100%", // Ensure the container is full width
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden", // Prevent overflow if the content exceeds the container height
            }}
          >
            <img
              src={imagePreview}
              alt="Preview"
              className="w-64 h-64 object-cover rounded-md shadow-md"
              style={{
                height: containerHeight,
                width: "100%", // Ensure the image takes full width of the div
                objectFit: "contain", // Prevent image from overflowing or distorting
              }}
            />
            <div className="flex gap-4" style={{ margin: "4px 0" }}>
              <button
                onClick={this.handleRetakeImage}
                style={{ background: "#1b4da3" }}
                className="px-4 py-2 bg-red-600 text-white rounded-md"
              >
                📸 Retake Photo
              </button>
              <button
                onClick={this.handleResumableUpload}
                disabled={isLoading}
                className="px-4 py-2 bg-green-600 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? "Uploading..." : "✅ Save Photo"}
              </button>
            </div>
          </div>
        )}
        {this.state.savingResponse || this.state.isUploading ? (
          <FullPageLoader
            isOpen={this.state.savingResponse || this.state.isUploading}
            message="Saving response... please wait"
          />
        ) : null}
      </div>
    );
  }
}
export default CameraPhotoButton;