const toMLFormat = (rect) => {
  const { x, y, width, height, ...rest } = rect;
  return {
    ...rest,
    type: "rectangle",
    coordinates: {
      xmin: x,
      ymin: y,
      xmax: x + width,
      ymax: y + height,
    },
  };
};

const fromMLFormat = (mlData) => {
  if (mlData.type === "rectangle" && mlData.coordinates) {
    const { coordinates, type, ...rest } = mlData;
    const { xmin, ymin, xmax, ymax } = coordinates;
    return {
      ...rest,
      x: xmin,
      y: ymin,
      width: xmax - xmin,
      height: ymax - ymin,
    };
  } else if (mlData.type === "rotatable-rectangle" && mlData.coordinates) {
    const { coordinates, type, ...rest } = mlData;

    // Handle flattened format (new format with x1, y1, etc.)
    if (coordinates.x1 !== undefined) {
      const { x1, y1, x2, y2, x3, y3, x4, y4 } = coordinates;

      // Use diagonal points to determine width and height
      const width = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

      const height = Math.sqrt(Math.pow(x4 - x1, 2) + Math.pow(y4 - y1, 2));

      // Calculate rotation angle (in degrees) from the top edge
      const rotation = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);

      // Use the first point (x1, y1) as the top-left corner
      return {
        ...rest,
        x: x1,
        y: y1,
        width,
        height,
        rotation,
        isRotatable: true,
      };
    }

    // Handle legacy points array format
    if (coordinates.points && coordinates.points.length === 4) {
      const points = coordinates.points;

      // Extract points in order: top-left, top-right, bottom-right, bottom-left
      const [topLeft, topRight, bottomRight, bottomLeft] = points;

      // Use the first point (topLeft) directly
      const x = topLeft.x;
      const y = topLeft.y;

      // Calculate width and height directly from points
      const width = Math.sqrt(
        Math.pow(topRight.x - topLeft.x, 2) +
          Math.pow(topRight.y - topLeft.y, 2)
      );

      const height = Math.sqrt(
        Math.pow(bottomLeft.x - topLeft.x, 2) +
          Math.pow(bottomLeft.y - topLeft.y, 2)
      );

      // Calculate rotation directly from points
      const rotation =
        Math.atan2(topRight.y - topLeft.y, topRight.x - topLeft.x) *
        (180 / Math.PI);

      return {
        ...rest,
        x,
        y,
        width,
        height,
        rotation,
        isRotatable: true,
      };
    }

    // Fallback to legacy format with x, y, width, height, rotation
    if (coordinates.x !== undefined) {
      const { x, y, width, height, rotation } = coordinates;
      return {
        ...rest,
        x,
        y,
        width,
        height,
        rotation,
        isRotatable: true,
      };
    }

    // If we can't process it any way, return as is
    return mlData;
  }
  return mlData;
};

// New simplified function for rotatable rectangles that stores x1,y1,x2,y2,x3,y3,x4,y4
const toRotatableMLFormat = (rect) => {
  const { x, y, width, height, rotation = 0, ...rest } = rect;

  // Calculate corner points directly from the top-left (x,y) of the rectangle
  const rad = (rotation * Math.PI) / 180;
  const cos = Math.cos(rad);
  const sin = Math.sin(rad);

  // Calculate all four corners directly from the top-left corner:
  // The 4 corners in order: top-left, top-right, bottom-right, bottom-left
  const x1 = x;
  const y1 = y;

  const x2 = x + width * cos;
  const y2 = y + width * sin;

  const x3 = x + width * cos + height * sin;
  const y3 = y + width * sin - height * cos;

  const x4 = x + height * sin;
  const y4 = y - height * cos;

  // Store as flattened x1, y1, x2, y2, etc. format
  return {
    ...rest,
    type: "rotatable-rectangle",
    coordinates: {
      x1,
      y1,
      x2,
      y2,
      x3,
      y3,
      x4,
      y4,
    },
  };
};

export { toMLFormat, fromMLFormat, toRotatableMLFormat };
