import React, { useState, useEffect } from "react";
import api from "../../../api";
import "./UserRatingsDashboardStyles.css";

const TimeRangeSelector = ({ value, onChange }) => (
  <div className="time-range">
    <div className="select-wrapper">
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        <option value="30">last 30 days</option>
        <option value="60">last 60 days</option>
        <option value="90">last 90 days</option>
      </select>
    </div>
  </div>
);

const StatsBar = ({ stats }) => (
  <div className="stats-bar">
    <div className="stat-item">
      <div className="stat-value">
        {stats.averageRating?.toFixed(1) || "0.0"}
      </div>
      <div className="stat-label">Avg. Rating</div>
    </div>
    <div className="divider"></div>
    <div className="stat-item">
      <div className="stat-value">{stats.pending}</div>
      <div className="stat-label">pending review</div>
    </div>
    <div className="divider"></div>
    <div className="stat-item">
      <div className="stat-value">{`${stats.accepted}/${stats.rejected}`}</div>
      <div className="stat-label">accepted/rejected</div>
    </div>
  </div>
);

const getDisplayStatusText = (statusText) => {
  // Convert kebab-case to readable format for display
  if (statusText === "outright-rejected") {
    return "OUTRIGHT REJECTED";
  }
  return statusText;
};

const TaskCard = ({ task, onClick }) => (
  <div className="task-card" onClick={onClick} style={{ cursor: "pointer" }}>
    <div className="task-header">
      <div className="task-meta">
        <span className="task-id">#{task.taskResultId}</span>
        <span className={`status-badge ${task.statusText}`}>
          {getDisplayStatusText(task.statusText)}
        </span>
        <div className="ratings-list">
          {task.ratings?.map((rating, index) => (
            <span key={index} className="rating-badge">
              {rating.criteriaName}: {rating.rating}
            </span>
          ))}
        </div>
      </div>
    </div>
    <div className="task-comments">
      {task.comments?.length > 0 && (
        <p className="comment-text">
          <span className="comment-label">Comment:</span>{" "}
          {task.comments[0].selectedOption}
          {task.comments[0].additionalComment &&
            ` ${task.comments[0].additionalComment}`}
        </p>
      )}
    </div>
  </div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="user-ratings-pagination-wrapper">
    <div className="user-ratings-pagination">
      <button
        className="user-ratings-pagination-btn"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        ⬅️ Previous
      </button>
      <p className="user-ratings-pagination-text">
        Task {currentPage} of {totalPages}
      </p>
      <button
        className="user-ratings-pagination-btn"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next ➡️
      </button>
    </div>
  </div>
);

const TasksContent = ({ taskType, onTaskClick }) => {
  const sessionKey = localStorage.getItem("sessionKey");
  const [timeRange, setTimeRange] = useState("30");
  const [currentPage, setCurrentPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [stats, setStats] = useState({
    averageRating: 0,
    accepted: 0,
    rejected: 0,
    pending: 0,
  });

  const getApiEndpoint = () => {
    const baseUrl = "/tasker-dashboard";
    return {
      stats: `${baseUrl}/${taskType}-tasks/stats`,
      tasks: `${baseUrl}/${taskType}-tasks`,
    };
  };

  const fetchStats = async () => {
    try {
      const response = await api.get(getApiEndpoint().stats, {
        params: {
          sessionKey,
          days: timeRange,
        },
        headers: { "Cache-Control": "no-cache" },
      });
      setStats(response.data);
    } catch (err) {
      console.error(`Error fetching ${taskType} stats:`, err);
      setError(err.response?.data?.message || "Failed to load stats");
    }
  };

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await api.get(getApiEndpoint().tasks, {
        params: {
          sessionKey,
          page: currentPage,
          limit: 10,
        },
        headers: { "Cache-Control": "no-cache" },
      });

      const { tasks, totalPages } = response.data;
      setTasks(tasks);
      setTotalPages(totalPages);
    } catch (err) {
      console.error(`Error fetching ${taskType} tasks:`, err);
      setError(err.response?.data?.message || "Failed to load tasks");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sessionKey) {
      fetchStats();
    }
  }, [timeRange, taskType]);

  useEffect(() => {
    if (sessionKey) {
      fetchTasks();
    }
  }, [currentPage, taskType]);

  const handleTimeRangeChange = (newRange) => {
    setTimeRange(newRange);
    setCurrentPage(1);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div>
      <TimeRangeSelector value={timeRange} onChange={handleTimeRangeChange} />
      <StatsBar stats={stats} />

      <div className="tasks-section">
        <h2 className="user-ratings-dashboard-section-title">Tasks</h2>
        {loading ? (
          <div className="loading">Loading...</div>
        ) : tasks.length === 0 ? (
          <div className="no-tasks">No tasks found</div>
        ) : (
          <div className="tasks-list">
            {tasks.map((task) => (
              <TaskCard
                key={task.taskResultId}
                task={task}
                onClick={() => onTaskClick(task.taskResultId)}
              />
            ))}
          </div>
        )}

        {!loading && tasks.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default TasksContent;
