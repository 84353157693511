import React from "react";
import { TermConditionContainer } from './Style'
export default function index() {
    return (
        <TermConditionContainer>
            <h1>
                <b>Terms and Conditions</b>
            </h1>
            <h4>1. Personal Information</h4>
            <p>
                "The Data Taskers platform" is the trademark of Integrated Digital
                Systems, with its registered office at 4B Sundaram, 46F Rafi Ahmed
                Kidwai Road, Kolkata 700016.
                <br />
                <br />
                The Company respects your privacy and values the trust you place in it.
                Set out below is the Company's 'Privacy Policy' which details the manner
                in which information relating to you is collected, used and disclosed.
                <br />
                <i>
                    <br />
                    If you do not agree with the terms and conditions of our Privacy
                    Policy, including in relation to the manner of collection or use of
                    your information, please do not use or access the Site, install the
                    app or continue with the registration process.
                </i>
                <br />
                <br />
                You and we are required to follow certain rules while you use our
                Platform. We have listed these rules in these Terms. Please read these
                Terms and all other hyperlinks mentioned here carefully. Do remember
                that by using our Platform, you agree to these Terms. Also, if you are
                using these services outside India, please comply with your local laws.
                <br />
                <br />
                Our Privacy Policy is incorporated into the Terms and Conditions of Use
                of the Site, and is subject to change from time to time without notice.
                It is strongly recommended that you periodically review our Privacy
                Policy as posted on the Site.
                <br />
                <br />
                <p>
                    Should you have any clarifications regarding this Privacy Policy,
                    please do not hesitate to contact us at{" "}
                    <a style={{ color: "blue" }} href="mailto:info@thedatataskers.com">
                        info@thedatataskers.com
                    </a>
                </p>
            </p>
            <h4>2. Contractual Relationship</h4>
            <p>
                These Terms of Use ("<em>Terms</em>") govern your use of our website
                located at{" "}
                <a style={{ color: "blue" }} href="https://thedatataskers.com">
                    www.thedatataskers.com
                </a>{" "}
                and/or{" "}
                <a style={{ color: "blue" }} href="https://taskers.thedatataskers.com">
                    www.taskers.thedatataskers.com
                </a>{" "}
                {/* and/or{" "}
                <a style={{ color: "blue" }} href="https://meragaon.page.link/webapp">
                    https://meragaon.page.link/webapp
                </a> */}
                all together collectively, the“Platform” made available by Integrated
                Digital Systems (“The Data Taskers platform”, “we”, “us” and “our”), a
                private company established under the laws of India having its
                registered office at 4B Sundaram, 46F Rafi Ahmed Kidwai Road, Kolkata
                700016. The terms “you” and “your” or “user” refer to any person or
                entity using our Platform.
            </p>
            <h4>
                Please read these terms carefully before accessing or using the
                services. These Terms are to be read with the The Data Taskers Privacy
                Policy. If you do not agree with the terms and conditions of these
                Terms, please do not use this Platform.
            </h4>
            <p>
                Your access and use of the Services constitutes your agreement to be
                bound by these Terms, which establishes a contractual relationship
                between you and The Data Taskers platform. If you do not agree to these
                Terms, you may not access or use the Services.
            </p>
            <p>
                Our Services (as we have described below in detail) and these Terms are
                compliant with the Indian Penal Code, 1860, and Information Technology
                Act, 2000, including all amendments made to it and rules framed under
                it. When you create an account on our Platform or use our Platform or
                any of our Services, you accept and agree to these Terms. However,
                please note that we do not represent that we are compliant with laws of
                any country apart from the Republic of India. If you wish to use our
                Services, please ensure that you are permitted to do so, in your
                jurisdiction.
            </p>
            <p>
                The Terms of Use herein are subject to change from time to time by the
                Company without notice. It is strongly recommended that you periodically
                review the Terms of Use as available on the Application.
            </p>
            <p>
                You and we are required to follow certain rules while you use our
                Platform. We have listed these rules in these Terms. Please read these
                Terms and all other hyperlinks mentioned here carefully. Do remember
                that by using our Platform, you agree to these Terms. Also, if you are
                using these services outside India, please comply with your local laws.
            </p>
            <p>
                The terms 'visitor', 'user', 'you' hereunder refer to the person
                visiting, accessing, browsing through and/or using the Site or the app
                at any point in time.
            </p>
            <br />
            <p>
                Should you have any clarifications regarding the Terms of Use, please do
                not hesitate to contact us a{" "}
                <a style={{ color: "blue" }} href="mailto:info@thedatataskers.com">
                    info@thedatataskers.com.
                </a>
            </p>
            <h4>3. Services Overview</h4>
            <p>
                Our platform facilitates the collection, annotation, and contribution of
                images, audio recordings, and other data for AI training and research
                purposes. It enables users to participate in data-related tasks, earn
                incentives, and contribute valuable datasets to the digital ecosystem.
            </p>
            <br />
            <p>
                Users may engage in specific data collection assignments as per the
                guidelines provided on the platform. The Data Taskers does not act as a
                buyer, seller, or intermediary for any commercial transaction between
                users.
            </p>
            <br />
            <p>
                YOU ACKNOWLEDGE THAT THE PLATFORM DOES NOT ENGAGE IN THE BUYING OR
                SELLING OF ANY GOODS, SERVICES, OR DATA OUTSIDE THE SCOPE OF ITS DATA
                COLLECTION TASKS. The Data Taskers does not authorize its employees,
                affiliates, or partners to enter into any commercial contracts on behalf
                of users.
            </p>
            <h4>4. Eligibility</h4>
            <p>
                Services on the Platform are currently available within India. Persons
                who are "incompetent to contract" within the meaning of the Indian
                Contract Act, 1872 including minors, un-discharged insolvents etc. are
                not eligible to use the Site. If you are a minor, i.e. under the age of
                18 years, you are not entitled to use the Platform.The Company reserves
                the right to terminate or refuse your registration, or refuse to permit
                access to the Site, if it is discovered or brought to its notice that
                you are a minor.
            </p>
            <br />
            <p>
                You may use our Services only if you are capable of forming a binding
                agreement with us and are legally permitted to use our Services. If you
                are accepting these Terms on behalf of a company or any legal persons,
                then you represent and warrant that you have the authority to bind such
                an entity to these Terms and effectively “you” and “your” shall refer to
                the company.
            </p>
            <br />
            <p>
                {" "}
                Please ensure that you are allowed to use our services under the law.
            </p>
            <h4>5. License</h4>
            <p>
                Subject to your compliance with these Terms, The Data Taskers platform
                grants you a limited, non-exclusive, non-sublicensable, revocable,
                non-transferable licence to: (i) access and use the Applications on your
                personal device solely in connection with your use of the Services; and
                (ii) access and use any content, information and related materials that
                may be made available through the Services, in each case solely for your
                use. Any rights not expressly granted herein are reserved by The Data
                Taskers platform and The Data Taskers platform's licensors.
            </p>
            <h4>6. Restrictions</h4>
            <p>
                The limited sub- licence does not include/permit any derivative use of
                the Platform or its contents; any downloading or copying of information
                for the benefit of another user or company or merchant; or any use of
                data mining, robots, or similar data gathering and extraction tools. The
                Application or any portion of the Platform may not be reproduced,
                duplicated, copied, sold, resold, visited, or otherwise exploited for
                any commercial purpose without express written consent of the Company.
                You may not frame or utilise framing techniques to enclose any
                trademark, logo, or other proprietary information (including images,
                text, page layout, or form) of the Application or of the Company and/or
                its affiliates without the express written consent of the Company. You
                may not use any meta tags or any other "hidden text" utilising the
                Company's name or trademarks without the express written consent of the
                Company. You shall not attempt to gain unauthorised access to any
                portion or feature of the Platform, or any other systems or networks
                connected to the App or to any server, computer, network, or to any of
                the services offered on or through the Platform, by hacking, 'password
                mining' or any other illegitimate means.
            </p>
            <p>
                {" "}
                You agree not to use the Site or App for any of the following purposes:
            </p>
            <p>
                1. Disseminating any unlawful, harassing, blasphemous, defamatory,
                obscene, pornographic, paedophilic, libellous, invasive of another's
                privacy, hateful, or racially, ethnically objectionable, disparaging,
                relating or encouraging money laundering or gambling, or otherwise
                unlawful in any manner whatever.
            </p>
            <p>
                2. Transmitting material that encourages conduct that constitutes a
                criminal offence or results in civil liability or otherwise breaches any
                relevant laws, regulations or code of practice.
            </p>
            <p>3. Gaining unauthorized access to other computer systems.</p>
            <p>
                4. Interfering with any other person's use or enjoyment of the Platform.
            </p>
            <p>5. Breaching any applicable laws.</p>
            <p>
                6. Interfering or disrupting networks or web sites connected to the
                Site.
            </p>
            <p>
                7. Making, transmitting or storing electronic copies of materials
                protected by copyright without the permission of the owner.
            </p>
            <p>8. Impersonating another person.</p>
            <p>9. Harms minors in any way.</p>
            <p>10. Use the site for child or human trafficking.</p>
            <p>
                11. Posts that are intended to or tend to harass, annoy, threaten or
                intimidate any other User of the Platform or services.
            </p>
            <p>
                12. Intended to defraud, swindle or deceive other users of the services.
            </p>
            <p>
                13. Promotes or solicits involvement in or support of a political
                platform, religion, cult, or sect.
            </p>
            <p>
                14. Disseminates another person's personal information without
                permission, or collects or solicits another person's personal
                information for commercial or unlawful purposes.
            </p>
            <p>
                15. Is in any way used for or in connection with spamming, spimming,
                phishing, trolling, or similar activities.
            </p>
            <p>
                16. Infringes any patent, trademark, copyright or other proprietary
                rights.
            </p>
            <p>17. Violates any law for the time being in force.</p>
            <p>
                18. Deceives or misleads the addressee about the origin of such messages
                or communicates any information which is grossly offensive or menacing
                in nature.
            </p>
            <p>
                19. Impersonates another person or otherwise misrepresents affiliation,
                connection or association with any person or entity.
            </p>
            <p>
                20. Contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the functionality of
                any computer resource.
            </p>
            <p>
                21. Do or post anything on the Platform that may make other Users feel
                unsafe using the Platform.
            </p>
            <p>
                22. Threatens the unity, integrity, defence, security or sovereignty of
                India, friendly relations with foreign states, or public order or causes
                incitement to the commission of any cognizable offence or prevents
                investigation of any offence or is insulting any other nation.
            </p>
            <p>
                23. Conducting any activities that are misleading or known to be false
                in any way.
            </p>
            <p>24. All user submissions are subject to moderation.</p>
            <p>25. Illegal, misleading, or offensive content will be removed.</p>
            <p>26. Users posting inappropriate content may be banned.</p>
            <h4>7. Account &amp; Registration Obligations</h4>
            <p>
                All users have to download and register to use the services of the
                Platform. You must keep your account and registration details current
                and correct for communications related to your activities in the
                Platform. To use our Services, you must register on our Platform by
                entering your phone number and the One-Time-Password sent by us via SMS
                to your phone number. When you register with us using the The Data
                Taskers platform, you also allow us to access your mobile gallery,
                mobile device storage, and mobile device camera. In case you wish to
                invite others to the app you can also choose to give us access to your
                contacts. However, we do not read any information stored on your mobile
                device and computer without your permission. In order to provide
                Services to you, we need to access certain features of your mobile
                device.
            </p>
            <br />
            <p>
                By agreeing to the Terms of Use, the user agrees to receive
                communication and newsletters from the Company and its partners. We may
                from time to time send you service-related announcements when we
                consider it necessary to do so (such as when we temporarily suspend the
                Platform for maintenance, or security, privacy, or
                administrative-related communications) or promotional announcements. We
                send these to you via SMS or WhatsApp or Voice Broadcast. You may not
                opt-out of these service-related announcements, which are not
                promotional in nature and used solely to protect your account and keep
                you informed of important changes to the Platform. However you may opt
                out of promotional announcements.. All users also agree to receive text
                messages or SMS or WhatsApp on their mobile for the services they click
                or they communicate to the call centre.
            </p>
            <h4>8. Promotion & Rewards</h4>
            <p>
                <strong>Scratch Cards</strong>
            </p>
            <p>
                From time to time, the Company may at its discretion offer a user
                promotional digital scratch card entitling you to redeem the applicable
                value of such cards on the Platform. The redemption will happen only
                when the user enters his/her valid UPI number and/or Bank account. When
                using promotional scratch cards the user cannot claim refund values in
                rupees or any other currency and neither will it be credited into your
                account for any unsuccessful deal.
            </p>
            <p>
                <strong>Referral/Invitation Rewards</strong>
            </p>
            <p>
                From time to time, the Company may at its discretion offer a user reward
                on the referral or user that he or she brings into the platform. The
                user can claim the reward only when the invited user joins the Platform.
                The value of this reward is decided by the company and may change at its
                discretion. The user can redeem the applicable value of such a reward on
                the Platform. The redemption will happen only when the user enters
                his/her valid UPI number and/or Bank account.
            </p>
            <p>
                When using such a reward the user cannot claim refund values in rupees
                or any other currency and neither will it be credited into your account
                for any unsuccessful invitation.
            </p>
            <p>
                <strong>Contests Rewards</strong>
            </p>
            <p>
                From time to time, the Company may at its discretion offer a user reward
                on various contests that the company may introduce. These rewards or
                contests are ad hoc and at the discretion of the company and can be
                removed anytime. The decision of the company will be binding as to the
                content, format, value, winners or any other aspect of the contest and
                no user can claim compensation or reward or money whatsoever for any
                participation that the user may have made.
            </p>
            <p>
                These rewards, when won, can be redeemed on the Platform. The redemption
                will happen only when the user enters his/her valid UPI number and/or
                Bank account.
            </p>
            <p>
                <strong>Lucky Draw</strong>
            </p>
            <p>
                The company from time to time announces “Lucky Draw” for its users and
                prospective users. These Lucky Draw are ad hoc and at the discretion of
                the company and can be removed anytime or withdrawn anytime.
            </p>
            <p>
                The decision of the company will be binding as to the content, format,
                value, winners or any other aspect of the contest and no user can claim
                compensation or reward or money whatsoever for any participation that
                the user may have made.
            </p>
            <p>
                These rewards, if in kind, have to be collected from the designated
                location that the company will announce and, if the reward is financial,
                then this has to be redeemed on the Platform. The redemption will happen
                only when the user enters his/her valid UPI number or bank account.
            </p>
            <p>
                The company at its discretion may discontinue any of the above Promotion
                or tran without liability to any of the users. None of the users can
                hold the company liable for any payments to be made under such Promotion
                & Reward program.
            </p>
            <h4>9. User-Submitted Content and Copyright Ownership</h4>
            <h4>Full Ownership Transfer of Submitted Content</h4>
            <p>
                By submitting any content to <strong>The Data Taskers </strong>platform,
                you hereby irrevocably and unconditionally transfer all rights,
                ownership, and intellectual property of the submitted material to{" "}
                <strong>The Data Taskers</strong>.
            </p>
            <p>
                <strong>9.1. Permanent Transfer of Rights</strong>
                <br></br>• Upon submission, you relinquish all copyrights, trademarks,
                and proprietary rights to the submitted content. However, rejected tasks
                remain the property of the user.
            </p>
            <p>
                • The Data Taskers becomes the sole owner of all intellectual property
                rights associated with the content.
            </p>
            <p>
                <strong>9.2. Unrestricted Usage Rights</strong>
                <br></br>• The Data Taskers has the unrestricted right to store, modify,
                reproduce, distribute, commercialize, and sublicense the content in any
                form, including for AI training, research, and third-party licensing.
            </p>
            <p>
                <strong>9.3. No Compensation</strong>
                <br></br>• You acknowledge that you will not receive any royalties,
                fees, or future compensation for the submitted content, regardless of
                how it is used, except for what has been mentioned in the Data Taskers
                web and app before you undertook and submitted the task.
            </p>
            <p>
                <strong>9.4. Irrevocability</strong>
                <br></br>• Once submitted, you cannot withdraw, request deletion, or
                alter the content in The Data Taskers database.
            </p>
            <p>
                <strong>9.5. Compliance & Third-Party Rights</strong>
                <br></br>• You confirm that the submitted content is original and does
                not infringe any third-party copyrights, trademarks, or privacy rights.
            </p>
            <p>
                • You are responsible for ensuring that no unauthorized or illegal
                content is submitted.
            </p>
            <p>
                By uploading, submitting, or otherwise providing any content, including
                but not limited to images, audio recordings, and videos ('User-Submitted
                Content'), to the The Data Taskers platform, you hereby grant The Data
                Taskers platform and its affiliates a worldwide, non-exclusive,
                royalty-free, sublicensable, and transferable license to use, reproduce,
                distribute, prepare derivative works of, display, and perform the
                User-Submitted Content in connection with the The Data Taskers platform
                and its business operations, including but not limited to advertising,
                marketing, and promotional activities.
            </p>
            <p>
                You acknowledge and agree that in cases where you have received
                compensation, whether in cash, kind, or Tasker Sicca, for the specific
                content, The Data Taskers platform may use, sell, or commercially
                exploit the content so submitted without any further obligation to
                compensate you or any other user for such use.
            </p>
            <p>
                This provision shall survive the termination or expiration of these
                Terms of Service.
            </p>
            <h4>10. Modification of Terms &amp; Conditions of Service</h4>
            <p>
                The Platform may at any time modify the Terms &amp; Conditions of Use of
                the Site/App without any prior notification to you. You can access the
                latest version of these Terms &amp; Conditions at any given time on the
                Platform. You should regularly review the Terms &amp; Conditions on the
                Platform. In the event the modified Terms &amp; Conditions are not
                acceptable to you, you should discontinue using the Service. However, if
                you continue to use the Service you shall be deemed to have agreed to
                accept and abide by the modified Terms &amp; Conditions of Use of this
                Platform.
            </p>
            <h4>11. You Agree and Confirm</h4>
            <p>
                a) That any transaction on the Platform is between you and the third
                party/user and the role of the Platform is that of a facilitator.
            </p>
            <p>
                b) That you will use the services provided by the Platform for lawful
                purposes only, and comply with all applicable laws and regulations while
                using and transacting on the Platform.
            </p>
            <p>
                c) You will provide authentic and true information in all instances
                where any information is requested of you. The Company reserves the
                right to confirm and validate the information and other details provided
                by you at any point of time.
            </p>
            <p>
                If at any time, the information provided by you is found to be false or
                inaccurate (wholly or partly), the Company shall have the right in its
                sole discretion to reject registration, cancel all posts, messages,
                advertisements or videos and debar you from using its services and other
                affiliated services in the future without any prior intimation
                whatsoever, and without any liability to you.
            </p>
            <p>
                d) That you are accessing the services available on the Platform and
                transacting at your sole risk and are using your best and prudent
                judgement before entering into any transaction through the Platform.
            </p>
            <p>
                e) You agree that You shall not access the Platform by any other means
                other than through the interfaces that are provided by Us.
            </p>
            <p>
                f) You agree that You shall not acquire, copy, or monitor any portion of
                the Platform or the Content in any way to reproduce or circumvent the
                navigational structure or presentation of the Platform, to obtain or
                attempt to obtain any materials, documents, or information through any
                means not specifically made available through the Platform.
            </p>
            <p>
                g) You agree that You shall not attempt to reverse engineer, de-encrypt,
                or otherwise derive the design, internal logic, structure or inner
                workings (including algorithms and source code) of the Platform, any
                other software, products, models, prototypes, or other items provided by
                Us.
            </p>
            <p>
                h) You agree that You shall not use any deep link, robot, spider or
                other automatic device or methodology, or any similar or equivalent
                manual process, to access, acquire, download, copy or monitor the
                Platform or any part thereof, or obtain any content through means not
                specifically made available through the Platform.
            </p>
            <p>
                i) You agree that You shall not hack or try to hack data, information,
                software, application stored on the Platform or the Company’s
                infrastructure and use the same for your own benefits whether it results
                in financial gain or not.
            </p>
            <p>
                j) You agree that you will inform the Company as soon as you are in
                possession of any unauthorised data, information, software, application
                and delete the same permanently.
            </p>
            <p>
                k) You understand as the Platform and the Terms are available in various
                languages for ease of its users, the Company cannot guarantee or be held
                liable for the accuracy or quality of the translations.
            </p>
            <h4>12. Disclaimer</h4>
            <p>12.1. No Guarantee of Earnings</p>
            <p>
                • Participation in data collection tasks does not guarantee any
                earnings.
            </p>
            <p>
                • Payments are subject to task completion, quality checks, and adherence
                to platform guidelines.
            </p>
            <p>12.2. No Responsibility for Third-Party Use</p>
            <p>
                • Once data is submitted, The Data Taskers is not responsible for how
                third parties (clients, AI research firms, or buyers) use the data.
            </p>
            <p>
                • Users should ensure they are comfortable with the ownership transfer
                before submission.
            </p>
            <p>12.3. No Warranty on Platform Availability</p>
            <p>
                • The platform and services are provided "as is" and "as available."
            </p>
            <p>
                • We do not guarantee uninterrupted access, error-free functionality, or
                compatibility with all devices.
            </p>
            <p>12.4. No Liability for Content Uploaded by Users</p>
            <p>
                • The Data Taskers is not liable for any illegal, offensive, or
                infringing content uploaded by users.
            </p>
            <p>
                • Any violations are the sole responsibility of the user, and such
                content may be removed.
            </p>
            <p>12.5. Not a Legal, Financial, or Advisory Service</p>
            <p>
                • The Data Taskers does not provide legal, financial, or any
                professional advice.
            </p>
            <p>
                • Any dataset or information derived from the platform should be
                independently verified by users or third parties.
            </p>
            <p>12.6. Compliance with Local Laws</p>
            <p>
                • Users are responsible for ensuring that their participation complies
                with local laws and regulations in their country.
            </p>
            <p>
                • We do not warrant that our services are legally permissible in all
                jurisdictions.
            </p>
            <p>12.7. No Data Reversal or Deletion Post-Submission</p>
            <p>• Once data is submitted, it cannot be withdrawn or deleted.</p>
            <p>
                • Users should be fully aware of this policy before uploading content.
            </p>
            <p>12.8. Limited Liability for Losses</p>
            <p>
                • The Data Taskers is not responsible for any direct, indirect,
                incidental, or consequential damages resulting from the use of the
                platform.
            </p>
            <p>
                • This includes, but is not limited to, data loss, loss of earnings, or
                reputational harm.
            </p>
            <p>12.9. Changes to Terms & Conditions</p>
            <p>
                • The company reserves the right to update or modify terms without prior
                notice.
            </p>
            <p>
                • Continued use of the platform after changes implies acceptance of the
                updated terms.
            </p>
            <p>
                12.10 The Data Taskers only facilitates task-based incentives and does
                not guarantee external payments, refunds, or third-party transactions.
            </p>
            <h4>
                13. Comments, Images, Video, Audio, Messages, Reviews, Feedback,
                Submission
            </h4>
            <p>13.1 Ownership & Transfer of Rights</p>
            <p>
                • All images, audio recordings, videos, text, or other content uploaded
                by users to The Data Taskers immediately and irrevocably become the
                exclusive property of The Data Taskers.
            </p>
            <p>
                • The Data Taskers has the unrestricted right to store, modify, analyze,
                distribute, sublicense, and commercialize the content in any form,
                including for AI training, research, and dataset sales.
            </p>
            <p>13.2 Compensation & Task-Specific Terms</p>
            <p>
                • Users acknowledge that they will only receive compensation if
                explicitly mentioned in the task details before submission.
            </p>
            <p>
                • No additional royalties, fees, or claims can be made once the content
                is submitted.
            </p>
            <p>13.3 Content Restrictions & Compliance</p>
            <p>
                • Users must not upload content they do not own or do not have rights to
                submit.
            </p>
            <p>
                • Users must not upload personal, private, or sensitive information of
                any individual without explicit consent.
            </p>
            <p>
                • Any content that is illegal, defamatory, abusive, misleading,
                offensive, or violates privacy laws will be removed.
            </p>
            <p>
                • The Data Taskers reserves the right to reject, delete, or ban users
                who violate these terms.
            </p>
            <p>13.4 No Responsibility for Third-Party Use</p>
            <p>
                • Once content is submitted, The Data Taskers is not responsible for how
                third-party clients, AI researchers, or dataset buyers use the content.
            </p>
            <p>
                • Users should ensure they are comfortable with the ownership transfer
                before submitting any data.
            </p>
            <h4>14. Copyright &amp; Trademark</h4>
            <p>14.1 Ownership of Platform Content</p>
            <p>
                • The The Data Taskers platform, including its technology, software,
                branding, proprietary datasets, and all associated content, is the
                exclusive intellectual property of The Data Taskers.
            </p>
            <p>
                • Accessing or using the platform does not grant users any ownership
                rights over The Data Taskers’ intellectual property.
            </p>
            <p>14.2 Trademarks & Branding</p>
            <p>
                • The name, logo, slogans, and service marks of The Data Taskers are
                protected trademarks.
            </p>
            <p>
                • Users may not use, modify, or reproduce The Data Taskers' trademarks
                or branding for any commercial or non-commercial purpose without
                explicit written permission.
            </p>
            <p>14.3 Use of Proprietary Datasets & AI-Generated Content</p>
            <p>
                • The datasets, AI-generated content, and labeled data provided by The
                Data Taskers are protected under copyright laws and cannot be copied,
                resold, or distributed outside permitted platform use.
            </p>
            <p>
                • Any unauthorized use of The Data Taskers' proprietary data may result
                in legal action.
            </p>
            <p>14.4 No Automatic License to Users</p>
            <p>
                • Users do not receive any rights or licenses to The Data Taskers'
                datasets, AI models, or proprietary algorithms.
            </p>
            <p>
                • The use of data is strictly limited to approved tasks and must comply
                with platform policies.
            </p>
            <p>14.5 Third-Party References & Links</p>
            <p>
                • Any third-party names, brands, or trademarks appearing on the platform
                are for reference only and do not imply endorsement by The Data Taskers.
            </p>
            <p>
                • The Data Taskers is not responsible for the content, accuracy, or
                legality of third-party sites linked from the platform.
            </p>
            <p>14.6 Restrictions on Copying & Redistribution</p>
            <p>
                • Users may not copy, download, or distribute platform content
                (including datasets) without explicit permission.
            </p>
            <p>
                • Violations of copyright and trademark laws may result in account
                suspension, legal action, and financial penalties.
            </p>
            <h4>15. Objectionable Material</h4>
            <p>
                • Users may encounter unfiltered or raw data collected from
                contributors.{" "}
            </p>
            <p>
                • The Data Taskers is not liable if any data appears inappropriate, as
                long as it complies with applicable laws and AI dataset policies.{" "}
            </p>
            <p>
                • The platform has the right to remove any data deemed offensive,
                misleading, or inappropriate for AI training.{" "}
            </p>
            <h4>16. Indemnity</h4>
            <p>
                • Users agree to indemnify The Data Taskers for any legal claims related
                to their content submissions, especially if they upload copyrighted,
                illegal, or false data.{" "}
            </p>
            <p>
                • Covers privacy violations, intellectual property infringements,
                defamation, and fraudulent activities.{" "}
            </p>
            <p>
                • This protects The Data Taskers from lawsuits arising from user
                misconduct.{" "}
            </p>
            <h4>17. Limitation of Liability</h4>
            <p>
                • The Data Taskers will not be responsible for indirect or consequential
                damages.{" "}
            </p>
            <p>
                • If any liability is proven, compensation cannot exceed any fees paid
                (if applicable).{" "}
            </p>
            <h4>18. Termination</h4>
            <p>
                The platform has the right to suspend or terminate accounts if users:
                <br />
                a) Upload misleading, illegal, or harmful data.
                <br />
                b) Violate copyright, privacy, or AI ethics.
                <br />
                c) Provide false information or fail verification checks.
            </p>
            <h4>19. Governing Law and Jurisdiction</h4>
            <p>
                This User Agreement shall be construed in accordance with the applicable
                laws of India. The Courts at Delhi shall have exclusive jurisdiction in
                any proceedings arising out of this agreement. Any dispute or difference
                either in interpretation or otherwise, of any terms of this User
                Agreement between the parties hereto, the same shall be referred to an
                independent arbitrator who will be appointed by the Company and his
                decision shall be final and binding on the parties hereto. The above
                arbitration shall be in accordance with the Arbitration and Conciliation
                Act, 1996 as amended from time to time. The arbitration shall be held in
                New Delhi. The High Court of judicature at Delhi alone shall have
                jurisdiction and the Laws of India shall apply.
                <br />
            </p>
            <h4>20. Grievance Policy</h4>
            <p>
                In compliance with global data privacy regulations (GDPR, CCPA, IT Rules
                2021, etc.), The Data Taskers has appointed a Grievance Officer to
                address:
                <br />
                • Privacy concerns, including the misuse of user data.
                <br />
                • Copyright and intellectual property disputes.
                <br />
                • Ethical concerns about AI datasets.
                <br />• Requests for data deletion (where applicable under law).
            </p>
            <p>
                <b>The Grievance Officer</b>
                <br />
                <b>Integrated Digital Systems </b>
                <br />
                Email address:{" "}
                <a style={{ color: "blue" }} href="mailto:info@thedatataskers.com">
                    info@thedatataskers.com
                </a>
            </p>
            <br />
            <h1 align="center">
                <b>Privacy Policy</b>
            </h1>
            <p>
                Welcome to The Data Taskers. This Privacy Policy explains how we
                collect, use, store, and disclose personal and uploaded data when you
                use our platform, website, and services. By accessing or using The Data
                Taskers platform, you agree to the collection, processing, and use of
                your data as described in this Privacy Policy. If you do not agree,
                please do not use the platform, install the app, or register for an
                account.
                <br />
                <br />
                This Privacy Policy is incorporated into and subject to our Terms &
                Conditions and may be updated periodically without prior notice.
                <p>
                    For any privacy-related concerns, contact us at{" "}
                    <a style={{ color: "blue" }} href="mailto:info@thedatataskers.com">
                        info@thedatataskers.com
                    </a>
                    .
                </p>
            </p>
            <br />
            <strong>1. Information We Collect</strong>
            <br />
            We collect different types of data from users, including:
            <p>1.1. Personal Information</p>
            <p>
                • Name, email address, phone number, and location.
                <br />
                • Demographic information (e.g., age, gender, language preference).
                <br />
                • Device information (IP address, browser type, operating system).
                <br />
            </p>
            <p>1.2. Content & Uploaded Data</p>
            <p>
                • Images, audio recordings, text, and metadata submitted by users.
                <br />
                • Task-related responses and annotations contributed for AI training.
                <br />
            </p>
            <p>1.3. Usage Data</p>
            <p>
                • Activity logs, including task submissions, logins, and interactions.
                <br />
                • Geolocation data when a task requires location-based validation.
                <br />
            </p>
            <br />
            <strong>2. How We Use Your Data</strong>
            <br />
            We process and use your data for the following purposes:
            <p>To Provide & Improve Platform Services</p>
            <p>
                • Enable users to contribute data for AI model training.
                <br />
                • Ensure smooth functionality and security of the platform.
                <br />
            </p>
            <p>For AI Training & Research</p>
            <p>• Use submitted content to train machine learning models.</p>
            <p>• Generate datasets for research and commercial applications.</p>
            <p>For Compliance & Legal Purposes</p>
            <p>
                • Ensure compliance with data protection laws (GDPR, CCPA, IT Rules
                2021, etc.).
            </p>
            <p>• Prevent fraud, abuse, and unauthorized access.</p>
            <p>For Communication & Support</p>
            <p>
                • Contact users regarding platform updates, policies, or task-related
                queries.
            </p>
            <p>• Provide customer support for privacy concerns and grievances.</p>
            <br />
            <strong>3. Data Storage & Retention</strong>
            <br />
            <p>Storage Location:</p>
            <p>• User data is stored on secure cloud servers.</p>
            <p>
                • Data may be stored in multiple jurisdictions for operational
                efficiency.
            </p>
            <p>Retention Policy:</p>
            <p>
                • Personal data is retained only as long as necessary for platform
                services.
            </p>
            <p>
                • Uploaded content (images, audio, etc.) cannot be deleted once
                submitted, as ownership is transferred to The Data Taskers.
            </p>
            <p>
                • Users can request closure of their personal account, subject to
                compliance with legal requirements. However, their demographic details
                will still remain attached with the content that has been accepted so
                far.
            </p>
            <br />
            <strong>4. Sharing & Disclosure of Data</strong>
            <br />
            <p>
                We do not sell personal information. However, we may share data with:
            </p>
            <p>AI Research & Development Partners</p>
            <p>
                • Datasets may be provided to third-party AI developers, research
                institutions, and businesses.
            </p>
            <p>Law Enforcement & Legal Compliance</p>
            <p>
                • Data may be disclosed to authorities if legally required (e.g.,
                government requests, legal investigations).
            </p>
            <p>Service Providers & Cloud Hosting Partners</p>
            <p>
                • We work with third-party hosting providers to store and manage
                platform data.
            </p>
            <br />
            <strong>5. User Rights & Data Protection Compliance</strong>
            <br />
            <p>
                Users have rights under various global data protection laws, including:
            </p>
            <p>IT Rules 2021 – India</p>
            <p>• Users can file complaints regarding data misuse.</p>
            <p>
                To exercise your rights, contact us at{" "}
                <a style={{ color: "blue" }} href="mailto:info@thedatataskers.com">
                    info@thedatataskers.com
                </a>
            </p>
            <br />
            <strong>6. Security Measures</strong>
            <br />
            <p>
                We take reasonable technical and organizational measures to protect user
                data, including:
            </p>
            <p>• Encryption of sensitive data during transmission and storage.</p>
            <p>• Access controls to prevent unauthorized use of datasets.</p>
            <p>• Regular security audits to ensure data integrity.</p>
            <br />
            <strong>7. Changes to Privacy Policy</strong>
            <br />
            <p>
                We reserve the right to modify this Privacy Policy at any time. Changes
                will be updated on our website, and continued use of the platform
                indicates acceptance of the updated terms.
            </p>
            <h4> Disclosure of your information</h4>
            <br />
            <strong>1. Public Content & User Submissions</strong>
            <p>
                • Any content (images, audio, text, and metadata) submitted to The Data
                Taskers is permanently stored and used for AI training and dataset
                development.
                <br />
                • Users cannot request deletion or modification of submitted AI-related
                content.
                <br />
                • The Data Taskers may distribute, resell, or sublicense submitted
                content to third-party AI developers and research institutions.
                <br />
            </p>
            <strong>2. Data Sharing & Third-Party Disclosure</strong>
            <p>
                We do not sell personal information, but we may share data with:
                <br />
                AI Research Institutions & Corporate Clients.
                <br />
                • AI datasets may be provided to research labs, AI companies, and
                enterprise clients.
                <br />
                Cloud Service Providers & Hosting Partners
                <br />
                • User content and personal data may be stored on third-party cloud
                platforms (AWS, Google Cloud, etc.).
                <br />
                Law Enforcement & Legal Compliance
                <br />
                • We may disclose data if legally required under applicable laws.
                <br />
                Mergers & Acquisitions.
                <br />
                • If The Data Taskers is acquired or merged with another entity, user
                data may be transferred to the new owner.
                <br />
                Updated "User Rights & Security" Section
            </p>
            <strong>3. Security Measures</strong>
            <p>
                • We encrypt sensitive data and implement access controls to protect
                against unauthorized access.
                <br />
                • Users are responsible for securing their login credentials and must
                report suspicious activity immediately.
                <br />
            </p>
            <strong>4. User Control & Data Retention</strong>
            <p>
                • Users can update or correct personal details (e.g., name, phone
                number, email) via account settings.
                <br />
                • Users cannot delete or modify submitted AI training content.
                <br />
            </p>
            <strong>5. Third-Party Embeds & External Links</strong>
            <p>
                • Some platform features may rely on third-party services (e.g., Google
                Maps for location-based tasks).
                <br />
                • We are not responsible for data collection policies of external
                websites or embedded services.
                <br />
            </p>
            <h4> Communications from us</h4>
            <p>
                We may from time to time send you service-related announcements when we
                consider it necessary to do so (such as when we temporarily suspend the
                Platform for maintenance, or security, privacy, or
                administrative-related communications) or promotional announcements. We
                send these to you via SMS or WhatsApp or Voice Broadcast. You may not
                opt-out of these service-related announcements, which are not
                promotional in nature and used solely to protect your account and keep
                you informed of important changes to the Platform. However, you may opt
                out of promotional announcements.
            </p>
            <h4> Policy Updates </h4>
            <p>
                Our Platform is dynamic and may change rapidly. As such, we may change
                the services we provide at our discretion. We may temporarily, or
                permanently, stop providing Services or any features to you generally.
                <br />
                <br />
                We may remove or add functionalities to our Platform and Services
                without any notice. However, if we make a change where your consent is
                required, we will make sure to ask for it. Please be sure to keep
                visiting this page from time to time to stay updated on our latest
                changes and developments.
                <br />
                <br />
                Visit this page to see any changes that we may make and services that we
                may add or modify, from time to time.
            </p>
            <p>
                {" "}
                In the event you wish contact the company or to report a breach of the
                Privacy Policy, you may contact the designated Grievance Officer of the
                Company at:
            </p>
            <br />
            <p>
                <b> Integrated Digital Systems at</b>
                {/* <br /> 90/31B, First Floor, Malviya Nagar,Delhi, South Delhi,
                <br /> Delhi, India, 110017.
                <br />
                <b> Voice recording System:7289000900 </b> */}
                <br /> Email address:{" "}
                <a style={{ color: "blue" }} href="mailto: info@thedatataskers.com">
                    {" "}
                    info@thedatataskers.com
                </a>
            </p>
        </TermConditionContainer>
    );
}