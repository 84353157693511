import React, { Component, useEffect } from "react";
import styled from "styled-components";
import Background1 from "../Image/greenH.png";
import Background2 from "../Image/share.png";
import {
  FaMicrophone,
  FaImage,
  FaVideo,
  FaEdit,
  FaClipboardList,
  FaCamera,
  FaFileAlt,
  FaTable,
  FaQuestion,
} from "react-icons/fa";
import Instagram from "../Image/in.png";
import Facebook from "../Image/fb.png";
import "../App.css";
// import Youtube from "../Image/ut.png";
// import Pinterest from "../Image/pt.png";
// import Tiktok from "../Image/tt.png";
// import Linkedin from "../Image/lk.png";
// import Twitter from "../Image/tw.png";
import { showNotification } from "../actions/index.actions";
import api from "../api";
import FullpageLoader from "../elements/FullpageLoader";
import ErrorUI from "../elements/ErrorUI";
import Logo from "../Image/logo2.PNG";
import { notificationService } from "../services/notificationService";
import {
  NotificationPrompt,
  useNotificationPermission,
} from "../components/NotificationComponents/NotificationPopup";
import RatingCarousel from "../components/UserDashboard/RatingCarousel";
import Modal from "../elements/Modal";
import Legal from "../Legal";
import { NextBtn, TandCSection } from "../Style";
// Sample gradient backgrounds
const gradients = [
  "linear-gradient(180deg, #be3a21, #584a10)",
  "linear-gradient(180deg, #2bbd3d, #080d90)",
  "linear-gradient(180deg, #eac92b, #165308)",
  "linear-gradient(180deg, #3a76cd, #931515)",
  "linear-gradient(180deg, #fe5050, #028e3f)",
  "linear-gradient(180deg, #ffd93db0, #06377ed1)",
  "linear-gradient(180deg, #704dffb5, #846d0fcf)",
  "linear-gradient(180deg, #cbc26b, #d82929b3)",
  "linear-gradient(180deg, #27a4b4, #071d3a)",
  "linear-gradient(180deg, #af47a4, #811111)",
];

const items = [
  // { name: "Voice Tasks", icon: <FaMicrophone /> }, // Microphone for voice tasks
  {
    name: "Voice Tasks",
    icon: <FaMicrophone />,
    href: `/audio-task`,
    disabled: false,
  },
  {
    name: "Image Tasks",
    icon: <FaImage />,
    href: `/image-task`,
    disabled: false,
  }, // Image for image tasks
  {
    name: "Image Annotation",
    icon: <FaCamera />,
    href: `/annotation-task`,
    disabled: true,
  }, // Camera for image annotation
  {
    name: "Video Tasks",
    icon: <FaVideo />,
    href: `/audio-task`,
    disabled: true,
  },
  // Video for video tasks
  {
    name: "Transcription",
    icon: <FaEdit />,
    href: `/audio-task`,
    disabled: true,
  }, // Transcription icon
  {
    name: "Image Analysis",
    icon: <FaClipboardList />,
    href: `/audio-task`,
    disabled: true,
  }, // Clipboard for image analysis

  {
    name: "Text Tasks",
    icon: <FaFileAlt />,
    href: `/audio-task`,
    disabled: true,
  }, // Document for text tasks
  // {
  //   name: "Tabular Tasks",
  //   icon: <FaTable />,
  //   href: `/audio-task`,
  //   disabled: true,
  // }, // Table for tabular tasks
  {
    name: "Earnings",
    icon: <div>₹</div>,
    href: `/tasker-earning-dashboard`,
    disabled: false,
  }, // Dollar sign for earnings
  {
    name: "Help Center",
    icon: <FaQuestion />,
    href: `/support`,
    disabled: false,
  },
];
// Styled components
const Container = styled.div`
  width: 90vw;
  margin: 0 auto;
  padding: 20px 0 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: black;
  @media (max-width: 480px) {
    width: 95vw;
    gap: 10px;
  }
`;

const Sections = styled.div`
  padding: 14px;
  border-radius: 14px;
`;
const RatingSections = styled.div`
  padding: 4px 8px;
  border-radius: 14px;
  background: linear-gradient(160deg, #3f6c0f, #2237a8);
  cursor: pointer;
  h4 {
    margin: 4px 8px 8px 8px;
    font-size: 30px;
    text-align: center;
    color: white;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  div {
    display: flex;
    gap: 4px;
    text-align: center;
    justify-content: space-around;
    p {
      margin: 8px;
      font-size: 24px;
      color: #cecccc;
      @media (max-width: 768px) {
        margin: 4px;
        font-size: 14px;
      }
    }
    span {
      color: white;
      font-weight: 600;
      font-size: 30px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
`;

const Section = styled.div`
  position: relative;
  padding: 14px;
  border-radius: 14px;
  overflow: hidden; /* Prevents image overflow */
  height: 270px; /* Set a fixed height or adjust as needed */
  @media (max-width: 768px) {
    height: 220px;
  }

  @media (max-width: 480px) {
    height: 100px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill; /* Make the image cover the section */
  z-index: 1; /* Ensure the image is behind the text */
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 2; /* Ensure the overlay is above the image */
`;

const Content = styled.div`
  position: relative; /* Positioned above the image */
  z-index: 1; /* Ensure the text is above the image */
  color: white; /* Change text color for better contrast */
  text-align: center;
  top: 20%;
  h4 {
    margin: 14px;
    font-size: 32px;
  }
  p {
    font-size: 24px;
    font-weight: 600;
    margin: 14px 8px;
  }
  @media (max-width: 768px) {
    top: 20%;
    p {
      font-size: 24px;
      font-weight: 600;
      margin: 8px;
    }
    h4 {
      margin: 8px;
      font-size: 28px;
    }
  }

  @media (max-width: 480px) {
    top: 2%;
    p {
      font-size: 18px;
      font-weight: 600;
      margin: 4px;
    }
    h4 {
      margin: 4px;
      font-size: 18px;
    }
  }
`;
const ShareContent = styled.div`
  position: relative; /* Positioned above the image */
  z-index: 1; /* Ensure the text is above the image */
  color: white; /* Change text color for better contrast */
  text-align: center;
  top: 20%;
  width: 60%;
  text-align: left;
  h4 {
    margin: 14px;
    font-size: 32px;
    color: #15580a;
  }
  p {
    font-size: 26px;
    margin: 14px;
    color: #373737;
  }
  @media (max-width: 768px) {
    top: 18%;
    p {
      font-size: 24px;
      margin: 8px;
    }
    h4 {
      margin: 8px;
      font-size: 30px;
    }
  }

  @media (max-width: 480px) {
    width: 60%;
    top: 5%;
    p {
      font-size: 14px;
      margin: 4px;
    }
    h4 {
      margin: 4px;
      font-size: 18px;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridItem = styled.a`
  background: ${(props) => props.gradient};
  padding: 24px;
  text-align: center;
  border-radius: 14px;
  height: 180px;
  text-decoration: none;
  color: white; /* Text color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;

  &:hover {
    transform: scale(0.98);
    border: 1px solid white; /* Optional border on hover */
  }

  @media (max-width: 1024px) {
    height: 160px;
  }
  @media (max-width: 768px) {
    height: 120px;
    border-radius: 6px;
  }
  @media (max-width: 480px) {
    height: 80px;
  }
  @media (max-width: 380px) {
    height: 80px;
    padding: 10px;
  }
`;

const IconWrapper = styled.div`
  font-size: 80px; /* Adjust icon size */
  margin-bottom: 10px; /* Space between icon and text */
  @media (max-width: 768px) {
    font-size: 60px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
    margin-bottom: 0px;
  }
`;

const MarqueeText = styled.div`
  //white-space: nowrap;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;

  & span {
    display: inline-block;
    // animation: marquee 10s linear infinite;
    font-size: 16px;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;
const CopyrightText = styled.p`
  display: inline-block;
  width: 68%;
  vertical-align: middle;
  font-size: 16px;
  margin: 6px 0 0 8px;
  @media (max-width: 900px) {
    width: 58%;
  }
  @media (min-width: 600px) and (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 550px) {
    width: 100%;
    font-size: 14px;
  }
`;

const PagePostFooter = styled.div`
  text-align: left;
  padding: 5px;
  //margin-left: 8px;
  border-top: 1px solid #18171733;
  @media (max-width: 900px) {
    padding: 0;
  }

  .follow-icons-container {
    float: right;
    display: flex;
    gap: 2px;
    margin-left: 4px;

    button {
      background: transparent;
      border: none;
      margin: 0px 6px 6px 6px;

      img {
        height: 26px;
        width: 26px;

        &:hover {
          filter: invert(100%);
          -webkit-filter: invert(100%);
        }
      }
    }

    @media (min-width: 901px) and (max-width: 1024px) {
      // Styles for tablet devices
    }

    @media (max-width: 900px) {
      button {
        font-size: 6px;
      }
    }

    @media (min-width: 426px) and (max-width: 600px) {
      float: left;
      padding-top: 14px;

      button {
        font-size: 6px;
      }
    }

    @media (max-width: 425px) {
      float: left;
      padding-top: 8px;

      button {
        font-size: 6px;
      }
    }
  }
`;
const FooterSections = styled.div`
  padding-top: 14px;
  border-radius: 8px 8px 0 0;
  background: rgb(221 231 255);
  //background: #d8fbfa;
  button {
    border: none;
    background: transparent;
    text-decoration: underline;
    font-size: 14px;
    color: #125790;
    margin: 0px 8px 6px 8px;
    padding: 0;
    cursor: pointer;
    text-align: left;
  }
`;
const FooterDetailsText = styled.p`
  font-size: 14px;
  color: #125790;
  margin: 10px 4px 6px 8px;
  text-decoration: underline;
  a {
    text-decoration: none;
    color: inherit;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    color: #125790;
    margin: 10px 4px 6px 8px;
    text-decoration: underline;
  }
`;
export const LogoName = styled.div`
  height: 70px;
  width: 280px;
  img {
    height: 70px;
    width: 280px;
    object-fit: cover;
  }
  @media (max-width: 576px) {
    height: 60px;
    width: 180px;
    img {
      height: 60px;
      width: 180px;
      object-fit: cover;
    }
  }
`;

const NotificationWrapper = ({ children }) => {
  const {
    showPrompt,
    handleRequestPermission,
    handleClose,
    checkAndShowPrompt,
  } = useNotificationPermission();

  useEffect(() => {
    checkAndShowPrompt();
  }, []);

  return (
    <>
      {children}
      {showPrompt && (
        <NotificationPrompt
          onRequestPermission={handleRequestPermission}
          onClose={handleClose}
        />
      )}
    </>
  );
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      totalData: {},
      voiceData: {},
      imageData: {},
      // userNumber: localStorage.getItem("userNumber"),
      sessionKey: localStorage.getItem("sessionKey"),
      isMobile: false,
      isIos: false,
      isAppInstalled: false,
      installPromptEvent: null,
      showInstallPopup: false,
      showNotificationPrompt: false,
      showTandCModal: false,
      isAccepted: false,
    };
  }
  openFBUrl = () => {
    window.open(
      "https://www.facebook.com/people/The-Data-Taskers/61563780753831",
      "_blank"
    );
  };
  openInstaUrl = () => {
    window.open("https://www.instagram.com/thedatataskers/", "_blank");
  };
  handleLogout = () => {
    localStorage.removeItem("sessionKey");
    localStorage.removeItem("lastWebAppActivity");
    window.open("/", "_self"); // Redirect to login page
  };
  componentDidMount = async () => {
    // const userNumber = localStorage.getItem("userNumber"); // Get "userNumber" from localStorage
    const sessionKey = localStorage.getItem("sessionKey");
    if (!sessionKey) {
      // If there's no "userNumber" found in localStorage
      window.open("/", "_self"); // Redirect user to the login page
    } else {
      this.fetchAllData(sessionKey);

      // handle notification permission:
      // Only attempt notification setup if the browser supports it
      if (notificationService.isSupported()) {
        try {
          const storedToken = notificationService.getStoredToken();

          if (Notification.permission === "granted") {
            await notificationService.initialize();
          } else {
            // Check if we should show the prompt
            const lastPrompt = localStorage.getItem("fcm_notification_prompt");
            const lastPromptData = lastPrompt ? JSON.parse(lastPrompt) : null;
            const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

            if (
              !lastPromptData ||
              Date.now() - lastPromptData.timestamp >= threeDaysInMs
            ) {
              this.setState({ showNotificationPrompt: true });
              localStorage.setItem(
                "fcm_notification_prompt",
                JSON.stringify({
                  timestamp: Date.now(),
                  pathname: window.location.pathname,
                })
              );
            }
          }
        } catch (error) {
          console.error("Error initializing notifications:", error);
        }
      } else {
        console.log("Push notifications are not supported in this browser");
      }
    }
    // Detect device and app status
    this.detectDeviceAndAppStatus();
  };
  handleRequestPermission = async () => {
    if (!notificationService.isSupported()) {
      console.log("Push notifications are not supported in this browser");
      this.setState({ showNotificationPrompt: false });
      return;
    }

    try {
      const isInitialized = await notificationService.initialize();
      if (isInitialized) {
        this.setState({ showNotificationPrompt: false });
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };

  handleCloseNotification = () => {
    this.setState({ showNotificationPrompt: false });
  };
  detectDeviceAndAppStatus = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipod|ipad|android/i.test(userAgent);
    const isIos = /iphone|ipod|ipad/i.test(userAgent);
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;

    this.setState({
      isMobile,
      isIos,
      isAppInstalled: isStandalone,
    });

    // For Android/Chrome/Firefox, handle beforeinstallprompt event
    if (isMobile) {
      window.addEventListener(
        "beforeinstallprompt",
        this.handleBeforeInstallPrompt
      );
    }
  };

  handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    this.setState({
      installPromptEvent: event,
      showInstallPopup: true,
    });
    // Automatically hide the popup after 20 seconds
    setTimeout(() => {
      if (this.state.showInstallPopup) {
        this.setState({ showInstallPopup: false });
      }
    }, 20000);
  };

  handleInstallClick = () => {
    const { installPromptEvent } = this.state;
    if (installPromptEvent) {
      installPromptEvent.prompt();
      installPromptEvent.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
        })
        .catch((err) => console.log("Failed to trigger install prompt", err));
      this.setState({ showInstallPopup: false });
    }
  };

  // Fetch all data for the user
  fetchAllData = async (sessionKey) => {
    this.setState({ isLoading: true });
    try {
      const [totalResponse, voiceResponse, imageResponse] = await Promise.all([
        api.get("/tasker-dashboard/user-dashboard", {
          params: { sessionKey },
          headers: { "Cache-Control": "no-cache" },
        }),
        api.get("/tasker-dashboard/voice-dashboard", {
          params: { sessionKey },
          headers: { "Cache-Control": "no-cache" },
        }),
        api.get("/tasker-dashboard/image-dashboard", {
          params: { sessionKey },
          headers: { "Cache-Control": "no-cache" },
        }),
      ]);
      if (totalResponse.status === 200) {
        this.setState({
          isLoading: false,
          isError: false,
          totalData: totalResponse.data,
          voiceData: voiceResponse.data,
          imageData: imageResponse.data,
          showTandCModal: totalResponse.data.IsTermAndConditionAccepted
            ? false
            : true,
        });
      }
    } catch (error) {
      let errorMsg =
        error.response?.data?.message || "Error fetching user data";
      this.setState({ isLoading: false, isError: true });
      if (error.response?.data?.message === "User not found") {
        this.handleLogout();
      }
      showNotification("Error", errorMsg, "danger");
    }
  };
  fetchUserData = async (sessionKey) => {
    this.setState({ isLoading: true });
    try {
      const response = await api.get("/tasker-dashboard/user-dashboard", {
        params: { sessionKey }, // Use 'params' for query parameters
        headers: { "Cache-Control": "no-cache" }, // Use 'headers' for headers
      });
      if (response.status === 200) {
        this.setState({
          isLoading: false,
          isError: false,
          userData: response.data,
        });
      }
    } catch (error) {
      let errorMsg =
        error.response?.data?.message || "Error fetching user data";
      this.setState({ isLoading: false, isError: true });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message == "User not found"
      ) {
        this.handleLogout();
      }
      showNotification("Error", errorMsg, "danger");
    }
  };
  handleTestModal = () => {
    console.log("testing");
  };
  // Function to submit term and condition
  submitTandC = async () => {
    const sessionKey = localStorage.getItem("sessionKey");
    this.setState({ showTandCModal: false });
    try {
      const response = await api.put(
        `/tasker-dashboard/term-condition?sessionKey=${sessionKey}`,
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );
      if (response.status === 200) {
        this.setState({ showTandCModal: false });
      }
    } catch (error) {
      console.log("Error in accepting T&C");
    }
  };
  // Handle the checkbox change
  handleCheckboxChange = (event) => {
    this.setState({
      isAccepted: event.target.checked, // Update state based on whether checkbox is checked
    });
  };
  render() {
    const {
      totalData,
      voiceData,
      imageData,
      isError,
      isLoading,
      showInstallPopup,
      isMobile,
      isIos,
      isAppInstalled,
      showNotificationPrompt,
      showTandCModal,
      isAccepted,
    } = this.state;
    const sessionKey = localStorage.getItem("sessionKey");
    // console.log("isAccepted", isAccepted);
    return (
      <div style={{ background: "black" }}>
        <Modal
          customStyles={{
            content: {
              width: "90%",
              maxWidth: "100%",
              backgroundColor: "#dde8ff",
            },
            overlay: { backgroundColor: "#000000f0" },
          }}
          title=""
          onClose={() => this.handleTestModal}
          hideClosebtn={true}
          isOpen={showTandCModal}
        >
          <div>
            <div
              style={{
                height: "60vh",
                overflow: "scroll",
                borderBottom: "1px solid #051f516e",
              }}
            >
              <Legal />
            </div>
            <TandCSection>
              <input
                type="checkbox"
                checked={this.state.isAccepted}
                onChange={this.handleCheckboxChange}
              />
              <p>
                I have read and accept the updated{" "}
                <span>Terms & Conditions</span> and <span>Privacy Policy</span>.
              </p>
            </TandCSection>
            <NextBtn
              onClick={this.submitTandC}
              disabled={!isAccepted}
              style={{
                margin: "16px auto 8px auto",
                padding: "14px 16px",
                width: "100%",
              }}
            >
              Accept Our Terms & Conditions
            </NextBtn>
          </div>
        </Modal>
        {isError ? (
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ErrorUI retry={() => this.fetchUserData(sessionKey)} />
          </div>
        ) : null}
        {!isError && !isLoading ? (
          <Container>
            {/* Profile Section */}
            <Section>
              <BackgroundImage alt="" src={Background1} />
              {/* <Overlay /> */}
              <Content>
                <h4>
                  {" "}
                  Welcome, {totalData.name}! 👋 Your next tasks are waiting -
                  complete them and watch your earnings grow! 💪{" "}
                </h4>
              </Content>
            </Section>

            {/* Rating Section */}
            <RatingCarousel
              totalData={this.state.totalData}
              voiceData={this.state.voiceData}
              imageData={this.state.imageData}
              RatingSections={RatingSections}
            />

            {/* Grid Section */}
            <Sections style={{ background: "black", padding: 0 }}>
              <GridContainer>
                {items.map((item, index) => (
                  // <GridItem
                  //     key={index}
                  //     href={item.href} // Use the href specified in the item
                  //     gradient={gradients[index]}
                  // >
                  <GridItem
                    key={index}
                    href={!item.disabled ? item.href : null} // Disable the link if `disabled` is true
                    gradient={gradients[index]}
                    style={{
                      opacity: item.disabled ? 0.5 : 1, // Make the item look disabled
                      pointerEvents: item.disabled ? "none" : "auto", // Prevent click interaction if disabled
                    }}
                  >
                    <IconWrapper>{item.icon}</IconWrapper>
                    <MarqueeText>
                      <span>{item.name}</span>
                    </MarqueeText>
                  </GridItem>
                ))}
              </GridContainer>
            </Sections>

            {/* Referral Section */}
            <Section
              style={{ cursor: "pointer" }}
              onClick={() => window.open("/tasker-referral-page", "_self")}
            >
              <BackgroundImage alt="" src={Background2} />
              {/* <Overlay /> */}
              <ShareContent>
                <h4> Share & Earn Extra!</h4>
                <p>
                  Spread the word to friends and family – earn rewards each time
                  someone joins using your invite!*
                </p>
              </ShareContent>
              {/* <p>*usage required</p> */}
            </Section>

            {/* Social Media Section */}
            <FooterSections>
              <div
                className="col-md-10 col-sm-9 col-xs-12"
                style={{ padding: "0px 10px 8px 10px" }}
              >
                <LogoName>
                  <img
                    src={Logo}
                    alt=""
                    onClick={() =>
                      window.open("https://www.thedatataskers.com/", "_blank")
                    }
                  />
                </LogoName>
                {/* <p className="footer_details_text">
                                   dummy text
                                </p> */}
                <FooterDetailsText>
                  <a href="mailto:info@thedatataskers.com">
                    <b>Email:</b> info@thedatataskers.com
                  </a>
                </FooterDetailsText>
                <button
                  onClick={() => {
                    window.open("/legal", "_self");
                  }}
                >
                  Privacy Policy
                </button>
                {/* <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  marginTop: '10px'
                }}>
                  <button
                    onClick={() => {
                      window.open("/support", "_self");
                    }}
                  >
                    Help Center
                  </button>
                  <button
                    onClick={() => {
                      window.open("/legal", "_self");
                    }}
                  >
                    Privacy Policy
                  </button>
                </div> */}
              </div>
              <PagePostFooter className="row">
                <CopyrightText className="text-sm copyright-text">
                  Copyright ©{new Date().getFullYear()} the Data Taskers
                  (Integrated Digital Systems). All Rights Reserved.
                </CopyrightText>
                <div className="follow-icons-container">
                  <button
                    onClick={this.openFBUrl}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={Facebook} alt="Facebook" />
                  </button>
                  <button
                    onClick={this.openInstaUrl}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={Instagram} alt="Instagram" />
                  </button>
                  {/* <button disabled>
                    <img src={Youtube} alt="YouTube" />
                  </button>
                  <button disabled>
                    <img src={Pinterest} alt="Pinterest" />
                  </button>
                  <button disabled>
                    <img src={Tiktok} alt="TikTok" />
                  </button>
                  <button disabled>
                    <img src={Linkedin} alt="LinkedIn" />
                  </button>
                  <button disabled>
                    <img src={Twitter} alt="Twitter" />
                  </button> */}
                </div>
              </PagePostFooter>
            </FooterSections>
          </Container>
        ) : null}
        {/* Custom Install Popup */}
        {isMobile && !isAppInstalled && showInstallPopup && (
          <div className="install-popup">
            <p>You need to add this App to you Home Screen.</p>
            <button onClick={this.handleInstallClick}>ADD</button>
          </div>
        )}
        {/* iOS Install Instruction Popup */}
        {/* {isIos && !isAppInstalled && !this.state.installPromptEvent && (
                <div className="ios-install-popup">
                    <p>To add this app to your home screen, tap the Share icon and select "Add to Home Screen".</p>
                </div>
            )} */}

        {showNotificationPrompt && notificationService.isSupported() && (
          <NotificationPrompt
            onRequestPermission={this.handleRequestPermission}
            onClose={this.handleCloseNotification}
          />
        )}
        <FullpageLoader
          isOpen={isLoading}
          message="Loading user dashboard data... please wait"
        />
      </div>
    );
  }
}
export default Dashboard;
