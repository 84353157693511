import React, { useState, useEffect } from "react";
import "./UserRatingsDashboardStyles.css";
import NotificationsContent from "./NotificationContent";
import TasksContent from "./TaskContent";
import TaskDetailsModal from "./TaskDetailsModal";
import api from "../../../api";

const RatingsDashboard = () => {
  const sessionKey = localStorage.getItem("sessionKey");
  const [selectedView, setSelectedView] = useState("notifications");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState(null);
  const [loadingTaskDetails, setLoadingTaskDetails] = useState(false);

  useEffect(() => {
    // Get the view parameter from URL
    const params = new URLSearchParams(window.location.search);
    const viewParam = params.get("view");
    if (viewParam && ["notifications", "audio", "image"].includes(viewParam)) {
      setSelectedView(viewParam);
    }
  }, []);

  if (!sessionKey) {
    window.open("/", "_self");
    return null;
  }

  const handleTaskClick = async (taskId, taskType) => {
    setIsModalOpen(true);
    try {
      setLoadingTaskDetails(true);
      const response = await api.get(
        `/tasker-dashboard/task-details/${taskId}`,
        {
          params: { type: taskType },
        }
      );

      const data = response.data;
      setSelectedTaskDetails({
        taskResultId: data.taskResultId,
        description: data.description,
        taskUrl: data.taskUrl,
        timestamp: data.timestamp | data.Timestamp,
        type: taskType,
        latitude: data.latitude,
        longitude: data.longitude,
      });
    } catch (error) {
      console.error("Error fetching task details:", error);
    } finally {
      setLoadingTaskDetails(false);
    }
  };

  return (
    <div className="ratings-dashboard">
      <div className="dashboard-content">
        <h1 className="dashboard-title">Dashboard</h1>

        <div className="view-selector">
          <div className="view-selector-wrapper">
            <select
              value={selectedView}
              onChange={(e) => {
                setSelectedView(e.target.value);
                // Update URL when selection changes
                const newUrl = new URL(window.location);
                newUrl.searchParams.set("view", e.target.value);
                window.history.pushState({}, "", newUrl);
              }}
              className="view-select"
            >
              <option value="notifications">Notifications</option>
              <option value="audio">Audio Tasks</option>
              <option value="image">Image Tasks</option>
            </select>
          </div>
        </div>

        {selectedView === "notifications" ? (
          <NotificationsContent />
        ) : (
          <TasksContent
            taskType={selectedView}
            onTaskClick={(taskId) => handleTaskClick(taskId, selectedView)}
          />
        )}
      </div>

      <TaskDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        taskDetails={selectedTaskDetails}
        loading={loadingTaskDetails}
      />
    </div>
  );
};

export default RatingsDashboard;
