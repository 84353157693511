import React, { useState } from "react";
import PendingImageTask from "./PendingImageTask";
import RejectedImageTask from "./RejectedImageTask";
import TabCss from "./Tabs.css"

const TABS = [
    {
        name: "Pending Task",
        component: PendingImageTask,
    },
    {
        name: "Rejected Task",
        component: RejectedImageTask,
    },
];

function AudioTask(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const Component = TABS[activeIndex].component;
    return (
        <div>
            <div className="mg-tabs__header">
                {TABS.map((tab, index) => (
                    <span
                        key={index}
                        className={index === activeIndex ? "mg-tabs__header-active" : ""}
                        onClick={() => setActiveIndex(index)}>
                        {tab.name}
                    </span>
                ))}
            </div>
            <div className="mg-tabs__container">
                <Component {...props} />
            </div>
        </div>
    );
}
export default AudioTask;