import React, { useState, useEffect } from "react";
import { BiBell } from "react-icons/bi";
import { notificationService } from "../../services/notificationService";

const notificationPromptStyles = {
  container: {
    position: "fixed",
    bottom: "80px", // Increased from 20px to avoid bottom overlaps
    left: "50%", // Center horizontally
    transform: "translateX(-50%)", // Center horizontally
    width: "85%", // Use percentage instead of fixed width
    maxWidth: "300px", // Maximum width on larger screens
    padding: "16px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: "12px",
    zIndex: 1000,
    border: "1px solid #e0e0e0",
    margin: "0 auto", // Center the container
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    gap: "8px", // Add space between icon and text
  },
  icon: {
    marginRight: "4px",
    color: "#4a90e2",
  },
  title: {
    margin: 0,
    fontSize: "18px",
    fontWeight: "600",
    color: "#333",
  },
  description: {
    margin: "8px 0 12px 0",
    fontSize: "14px",
    color: "#666",
    lineHeight: "1.4",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "12px",
  },
  button: {
    padding: "10px 16px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
    flex: "1", // Make buttons equal width
    maxWidth: "120px", // Limit button width
  },
  laterButton: {
    backgroundColor: "#f5f5f5",
    color: "#666",
  },
  enableButton: {
    backgroundColor: "#4a90e2",
    color: "#fff",
  },
};

export const NotificationPrompt = ({ onRequestPermission, onClose }) => {
  // Check if notifications are supported before rendering
  if (!notificationService.isSupported()) {
    return null;
  }

  return (
    <div style={notificationPromptStyles.container}>
      <div style={notificationPromptStyles.header}>
        <BiBell size={20} style={notificationPromptStyles.icon} />
        <h3 style={notificationPromptStyles.title}>Enable Notifications</h3>
      </div>
      <p style={notificationPromptStyles.description}>
        Stay updated with important task notifications and reminders
      </p>
      <div style={notificationPromptStyles.buttonContainer}>
        <button
          onClick={onClose}
          style={{
            ...notificationPromptStyles.button,
            ...notificationPromptStyles.laterButton,
          }}
        >
          Later
        </button>
        <button
          onClick={onRequestPermission}
          style={{
            ...notificationPromptStyles.button,
            ...notificationPromptStyles.enableButton,
          }}
        >
          Enable
        </button>
      </div>
    </div>
  );
};

export const useNotificationPermission = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const STORAGE_KEY = "fcm_notification_prompt";

  const getLastPromptData = () => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : null;
  };

  const setLastPromptData = () => {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        timestamp: Date.now(),
        pathname: window.location.pathname,
      })
    );
  };

  const shouldShowPrompt = async () => {
    // First check if notifications are supported at all
    if (!notificationService.isSupported()) {
      return false;
    }

    if (Notification.permission === "denied") {
      return false;
    }

    if (await notificationService.checkPermission()) {
      return false;
    }

    const lastPrompt = getLastPromptData();
    if (!lastPrompt) return true;

    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    return Date.now() - lastPrompt.timestamp >= threeDaysInMs;
  };

  const handleRequestPermission = async () => {
    if (!notificationService.isSupported()) {
      setShowPrompt(false);
      return;
    }

    try {
      const isInitialized = await notificationService.initialize();
      if (isInitialized) {
        setShowPrompt(false);
        setLastPromptData();
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      setShowPrompt(false); // Hide prompt on error
    }
  };

  const checkAndShowPrompt = async () => {
    if (!notificationService.isSupported()) {
      return;
    }

    const shouldShow = await shouldShowPrompt();
    if (shouldShow) {
      setShowPrompt(true);
      setLastPromptData();
    }
  };

  const handleClose = () => {
    setShowPrompt(false);
    setLastPromptData();
  };

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Cleanup if component unmounts while prompt is showing
      if (showPrompt) {
        setLastPromptData();
      }
    };
  }, [showPrompt]);

  return {
    showPrompt,
    handleRequestPermission,
    handleClose,
    checkAndShowPrompt,
  };
};
