import types from "./types"; // Adjust the path as needed
import { getURL } from "../../commons/commons";
import api from '../../api';

export function getImageTaskData(params) {
    const url = getURL("/api/image-task", params);
    return async function (dispatch) {
        if (!params.nextCursor) {
            dispatch({ type: types.FETCH_IMAGE_TASK_DATA, payload: [], nextCursor: null });
        }
        const res = await api.get(url);
        dispatch({
            type: types.FETCH_IMAGE_TASK_DATA,
            payload: res.data.data,
            nextCursor: params.nextCursor,
        });
        return res;
    };
}

export function updateImageTaskData(data) {
    return async function (dispatch) {
        const res = await api.post("/api/image-task-result", data);
        dispatch({ type: types.UPDATE_IMAGE_TASK_DATA, payload: data });
        return res;
    };
}

export function getRejectedImageTaskData(params) {
    const url = getURL("/api/image-task-rejected", params);
    return async function (dispatch) {
        if (!params.nextCursor) {
            dispatch({ type: types.FETCH_REJECTED_IMAGE_TASK_DATA, payload: [], nextCursor: null });
        }
        const res = await api.get(url);
        dispatch({
            type: types.FETCH_REJECTED_IMAGE_TASK_DATA,
            payload: res.data.data,
            nextCursor: params.nextCursor,
        });
        return res;
    };
}

export function updateRejectedImageTaskData(data) {
    return async function (dispatch) {
        const res = await api.post("/api/image-task-result", data);
        dispatch({ type: types.UPDATE_REJECTED_IMAGE_TASK_DATA, payload: data });
        return res;
    };
}