import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import api from "../../../../api";

const FormContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  padding: 20px;
`;

const FormModal = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  cursor: move;
  top: ${(props) => props.$position.y}px;
  left: ${(props) => props.$position.x}px;
`;

const FormTitle = styled.h2`
  margin: 0;
  color: #2c3e50;
  font-size: 1.5rem;
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #eee;
  background: white;
  border-radius: 8px 8px 0 0;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: move;
`;

const FormContent = styled.div`
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  background: white;
  border-top: 1px solid #eee;
  border-radius: 0 0 8px 8px;
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

const FieldGroup = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const FieldLabel = styled.label`
  color: #2c3e50;
  font-weight: 500;
  font-size: 0.95rem;
  ${(props) =>
    props.required &&
    `
    &:after {
      content: ' *';
      color: #e74c3c;
    }
  `}
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }

  &::placeholder {
    color: #95a5a6;
  }
`;

const SearchResults = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 8px;
  display: ${(props) => (props.$hasResults ? "block" : "none")};
`;

const SearchResultItem = styled.div`
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.$isSelected ? "#e3f2fd" : "transparent"};
  &:hover {
    background-color: #f5f5f5;
  }
`;

const SelectedValue = styled.div`
  margin-top: 8px;
  padding: 8px 12px;
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #2c3e50;
`;

const ModeToggle = styled.button`
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: ${(props) => (props.$active ? "#e3f2fd" : "white")};
  color: ${(props) => (props.$active ? "#1976d2" : "#666")};
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 8px;
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) => (props.$active ? "#bbdefb" : "#f5f5f5")};
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.$primary ? "#1976d2" : "#f5f5f5")};
  color: ${(props) => (props.$primary ? "white" : "#666")};
  cursor: pointer;
  font-size: 1rem;
  margin: 0 8px;
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) => (props.$primary ? "#1565c0" : "#e0e0e0")};
  }
`;

const MultiAttributeForm = ({
  fields = [],
  category,
  onClose,
  onSubmit,
  projectId,
  initialValues,
  onFormDataUpdate = () => {},
  allowClear = false,
}) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [searchResults, setSearchResults] = useState({});
  const [isSearching, setIsSearching] = useState({});
  const [activeMode, setActiveMode] = useState({});
  const searchTimeouts = useRef({});
  const [searchInputValues, setSearchInputValues] = useState({});

  const [searchAttempted, setSearchAttempted] = useState({});
  const [isLoadingSearch, setIsLoadingSearch] = useState({});

  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - 250,
    y: window.innerHeight / 2 - 200,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const formRef = useRef(null);

  const handleMouseDown = (e) => {
    if (e.target.closest('[data-no-drag="true"]')) return;
    setIsDragging(true);
    const rect = formRef.current.getBoundingClientRect();
    setDragOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const newX = e.clientX - dragOffset.x;
    const newY = e.clientY - dragOffset.y;

    // Ensure the form stays within viewport bounds
    const maxX = window.innerWidth - formRef.current.offsetWidth;
    const maxY = window.innerHeight - formRef.current.offsetHeight;

    setPosition({
      x: Math.max(0, Math.min(newX, maxX)),
      y: Math.max(0, Math.min(newY, maxY)),
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDragging]);

  useEffect(() => {
    const initialData = {};
    const initialMode = {};

    fields.forEach((field) => {
      // If we have initial values, use them
      if (initialValues && initialValues[field.FieldID]) {
        initialData[field.FieldID] = initialValues[field.FieldID];

        // Also set the search input value if it's a search type
        if (initialValues[field.FieldID].type === "search") {
          setSearchInputValues((prev) => ({
            ...prev,
            [field.FieldID]: initialValues[field.FieldID].value,
          }));
        }
      } else {
        // Otherwise set default empty values
        initialData[field.FieldID] = {
          value: "",
          type: field.predefinedValues ? "predefined" : "text",
          labelId: null,
        };
      }

      // Set initial mode for hybrid fields
      if (field.fieldType === "hybrid") {
        // If we have initial values, set mode based on the type
        if (initialValues && initialValues[field.FieldID]) {
          initialMode[field.FieldID] =
            initialValues[field.FieldID].type === "search" ? "search" : "text";
        } else {
          initialMode[field.FieldID] = "search";
        }
      }
    });

    setFormData(initialData);
    setActiveMode(initialMode);
  }, [fields, initialValues]);

  const searchLabels = async (query, fieldId) => {
    if (!query.trim() || query.length < 2) return [];

    try {
      const response = await api.get(
        "/tasker-dashboard/search-annotation-labels",
        {
          params: {
            searchTerm: query,
            projectId,
            fieldId,
          },
        }
      );

      return response.data.map((label) => ({
        id: label.AnnotationLabelID,
        value: label.AnnotationLabelName,
        description: label.Description,
      }));
    } catch (error) {
      console.error("Error searching labels:", error);
      return [];
    }
  };

  const handleSearch = async (value, fieldId) => {
    // Clear previous timeout
    if (searchTimeouts.current[fieldId]) {
      clearTimeout(searchTimeouts.current[fieldId]);
    }

    if (!value.trim() || value.length < 2) {
      setSearchResults((prev) => ({ ...prev, [fieldId]: [] }));
      return;
    }

    // Set new timeout for debouncing
    searchTimeouts.current[fieldId] = setTimeout(async () => {
      setIsLoadingSearch((prev) => ({ ...prev, [fieldId]: true }));
      try {
        const results = await searchLabels(value, fieldId);
        setSearchResults((prev) => ({
          ...prev,
          [fieldId]: results,
        }));
      } catch (error) {
        console.error("Error searching labels:", error);
        setSearchResults((prev) => ({ ...prev, [fieldId]: [] }));
      } finally {
        setIsLoadingSearch((prev) => ({ ...prev, [fieldId]: false }));
      }
    }, 300); // 300ms debounce delay
  };

  useEffect(() => {
    return () => {
      Object.values(searchTimeouts.current).forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, []);

  const handleFieldChange = (fieldId, value, type = "text") => {
    setFormData((prev) => ({
      ...prev,
      [fieldId]: {
        ...prev[fieldId],
        value,
        type,
        labelId: type === "text" ? null : prev[fieldId]?.labelId,
      },
    }));
    setErrors((prev) => ({ ...prev, [fieldId]: null }));
  };

  const toggleMode = (fieldId) => {
    const currentMode = activeMode[fieldId];
    const newMode = currentMode === "text" ? "search" : "text";

    setActiveMode((prev) => ({
      ...prev,
      [fieldId]: newMode,
    }));

    // If switching from text to search, clear the text value
    if (currentMode === "text") {
      // Clear the form data value
      setFormData((prev) => ({
        ...prev,
        [fieldId]: {
          ...prev[fieldId],
          value: "",
          labelId: null,
          type: "search",
        },
      }));

      // Clear the search input value
      setSearchInputValues((prev) => ({
        ...prev,
        [fieldId]: "",
      }));

      // Clear search results
      setSearchResults((prev) => ({
        ...prev,
        [fieldId]: [],
      }));
    } else {
      // Switching from search to text
      // Clear search results
      setSearchResults((prev) => ({
        ...prev,
        [fieldId]: [],
      }));

      // Update the form data type
      setFormData((prev) => ({
        ...prev,
        [fieldId]: {
          ...prev[fieldId],
          type: "text",
        },
      }));
    }
  };

  const renderHybridField = (field) => {
    // For predefined category, only show search
    if (category === "predefined") {
      return renderSearchOnlyField(field);
    }
    // For discover category, only show text input
    if (category === "discover") {
      return renderTextOnlyField(field);
    }

    const isTextMode = activeMode[field.FieldID] === "text";
    const currentResults = searchResults[field.FieldID] || [];
    const isSearching = isLoadingSearch[field.FieldID];

    // Add a condition to not show "No matching labels found" if a value has been selected
    const hasSelectedValue = !!formData[field.FieldID]?.value;
    const hasNoResults =
      !isSearching &&
      currentResults.length === 0 &&
      searchAttempted[field.FieldID] &&
      !hasSelectedValue; // Don't show the message if a value has been selected

    return isTextMode ? (
      <InputWrapper>
        <StyledInput
          type="text"
          value={formData[field.FieldID]?.value || ""}
          onChange={(e) =>
            handleFieldChange(field.FieldID, e.target.value, "text")
          }
          placeholder={`Type ${field.FieldName}...`}
        />
        <div style={{ padding: "8px", fontSize: "0.9rem" }}>
          <button
            type="button"
            onClick={() => toggleMode(field.FieldID)}
            style={{
              background: "none",
              border: "none",
              color: "#3498db",
              textDecoration: "underline",
              cursor: "pointer",
              padding: "0 4px",
              fontSize: "0.9rem",
            }}
          >
            switch to search mode
          </button>
        </div>
      </InputWrapper>
    ) : (
      <div>
        <InputWrapper>
          <StyledInput
            type="text"
            placeholder={`Search ${field.FieldName}... (min. 2 characters)`}
            value={searchInputValues[field.FieldID] || ""}
            onChange={(e) => {
              const value = e.target.value;
              setSearchInputValues((prev) => ({
                ...prev,
                [field.FieldID]: value,
              }));

              if (value.length >= 2) {
                setSearchAttempted((prev) => ({
                  ...prev,
                  [field.FieldID]: true,
                }));
                handleSearch(value, field.FieldID);
              } else {
                // Clear results if search term is too short
                setSearchResults((prev) => ({
                  ...prev,
                  [field.FieldID]: [],
                }));
              }
            }}
          />
        </InputWrapper>

        {isSearching && (
          <div style={{ padding: "8px", color: "#7f8c8d" }}>Searching...</div>
        )}

        {hasNoResults && (
          <div
            style={{
              padding: "8px",
              color: "#e74c3c",
              fontSize: "0.9rem",
            }}
          >
            No matching labels found. Try a different search term or
            <button
              type="button"
              onClick={() => toggleMode(field.FieldID)}
              style={{
                background: "none",
                border: "none",
                color: "#3498db",
                textDecoration: "underline",
                cursor: "pointer",
                padding: "0 4px",
                fontSize: "0.9rem",
              }}
            >
              switch to manual input
            </button>
          </div>
        )}

        {!isSearching && currentResults.length > 0 && (
          <SearchResults $hasResults={currentResults.length > 0}>
            {currentResults.map((result) => (
              <SearchResultItem
                key={result.id}
                $isSelected={
                  formData[field.FieldID]?.value === result.value &&
                  formData[field.FieldID]?.labelId === result.id
                }
                onClick={() => {
                  // Create a new object directly instead of using the function form
                  const updatedFormData = {
                    ...formData,
                    [field.FieldID]: {
                      value: result.value,
                      type: "search",
                      labelId: result.id,
                      description: result.description,
                    },
                  };

                  setFormData(updatedFormData);

                  setSearchResults((prev) => ({
                    ...prev,
                    [field.FieldID]: [],
                  }));

                  setSearchInputValues((prev) => ({
                    ...prev,
                    [field.FieldID]: result.value,
                  }));

                  // Reset search attempted flag when a value is selected
                  setSearchAttempted((prev) => ({
                    ...prev,
                    [field.FieldID]: false,
                  }));
                }}
              >
                <div>{result.value}</div>
                {result.description && (
                  <div style={{ fontSize: "0.8em", color: "#666" }}>
                    {result.description}
                  </div>
                )}
              </SearchResultItem>
            ))}
          </SearchResults>
        )}

        {formData[field.FieldID]?.value &&
          formData[field.FieldID]?.type === "search" && (
            <SelectedValue>
              Selected: {formData[field.FieldID].value}
              {formData[field.FieldID].description && (
                <div
                  style={{ fontSize: "0.8em", color: "#666", marginTop: "4px" }}
                >
                  {formData[field.FieldID].description}
                </div>
              )}
            </SelectedValue>
          )}
      </div>
    );
  };

  const handleClose = () => {
    setFormData({});
    setErrors({});
    setSearchResults({});
    setSearchInputValues({});
    onClose();
  };

  const renderSearchOnlyField = (field) => {
    const currentResults = searchResults[field.FieldID] || [];
    const hasSelectedValue = !!formData[field.FieldID]?.value;
    const isSearching = isLoadingSearch[field.FieldID];
    const hasNoResults =
      !isSearching &&
      currentResults.length === 0 &&
      searchAttempted[field.FieldID] &&
      !hasSelectedValue;

    return (
      <div>
        <InputWrapper>
          <StyledInput
            type="text"
            value={searchInputValues[field.FieldID] || ""}
            placeholder={`Search ${field.FieldName}... (min. 2 characters)`}
            onChange={(e) => {
              const value = e.target.value;
              setSearchInputValues((prev) => ({
                ...prev,
                [field.FieldID]: value,
              }));

              if (value.length >= 2) {
                setSearchAttempted((prev) => ({
                  ...prev,
                  [field.FieldID]: true,
                }));
                handleSearch(value, field.FieldID);
              } else {
                // Clear results if search term is too short
                setSearchResults((prev) => ({
                  ...prev,
                  [field.FieldID]: [],
                }));
              }
            }}
          />
        </InputWrapper>

        {isSearching && (
          <div style={{ padding: "8px", color: "#7f8c8d" }}>Searching...</div>
        )}

        {hasNoResults && (
          <div
            style={{
              padding: "8px",
              color: "#e74c3c",
              fontSize: "0.9rem",
            }}
          >
            No matching labels found. Try a different search term.
          </div>
        )}

        {!isSearching && currentResults.length > 0 && (
          <SearchResults $hasResults={currentResults.length > 0}>
            {currentResults.map((result) => (
              <SearchResultItem
                key={result.id}
                $isSelected={
                  formData[field.FieldID]?.value === result.value &&
                  formData[field.FieldID]?.labelId === result.id
                }
                onClick={() => {
                  // Create a new object directly instead of using the function form
                  const updatedFormData = {
                    ...formData,
                    [field.FieldID]: {
                      value: result.value,
                      type: "search",
                      labelId: result.id,
                      description: result.description,
                    },
                  };

                  setFormData(updatedFormData);

                  setSearchResults((prev) => ({
                    ...prev,
                    [field.FieldID]: [],
                  }));

                  setSearchInputValues((prev) => ({
                    ...prev,
                    [field.FieldID]: result.value,
                  }));

                  // Reset search attempted flag when a value is selected
                  setSearchAttempted((prev) => ({
                    ...prev,
                    [field.FieldID]: false,
                  }));
                }}
              >
                <div>{result.value}</div>
                {result.description && (
                  <div style={{ fontSize: "0.8em", color: "#666" }}>
                    {result.description}
                  </div>
                )}
              </SearchResultItem>
            ))}
          </SearchResults>
        )}

        {formData[field.FieldID]?.value &&
          formData[field.FieldID]?.type === "search" && (
            <SelectedValue>
              Selected: {formData[field.FieldID].value}
              {formData[field.FieldID].description && (
                <div
                  style={{ fontSize: "0.8em", color: "#666", marginTop: "4px" }}
                >
                  {formData[field.FieldID].description}
                </div>
              )}
            </SelectedValue>
          )}
      </div>
    );
  };

  const renderTextOnlyField = (field) => {
    return (
      <InputWrapper>
        <StyledInput
          type="text"
          value={formData[field.FieldID]?.value || ""}
          onChange={(e) =>
            handleFieldChange(field.FieldID, e.target.value, "text")
          }
          placeholder={`Type ${field.FieldName}...`}
        />
      </InputWrapper>
    );
  };

  const renderField = (field) => {
    // Handle predefined values (radio buttons or select dropdown)
    if (field.predefinedValues) {
      const values = field.predefinedValues;
      if (values.length <= 3) {
        return (
          <div style={{ display: "flex", gap: "1rem" }}>
            {values.map((value) => (
              <label
                key={value}
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <input
                  type="radio"
                  name={field.FieldID}
                  value={value}
                  checked={formData[field.FieldID]?.value === value}
                  onChange={(e) =>
                    handleFieldChange(
                      field.FieldID,
                      e.target.value,
                      "predefined"
                    )
                  }
                />
                {value}
              </label>
            ))}
          </div>
        );
      } else {
        return (
          <select
            value={formData[field.FieldID]?.value || ""}
            onChange={(e) =>
              handleFieldChange(field.FieldID, e.target.value, "predefined")
            }
            style={{ width: "100%", padding: "0.5rem" }}
          >
            <option value="">Select {field.FieldName}</option>
            {values.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        );
      }
    }
    // Handle label_search fields - this is the new addition
    else if (field.fieldType === "label_search") {
      // For label_search fields, always use the search interface
      return renderSearchOnlyField(field);
    }
    // Handle hybrid fields
    else if (field.fieldType === "hybrid") {
      return renderHybridField(field);
    }
    // Default to text input for all other fields
    else {
      return (
        <InputWrapper>
          <StyledInput
            type="text"
            value={formData[field.FieldID]?.value || ""}
            onChange={(e) =>
              handleFieldChange(field.FieldID, e.target.value, "text")
            }
            placeholder={`Enter ${field.FieldName}`}
            style={{ width: "100%", padding: "0.5rem" }}
          />
        </InputWrapper>
      );
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    fields.forEach((field) => {
      const fieldData = formData[field.FieldID];

      if (
        field.IsRequired &&
        (!fieldData?.value || fieldData.value.trim() === "")
      ) {
        newErrors[field.FieldID] = "This field is required";
        isValid = false;
      }

      // Additional validation for hybrid fields
      if (field.fieldType === "hybrid" && field.IsRequired) {
        if (activeMode[field.FieldID] === "search" && !fieldData?.labelId) {
          newErrors[field.FieldID] =
            "Please select a valid option from the search";
          isValid = false;
        }
      }

      // Additional validation for label_search fields
      if (field.fieldType === "label_search" && field.IsRequired) {
        if (!fieldData?.labelId) {
          newErrors[field.FieldID] =
            "Please select a valid option from the search";
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (!validateForm()) {
      console.log("Form validation failed:", errors);
      return;
    }

    const transformedData = Object.entries(formData).reduce(
      (acc, [fieldId, data]) => {
        acc[fieldId] = {
          value: data.value || "",
          labelId: data.labelId || null,
          type: data.type || "text",
        };
        return acc;
      },
      {}
    );

    // Submit transformed data
    onSubmit(transformedData);
  };

  const handleClearForm = () => {
    const emptyData = {};
    fields.forEach((field) => {
      emptyData[field.FieldID] = {
        value: "",
        type: field.predefinedValues ? "predefined" : "text",
        labelId: null,
      };
    });
    setFormData(emptyData);
    setErrors({});
    setSearchResults({});
    setSearchInputValues({});
    // Reset search attempted flags
    const resetSearchAttempted = {};
    fields.forEach((field) => {
      resetSearchAttempted[field.FieldID] = false;
    });
    setSearchAttempted(resetSearchAttempted);
  };

  return (
    <FormContainer>
      <FormModal
        ref={formRef}
        $position={position}
        onMouseDown={handleMouseDown}
      >
        <FormTitle>
          {initialValues ? "Edit Annotation" : "New Annotation"}
        </FormTitle>
        <FormContent>
          {fields.map((field) => (
            <FieldGroup key={field.FieldID}>
              <FieldHeader>
                <FieldLabel required={field.IsRequired}>
                  {field.FieldName}
                </FieldLabel>
                {field.fieldType === "hybrid" && (
                  <ModeToggle
                    type="button"
                    onClick={() => toggleMode(field.FieldID)}
                    $active={activeMode[field.FieldID] !== "text"}
                  >
                    {activeMode[field.FieldID] === "text"
                      ? "Search Labels"
                      : "Input Text"}
                  </ModeToggle>
                )}
              </FieldHeader>
              {renderField(field)}
              {errors[field.FieldID] && (
                <div
                  style={{
                    color: "#f44336",
                    fontSize: "0.9rem",
                    marginTop: "4px",
                  }}
                >
                  {errors[field.FieldID]}
                </div>
              )}
            </FieldGroup>
          ))}
        </FormContent>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          {allowClear && <Button onClick={handleClearForm}>Clear Form</Button>}
          <Button $primary onClick={handleSubmit}>
            Submit
          </Button>
        </ButtonGroup>
      </FormModal>
    </FormContainer>
  );
};

export default MultiAttributeForm;
