import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RegistrationPage from "./Registration_Page";
//import PrivateRoute from "./PrivateRoute";
import AudioTask from "./AudioTask/index";
import Dashboard from "./AudioTask/Dashboard";
import TaskerLogin from "./AudioTask/TaskerLogin";
import RatingsDashboard from "./components/UserDashboard/UserRatingDashboardComponents/UserRatingsDashboard";
import TaskerEarningsDashboard from "./components/UserDashboard/TaskerEarningsPage";
import TransactionHistory from "./components/UserDashboard/TransactionHistory";
import PaymentForm from "./components/UserDashboard/TakserPaymentForm";
import ReferralShare from "./components/TaskDashboardComponents/ReferralShareComponent";
import ReferralLanding from "./components/TaskDashboardComponents/ReferralLanding";
import UpdateLanguageUI from "./AudioTask/UpdateLanguageUI";
import ImageTask from "./components/ImageTask/index";
import Legal from "./Legal";
import DataTaskerSupport from "./components/DataTaskerSupport";
import CreateNewIssue from "./components/DataTaskerSupport/CreateNewIssue";
import TicketSupportDetails from "./components/DataTaskerSupport/TicketSupportDetails";
import AnnotationTask from "./components/AnnotationTask/index";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/support" element={<DataTaskerSupport />} />
        <Route path="/support-new" element={<CreateNewIssue />} />
        <Route path="/support/:id" element={<TicketSupportDetails />} />
        <Route path="/" element={<TaskerLogin />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/tasker-dashboard" element={<Dashboard />} />
        <Route path="/audio-task" element={<AudioTask />} />
        <Route path="/image-task" element={<ImageTask />} />
        <Route path="/annotation-task" element={<AnnotationTask />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/tasker-rating-dashboard" element={<RatingsDashboard />} />
        <Route
          path="/tasker-earning-dashboard"
          element={<TaskerEarningsDashboard />}
        />
        <Route path="/transaction-history" element={<TransactionHistory />} />
        <Route path="/tasker-payment-form" element={<PaymentForm />} />
        <Route path="/tasker-referral-page" element={<ReferralShare />} />
        <Route path="/details" element={<ReferralLanding />} />
        <Route
          path="/update-language-preference"
          element={<UpdateLanguageUI />}
        />
      </Routes>
    </Router>
  );
}
export default App;
