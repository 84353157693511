import axios from "axios";

const api = axios.create({
  // baseURL: "https://second-caster-430015-j9.el.r.appspot.com",
  baseURL: "https://backend-prod-tdt.el.r.appspot.com",
  //baseURL: "http://localhost:3307",
  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
  },
});
// Add an interceptor to set headers dynamically before each request
api.interceptors.request.use(
  (config) => {
    const latitude = localStorage.getItem("Employee_Latitude");
    const longitude = localStorage.getItem("Employee_Longitude");
    if (latitude) config.headers["X-user-latitude"] = latitude;
    if (longitude) config.headers["X-user-longitude"] = longitude;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default api;
