import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
    getRejectedImageTaskData,
    updateRejectedImageTaskData,
} from "../../../actions/ImageTask/index";
import LoadingUI from "../../../elements/LoadingUI";
import NoDataFound from "../../../elements/NoDataFound";
import ImageTaskUI from "./ImageTaskUI";
import { showNotification } from "../../../actions/index.actions";

const AudioWorkFlow = (props) => {
    const sessionKey = localStorage.getItem("sessionKey");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formData, setFormData] = useState({ sessionKey: sessionKey });
    const [nextCursor, setNextCursor] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    // console.log("Audio-task", latitude, longitude);
    const [locationGranted, setLocationGranted] = useState(false);
    const [locationBlocked, setLocationBlocked] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [isLocationLoading, setLocationLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(
        "There are no rejected tasks available or you have completed all task."
    );
    // useRef to track if the data has been fetched already
    const hasFetchedData = useRef(false);
    useEffect(() => {
        if (!sessionKey) {
            window.open("/", "_self");
        }
        if (sessionKey && !hasFetchedData.current) {
            // Mark that the data has been fetched
            hasFetchedData.current = true;
            // Call the API to fetch survey question data
            getRejectedImageTaskData({ sessionKey: sessionKey });
        }
        // Handle location permission and fetching logic
        const userLocationPermission = localStorage.getItem("locationGranted");
        if (userLocationPermission === "true") {
            setLocationGranted(true);
            setShowLocationModal(false);
            requestLocation();
        } else {
            setLocationGranted(false);
            setShowLocationModal(true);
        }
        setLocationLoading(false);
    }, [sessionKey]); // Dependencies: will only re-run if userNumber changes

    // Request location access
    const requestLocation = () => {
        setLocationLoading(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    setLocationGranted(true);
                    setLocationBlocked(false);
                    setShowLocationModal(false);
                    localStorage.setItem("locationGranted", "true");
                    localStorage.setItem("locationBlocked", "false");
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        // User has denied the location request
                        setLocationGranted(false);
                        setLocationBlocked(true);
                        setShowLocationModal(true); // Show modal with instructions
                        localStorage.setItem("locationBlocked", "true");
                        localStorage.setItem("locationGranted", "false");
                        showNotification(
                            "Error",
                            "Location access denied. Please enable it in your browser settings.",
                            "danger"
                        );
                    } else {
                        // Handle other errors (e.g., timeout or unavailable geolocation)
                        showNotification("Error", "Unable to access location.", "danger");
                    }
                    setLocationLoading(false); // End loading
                }
            );
        } else {
            showNotification(
                "Error",
                "Geolocation is not supported by this browser.",
                "danger"
            );
        }
    };
    // Render location modal if needed
    const renderLocationModal = () => {
        if (isLocationLoading) {
            return null; // Don't render anything during the loading phase
        }
        return (
            <div style={styles.modal}>
                <div style={styles.modalContent}>
                    {locationBlocked ? null : (
                        <React.Fragment>
                            <h3>We need your location!</h3>
                            <p>
                                To provide better services, we require access to your location.
                                Please click "Allow Location" to grant us permission.
                            </p>
                            <p style={{ color: "#1d5dc9" }}>
                                Note: Please do not click on "Never" when location popup/dialog
                                appears
                            </p>
                            <button
                                onClick={requestLocation}
                                style={styles.modalButton}
                                disabled={isLocationLoading}
                            >
                                {isLocationLoading ? "Please Wait..." : "Allow Location"}
                            </button>
                        </React.Fragment>
                    )}
                    {locationBlocked && (
                        <div
                            style={{
                                textAlign: "left",
                                color: "#ba1d1d",
                                background: "#fff8f8",
                                padding: "2px 2px",
                                borderRadius: "5px",
                            }}
                        >
                            <p style={styles.blockedText}>
                                Location access is required to continue. Please enable it in
                                your browser settings:
                                <br />
                                1. Go to your browser settings.
                                <br />
                                2. Find "Site Settings" or "Permissions".
                                <br />
                                3. Find "Location" within "Permissions" and check the "Blocked"
                                section.
                                <br />
                                4. You will find this site in your blocked section
                                "https://taskers.thedatataskers.com".
                                <br />
                                5. Click and remove this, and enable location access for this
                                site.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getRejectedImageTaskData = (reqObj) => {
        setIsLoading(true);
        setIsError(false);
        if (!reqObj.nextCursor) {
            localStorage.removeItem("imageRIndex"); 
        }
        props
            .getRejectedImageTaskData(reqObj)
            .then((res) => {
                setNextCursor(res.data.nextCursor)
                setIsLoading(false);
                setIsError(false);
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false);
                setIsError(true);
                const errorMsg =
                    error.response?.data?.message ||
                    "There are no rejected tasks available or you have completed all task.";
                showNotification("Error", errorMsg, "danger");
                setErrorMessage(errorMessage);
                if (error && error.response && error.response.data.code == 401) {
                    setTimeout(() => {
                        window.open("/update-language-preference", "_self"); // Redirect user to the dashboard page after 1 seconds
                    }, 1000);
                }
            });
    };

    const getUI = () => {
        const { rejectedImageTaskDataList } = props;
        const filteredQuestions =
            rejectedImageTaskDataList &&
            rejectedImageTaskDataList.length &&
            rejectedImageTaskDataList.filter((question) => question.imageUrl == null);
        if (isLoading) {
            return (
                <div
                    style={{
                        textAlign: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <LoadingUI message={"Loading image tasks for you...please wait"} />
                </div>
            );
        }

        if (isError) {
            return (
                <div
                    style={{
                        textAlign: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <NoDataFound message={errorMessage} />
                </div>
            );
        }

        if (filteredQuestions.length === 0) {
            return (
                <div
                    style={{
                        textAlign: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <NoDataFound message={"There are no rejected tasks available or you have completed all task."} />
                </div>
            );
        }

        return (
            <ImageTaskUI
                imageTaskDataList={filteredQuestions}
                key="rejectedImageTaskDataList"
                latitude={latitude}
                longitude={longitude}
                updateImageTaskData={props.updateRejectedImageTaskData}
                requestLocation={requestLocation}
                sessionKey={sessionKey}
                getRejectedImageTaskData={getRejectedImageTaskData}
                nextCursor={nextCursor}
            />
        );
    };

    return (
        <div style={styles.container}>
            {showLocationModal && renderLocationModal()}
            {showLocationModal ? null : getUI()}
        </div>
    );
};

const styles = {
    container: {
        // display: "flex",
    },
    modal: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000000e0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        width: "80%",
        maxWidth: "400px",
    },
    modalButton: {
        backgroundColor: "#4CAF50",
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
    },
};

function mapStateToProps({ rejectedImageTaskDataList }) {
    return { rejectedImageTaskDataList };
}

export default connect(mapStateToProps, {
    getRejectedImageTaskData,
    updateRejectedImageTaskData,
    showNotification,
})(AudioWorkFlow);
