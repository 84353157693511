import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PendingAnnotationTask from "./PendingAnnotationTask";
//import RejectedImageTask from "./RejectedImageTask";
import TabCss from "./Tabs.css";
import { showNotification } from "../../actions/index.actions";

const TABS = [
  {
    name: "Pending Task",
    component: PendingAnnotationTask,
  },
  // {
  //   // name: "Rejected Task",
  //   //component: RejectedImageTask,
  // },
];

function AnnotationTask(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const employeeKey = searchParams.get("key");
  const Component = TABS[activeIndex].component;
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          localStorage.setItem("Employee_Latitude", latitude);
          localStorage.setItem("Employee_Longitude", longitude);
        },
        (error) => {
          showNotification("Error", "Unable to access location.", "danger");
        }
      );
    }
  }, []);
  return (
    <div>
      {!employeeKey && (
        <div className="mg-tabs__header">
          {TABS.map((tab, index) => (
            <span
              key={index}
              className={index === activeIndex ? "mg-tabs__header-active" : ""}
              onClick={() => setActiveIndex(index)}
            >
              {tab.name}
            </span>
          ))}
        </div>
      )}
      <div className="mg-tabs__container">
        <Component {...props} />
      </div>
    </div>
  );
}
export default AnnotationTask;
