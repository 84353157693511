import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import NoDataFound from "../../elements/NoDataFound";
import moment from "moment";
import LoadingUI from "../../elements/LoadingUI";
import ErrorUI from "../../elements/ErrorUI";
const Container = styled.div`
  padding: 0px 10px;
`;

const Title = styled.h1`
  font-size: 18px;
  text-align: center;
  margin: 0;
  color: #000000db;
  border-bottom: 1px solid #e8e5e5;
  padding: 14px 10px;
  position: fixed;
  background: #1f576f;
  color: white;
  width: 100%;
  right: 0;
  z-index: 10;
  top: 0;
`;

const TicketCard = styled.div`
  margin-bottom: 4px;
  padding: 10px 8px;
  border-bottom: 1px solid #e8e5e5;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
    props.initiatedBy
      ? "#dce7fdab"
      : "#dbfff3"};
  &:hover {
    background-color: #f2f2f2;
  }
`;
const TicketContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TicketTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #000000bd;
  margin: 0;
  padding: 4px 0px;
`;
const TicketTitleDesc = styled.p`
  font-weight: 500;
  font-size: 15px;
  color: #000000bd;
  margin: 0;
  padding: 4px 0px;
`;

const TicketStatus = styled.p`
  color: ${(props) =>
    props.isClosed
      ? "#dc2626"
      : "#026326"}; /* red-500 or green-500 equivalent */
  margin: 0;
  padding: 4px 0px;
  font-size: 14px;
`;
const TicketTypeStatus = styled.p`
    background: #9bc6f685;
    color: #174693;
    font-size: 15px;
    padding: 4px 8px;
    border-radius: 5px;
    margin: 0;
`;

const CreateButton = styled.button`
  margin-top: 24px;
  padding: 10px 10px;
  background-color: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 3px;
  &:hover {
    background-color: #2563eb; /* blue-600 */
  }
  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
`;

export default function DataTaskerSupport() {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isClosedCount, setIsClosedCount] = useState(false);

  useEffect(() => {
    const sessionKey = localStorage.getItem("sessionKey");
    const fetchTickets = async () => {
      try {
        const response = await api.get("/api/support-query/list", {
          params: { sessionKey }, // Passing sessionKey as a query parameter
          headers: { "Cache-Control": "no-cache" },
        });

        // Ensure response contains data before setting it
        if (response.data.supportTicket && response.data.supportTicket.length) {
          const newData = response.data.supportTicket.sort((a, b) => a.IsClosed - b.IsClosed);
          setTickets(newData);
        }
        if (response.data.supportTicket.length) {
          // Count the number of items where IsClosed is 0
          const openSupportCount =
            response.data.supportTicket &&
            response.data.supportTicket.filter((item) => item.IsClosed === 0 && !item.InitiatedBy)
              .length;
          if (openSupportCount >= 2) {
            setIsClosedCount(true);
          } else {
            setIsClosedCount(false);
          }
        }
      } catch (err) {
        setError("Failed to fetch support tickets.");
      } finally {
        setLoading(false);
      }
    };
    fetchTickets();
  }, []);

  const handleTicketClick = (ticketId) => {
    navigate(`/support/${ticketId}`);
  };

  const handleCreateNew = () => {
    navigate("/support-new");
  };

  if (loading) return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "100%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <LoadingUI message={"Loading support data...please wait"} />
    </div>
  );
  if (error) return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "95%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <ErrorUI retry={() => window.location.reload()} />
    </div>
  );
  const formatStartDate = (startDate) => {
    const diffInDays = moment().diff(moment(startDate), "days");

    if (diffInDays <= 2) {
      return moment(startDate).fromNow(); // "1 day ago", "2 days ago", etc.
    } else {
      return moment(startDate).format("MMM D, YYYY"); // Show the exact date
    }
  };

  return (
    <Container>
      <Title>theDataTasker Support Tickets</Title>
      <div style={{ marginTop: '60px' }}>
        {tickets.length > 0 ? (
          tickets.map((ticket) => (
            <TicketCard
              initiatedBy={ticket.InitiatedBy}
              key={ticket.ID}
              onClick={() => handleTicketClick(ticket.SupportID)}
            >
              <TicketContainer>
                <TicketTitleDesc>theDataTasker Support</TicketTitleDesc>
                <TicketTitle>
                  {formatStartDate(ticket.StartDate)}
                  {/* {new Date(ticket.StartDate).toLocaleDateString()} */}
                </TicketTitle>
              </TicketContainer>
              <TicketTitle>
                {ticket.RespondedBy ? "TDT: " : "You: "} {ticket.QueryDescription}
              </TicketTitle>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <TicketStatus isClosed={ticket.IsClosed}>
                  {ticket.IsClosed ? "Closed" : "Ongoing"}
                </TicketStatus>
                {ticket.InitiatedBy ? <TicketTypeStatus>
                  Awareness
                </TicketTypeStatus> : null}
              </div>
            </TicketCard>
          ))
        ) : (
          <NoDataFound message={"No support ticket found."} />
        )}
      </div>
      <div style={{ textAlign: " center" }}>
        <CreateButton disabled={isClosedCount} onClick={handleCreateNew}>
          Create New Ticket
        </CreateButton>
      </div>
    </Container>
  );
}