import React, { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import AnnotationCanvas from "./components/AnnotationCanvas";
import AnnotationToolbar from "./components/AnnotationToolbar";
import api from "../../../api";
import {
  toMLFormat,
  fromMLFormat,
  toRotatableMLFormat,
} from "./coordinate-helpers";

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
  position: relative;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
`;

const Header = styled.div`
  background: #1b4da3;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;

  h1 {
    font-size: 18px;
    margin: 0;
    color: white;
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 12px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 24px;
`;

const Button = styled.button`
  background: ${(props) => {
    if (props.$primary) return "#4CAF50";
    if (props.$save) return "#2196F3";
    return "#f44336";
  }};
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    opacity: 0.9;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 18px;
  color: #1b4da3;
`;

const ProgressContainer = styled.div`
  width: 300px;
  margin-top: 20px;
  background: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  height: 8px;
  background: #4caf50;
  width: ${(props) => props.$progress || "0%"};
  transition: width 0.3s ease;
`;

const ProgressText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #555;
`;

// Define a reasonable chunk size - this can be adjusted based on testing
const CHUNK_SIZE = 25; // Number of annotations per chunk
// Enum to track annotation status
const AnnotationStatus = {
  UNCHANGED: "unchanged", // Already saved, no changes
  NEW: "new", // Newly created, not yet saved
  MODIFIED: "modified", // Existing annotation that was modified
  DELETED: "deleted", // Marked for deletion
};

const AnnotationEditor = ({
  taskData,
  onComplete,
  onClose,
  latitude,
  longitude,
}) => {
  const [searchParams] = useSearchParams();
  const employeeKey = searchParams.get("key");
  const sessionKey = !employeeKey ? localStorage.getItem("sessionKey") : null;

  const [isDrawing, setIsDrawing] = useState(false);
  const [isRotatableDrawing, setIsRotatableDrawing] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [resultId, setResultId] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [originalAnnotations, setOriginalAnnotations] = useState([]);
  const [taskFields, setTaskFields] = useState([]);
  const [controlledLabelInfo, setControlledLabelInfo] = useState(null);
  const canvasRef = useRef();
  const [annotationFormData, setAnnotationFormData] = useState({});

  // New state to track annotation status
  const [annotationStatus, setAnnotationStatus] = useState({});

  // Track deleted annotation IDs
  const [deletedAnnotationIds, setDeletedAnnotationIds] = useState([]);

  // Added state for copy-paste functionality
  const [editingRectId, setEditingRectId] = useState(null);
  const [copiedAnnotation, setCopiedAnnotation] = useState(null);

  // State for save progress tracking
  const [saveProgress, setSaveProgress] = useState(0);
  const [saveMessage, setSaveMessage] = useState("");

  useEffect(() => {
    const loadTaskData = async () => {
      try {
        setIsLoading(true);

        // First fetch task fields
        if (taskData?.AnnotationTaskID) {
          const fieldsResponse = await api.get(
            `/tasker-dashboard/annotation-task/${taskData.AnnotationTaskID}/full`,
            {
              params: { sessionKey, employeeKey },
            }
          );

          if (fieldsResponse.data?.data.projectFields) {
            setTaskFields(fieldsResponse.data.data.projectFields);
          }
          // Store controlled label information
          if (fieldsResponse.data?.data.controlledLabel) {
            setControlledLabelInfo(fieldsResponse.data.data.controlledLabel);
          }

          // Then fetch existing annotations
          const annotationsResponse = await api.get(
            `/tasker-dashboard/get-annotation/${taskData.AnnotationTaskID}`,
            {
              params: { sessionKey, employeeKey },
            }
          );

          if (
            annotationsResponse.data.success &&
            annotationsResponse.data.data
          ) {
            let loadedAnnotations =
              annotationsResponse.data.data.annotations || [];

            // Process the annotations for proper display
            const processedAnnotations = loadedAnnotations.map((ann) => {
              // Use the unified fromMLFormat function for both rectangle types
              let processedAnn = fromMLFormat(ann);

              // Extract label information from formData
              if (ann.formData) {
                // For controlled category, handle directly
                if (taskData?.Category === "controlled") {
                  processedAnn.label = taskData.AnnotationLabelName;
                  processedAnn.labelId = taskData.AnnotationLabelID;
                } else {
                  // For all other categories, find the label from form data
                  // Usually the first field (with ID "1") contains the main label
                  const labelField = Object.entries(ann.formData).find(
                    ([fieldId, data]) => data.type === "search" && data.labelId
                  );

                  if (labelField) {
                    const [fieldId, data] = labelField;
                    processedAnn.label = data.value;
                    processedAnn.labelId = data.labelId;
                  } else {
                    // No search field found, use the first field value as label
                    const firstField = Object.entries(ann.formData)[0];
                    if (firstField) {
                      const [fieldId, data] = firstField;
                      processedAnn.label = data.value || `Label ${fieldId}`;
                    } else {
                      processedAnn.label = "Unlabeled";
                    }
                  }
                }
              }

              return processedAnn;
            });

            // Also load the form data into the annotationFormData state
            const formData = {};
            loadedAnnotations.forEach((ann) => {
              if (ann.id && ann.formData) {
                formData[ann.id] = ann.formData;
              }
            });

            // Initialize all loaded annotations as UNCHANGED
            const initialStatus = {};
            processedAnnotations.forEach((ann) => {
              initialStatus[ann.id] = AnnotationStatus.UNCHANGED;
            });

            setAnnotationFormData(formData);
            setAnnotations(processedAnnotations);
            setOriginalAnnotations(
              JSON.parse(JSON.stringify(processedAnnotations))
            );
            setAnnotationStatus(initialStatus);
            setResultId(annotationsResponse.data.data.resultId);
          }
        }
      } catch (error) {
        console.error("Error loading task data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (taskData?.AnnotationTaskID) {
      loadTaskData();
    }
  }, [taskData?.AnnotationTaskID, employeeKey, sessionKey]);

  // Enhanced handler for annotation changes - track status of each annotation
  const handleAnnotationChange = (newAnnotations) => {
    // First, detect deleted annotations by comparing with previous state
    const deletedAnns = annotations.filter(
      (oldAnn) => !newAnnotations.some((newAnn) => newAnn.id === oldAnn.id)
    );

    // Process deletions if any were found
    if (deletedAnns.length > 0) {
      let newDeletedIds = [...deletedAnnotationIds];

      deletedAnns.forEach((deletedAnn) => {
        // Skip NEW annotations that were deleted before saving
        if (annotationStatus[deletedAnn.id] !== AnnotationStatus.NEW) {
          // Extract shape ID for backend deletion
          const shapeId = deletedAnn.id.split("_").slice(1).join("_");
          if (!newDeletedIds.includes(shapeId)) {
            newDeletedIds.push(shapeId);
          }
        }

        // Remove from annotation status
        setAnnotationStatus((prev) => {
          const newStatus = { ...prev };
          delete newStatus[deletedAnn.id];
          return newStatus;
        });
      });

      // Update deletedAnnotationIds if we have new deletions
      if (newDeletedIds.length > deletedAnnotationIds.length) {
        setDeletedAnnotationIds(newDeletedIds);
        setHasUnsavedChanges(true);

        // Skip the rest of the processing since we're already updating state
        setAnnotations(newAnnotations);
        return;
      }
    }

    // Original code for tracking new and modified annotations
    const newStatus = { ...annotationStatus };

    // First, identify new annotations
    newAnnotations.forEach((ann) => {
      if (!annotationStatus[ann.id]) {
        // This is a new annotation
        newStatus[ann.id] = AnnotationStatus.NEW;
      }
    });

    // Next, identify modified annotations
    newAnnotations.forEach((ann) => {
      if (newStatus[ann.id] === AnnotationStatus.UNCHANGED) {
        // Check if it's been modified
        const original = originalAnnotations.find((orig) => orig.id === ann.id);
        if (original) {
          const annCopy = { ...ann };
          const origCopy = { ...original };

          // Remove properties we don't want to compare
          delete annCopy._tempId;
          delete origCopy._tempId;

          if (JSON.stringify(annCopy) !== JSON.stringify(origCopy)) {
            newStatus[ann.id] = AnnotationStatus.MODIFIED;
          }
        }
      }
    });

    // Update state
    setAnnotations(newAnnotations);
    setAnnotationStatus(newStatus);

    // Check if we have any non-UNCHANGED annotations or deleted annotations
    const hasChanges =
      Object.values(newStatus).some(
        (status) => status !== AnnotationStatus.UNCHANGED
      ) || deletedAnnotationIds.length > 0;

    setHasUnsavedChanges(hasChanges);
  };

  // Enhanced handler for form data updates - mark annotation as modified
  const handleFormDataUpdate = (annotationId, formData) => {
    setAnnotationFormData((prev) => ({
      ...prev,
      [annotationId]: formData,
    }));

    // Mark this annotation as modified if it wasn't new
    if (annotationStatus[annotationId] === AnnotationStatus.UNCHANGED) {
      setAnnotationStatus((prev) => ({
        ...prev,
        [annotationId]: AnnotationStatus.MODIFIED,
      }));
      setHasUnsavedChanges(true);
    }
  };

  // Enhanced delete handler to track deleted annotations
  const handleDelete = () => {
    // Get the current editing ID
    const currentEditingId = editingRectId;

    if (!currentEditingId) {
      return;
    }

    // Instead of trying to manage deletion tracking here,
    // let the canvas delete the annotation, and let handleAnnotationChange
    // detect and track the deletion automatically
    if (canvasRef.current && canvasRef.current.handleDelete) {
      canvasRef.current.handleDelete();
    }

    // We don't need to set hasUnsavedChanges here because
    // handleAnnotationChange will do that for us
  };

  // Handler for receiving info about the currently editing rectangle
  const handleEditingRectChange = (rectId) => {
    setEditingRectId(rectId);
  };

  // Handler for copying an annotation
  const handleCopyAnnotation = (annotationWithFormData) => {
    setCopiedAnnotation(annotationWithFormData);
  };

  // Process annotations for backend format
  const processAnnotation = (annotation) => {
    let mlFormat;
    if (annotation.isRotatable) {
      mlFormat = toRotatableMLFormat(annotation);
    } else {
      mlFormat = toMLFormat(annotation);
    }

    // For controlled category, create a structured formData
    let formData = {};

    if (taskData?.TaskCategory === "controlled") {
      formData = {
        1: {
          type: "label",
          value: controlledLabelInfo?.AnnotationLabelName,
          labelId: taskData.CategoryLabelID,
        },
      };
    } else {
      formData = annotationFormData[annotation.id] || {};
    }

    return {
      ...mlFormat,
      formData: formData,
      type: annotation.isRotatable ? "rotatable-rectangle" : "rectangle",
      labelId:
        taskData?.TaskCategory === "controlled"
          ? taskData.CategoryLabelID
          : annotation.labelId,
      label:
        taskData?.TaskCategory === "controlled"
          ? controlledLabelInfo?.AnnotationLabelName
          : annotation.label,
    };
  };

  // New optimized save function that only sends changes
  const handleOptimizedSave = async () => {
    try {
      setIsSaving(true);
      setSaveMessage("Preparing to save annotations...");
      setSaveProgress(0);

      // Collect annotations that need to be sent to the server
      const annotationsToSend = annotations.filter(
        (ann) =>
          annotationStatus[ann.id] === AnnotationStatus.NEW ||
          annotationStatus[ann.id] === AnnotationStatus.MODIFIED
      );

      // Important check: Even if there are no annotation changes, we should still save if there are deletions
      if (annotationsToSend.length === 0 && deletedAnnotationIds.length === 0) {
        setIsSaving(false);
        return;
      }

      // Process only the annotations that changed
      const processedAnnotations = annotationsToSend.map(processAnnotation);

      const dimensions = {
        imageWidth: canvasRef.current?.getImageDimensions()?.width,
        imageHeight: canvasRef.current?.getImageDimensions()?.height,
      };

      // Prepare delta update payload
      const deltaPayload = {
        taskId: taskData.AnnotationTaskID,
        annotations: processedAnnotations,
        deletedShapeIds: deletedAnnotationIds,
        ...dimensions,
      };

      let response;

      // Use the delta update endpoint for direct saving
      response = await api.post(
        "/tasker-dashboard/save-annotation-delta",
        deltaPayload,
        {
          params: { sessionKey, employeeKey },
        }
      );

      // Update state after successful save
      if (response.data.success) {
        // Update the annotation status - all are now UNCHANGED
        const newStatus = {};
        annotations.forEach((ann) => {
          newStatus[ann.id] = AnnotationStatus.UNCHANGED;
        });

        setAnnotationStatus(newStatus);
        setOriginalAnnotations(JSON.parse(JSON.stringify(annotations)));
        setDeletedAnnotationIds([]); // Clear the deletion list after successful save
        setHasUnsavedChanges(false);
        setSaveMessage("All changes saved successfully!");
        setTimeout(() => setSaveMessage(""), 2000);
      }
    } catch (error) {
      console.error("Error in optimized save:", error);
      setSaveMessage("Error saving annotations. Please try again.");
    } finally {
      setIsSaving(false);
      setSaveProgress(0);
    }
  };

  const handleCommit = async () => {
    try {
      setIsSaving(true);
      setSaveMessage("Committing annotations...");

      // If there are unsaved changes, save them first
      if (hasUnsavedChanges) {
        await handleOptimizedSave();
      }

      // Now commit without sending annotations again
      setSaveMessage("Finalizing task...");
      const response = await api.post(
        "/tasker-dashboard/commit-annotation",
        {
          taskId: taskData.AnnotationTaskID,
          resultId: resultId,
          latitude,
          longitude,
        },
        {
          params: { sessionKey, employeeKey },
        }
      );

      if (response.data.success) {
        onComplete(taskData.AnnotationTaskID);
      }
    } catch (error) {
      console.error("Error committing annotations:", error);
      setSaveMessage("Error committing task. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleToolbarAction = (action) => {
    switch (action) {
      case "draw":
        // Toggle regular drawing, turn off rotatable
        setIsDrawing(!isDrawing);
        setIsRotatableDrawing(false);
        break;
      case "draw-rotatable":
        // Toggle rotatable drawing, turn off regular
        setIsRotatableDrawing(!isRotatableDrawing);
        setIsDrawing(false);
        break;
      case "reset":
        if (canvasRef.current) {
          canvasRef.current.handleReset();
        }
        break;
      case "copy":
        // Pass the copy action to the canvas component
        if (canvasRef.current && canvasRef.current.handleCopy) {
          canvasRef.current.handleCopy();
        }
        break;
      case "paste":
        // Pass the paste action to the canvas component
        if (canvasRef.current && canvasRef.current.handlePaste) {
          canvasRef.current.handlePaste();
        }
        break;
      case "delete":
        // Use our enhanced delete handler
        handleDelete();
        break;
      default:
        break;
    }
  };

  // Updated function to check if commit should be enabled
  const canCommit = () => {
    // Only enable commit if:
    // 1. There are annotations
    // 2. There are NO unsaved changes
    return (
      annotations.length > 0 &&
      !hasUnsavedChanges &&
      deletedAnnotationIds.length === 0
    );
  };

  if (isLoading) {
    return (
      <EditorContainer>
        <LoadingOverlay>Loading annotations...</LoadingOverlay>
      </EditorContainer>
    );
  }

  return (
    <EditorContainer>
      <Header>
        <h1>{taskData?.AnnotationTaskName || "Image Annotation"}</h1>
      </Header>

      <MainContent>
        <AnnotationToolbar
          onAction={handleToolbarAction}
          isDrawing={isDrawing}
          isRotatableDrawing={isRotatableDrawing}
          category={taskData?.Category}
          labelName={taskData?.AnnotationLabelName}
          hasSelectedAnnotation={!!editingRectId}
          hasCopiedAnnotation={!!copiedAnnotation}
        />

        <AnnotationCanvas
          ref={canvasRef}
          imageUrl={taskData?.ImageURL}
          isDrawing={isDrawing}
          isRotatableDrawing={isRotatableDrawing}
          setIsDrawing={setIsDrawing}
          setIsRotatableDrawing={setIsRotatableDrawing}
          annotations={annotations}
          onAnnotationsChange={handleAnnotationChange}
          category={taskData?.TaskCategory}
          labelName={
            taskData?.TaskCategory === "controlled"
              ? controlledLabelInfo?.AnnotationLabelName
              : taskData?.AnnotationLabelName
          }
          labelId={
            taskData?.TaskCategory === "controlled"
              ? taskData?.CategoryLabelID
              : taskData?.AnnotationLabelID
          }
          taskFields={taskFields}
          projectId={taskData?.ProjectID}
          annotationFormData={annotationFormData}
          onFormDataUpdate={handleFormDataUpdate}
          onEditingRectChange={handleEditingRectChange}
          onCopyAnnotation={handleCopyAnnotation}
          copiedAnnotation={copiedAnnotation}
        />
      </MainContent>

      {isSaving && (
        <LoadingOverlay>
          {saveMessage}
          {saveProgress > 0 && (
            <>
              <ProgressContainer>
                <ProgressBar $progress={`${saveProgress}%`} />
              </ProgressContainer>
              <ProgressText>{Math.round(saveProgress)}% complete</ProgressText>
            </>
          )}
        </LoadingOverlay>
      )}

      <Footer>
        <ButtonGroup>
          <Button onClick={onClose} disabled={isSaving}>
            Close
          </Button>
          <Button
            $save
            onClick={handleOptimizedSave} // Using the optimized save handler
            disabled={isSaving || !hasUnsavedChanges}
          >
            Save Draft
          </Button>
          <Button
            $primary
            onClick={handleCommit}
            disabled={isSaving || !canCommit()}
          >
            Commit
          </Button>
        </ButtonGroup>
      </Footer>
    </EditorContainer>
  );
};

export default AnnotationEditor;
