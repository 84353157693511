import React, { useState } from "react";
import styled from "styled-components";

const ToolbarContainer = styled.div`
  background: white;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const ToolButton = styled.button`
  background: ${(props) => (props.$active ? "#1b4da3" : "white")};
  color: ${(props) => (props.$active ? "white" : "#333")};
  border: 1px solid ${(props) => (props.$active ? "#1b4da3" : "#ddd")};
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:hover {
    background: ${(props) =>
      props.disabled ? "white" : props.$active ? "#1b4da3" : "#f5f5f5"};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const LabelInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  max-width: 200px;

  &:focus {
    outline: none;
    border-color: #1b4da3;
  }
`;

const LabelSelect = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  max-width: 200px;

  &:focus {
    outline: none;
    border-color: #1b4da3;
  }
`;

const RectangleIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="3" y="3" width="18" height="18" rx="2" />
  </svg>
);

// Icon for rotatable box
const RotatableBoxIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect
      x="5"
      y="5"
      width="14"
      height="14"
      rx="2"
      transform="rotate(15, 12, 12)"
    />
  </svg>
);

const ResetIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M3 12a9 9 0 1 1 9 9M3 12h9" />
  </svg>
);

const CopyIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
    <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
  </svg>
);

const PasteIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M16 4h2a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h2" />
    <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
  </svg>
);

const DeleteIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
    <line x1="10" y1="11" x2="10" y2="17" />
    <line x1="14" y1="11" x2="14" y2="17" />
  </svg>
);

const AnnotationToolbar = ({
  isDrawing,
  isRotatableDrawing,
  onAction,
  category,
  labelName,
  hasSelectedAnnotation = false,
  hasCopiedAnnotation = false,
}) => {
  const [customLabel, setCustomLabel] = useState("");

  return (
    <ToolbarContainer>
      <ToolButton $active={isDrawing} onClick={() => onAction("draw")}>
        <RectangleIcon />
        Rectangle
      </ToolButton>

      <ToolButton
        $active={isRotatableDrawing}
        onClick={() => onAction("draw-rotatable")}
      >
        <RotatableBoxIcon />
        Rotatable Box
      </ToolButton>

      <ToolButton onClick={() => onAction("reset")}>
        <ResetIcon />
        Reset View
      </ToolButton>

      <ToolButton
        onClick={() => onAction("copy")}
        disabled={!hasSelectedAnnotation}
        title="Copy selected annotation (Ctrl+C)"
      >
        <CopyIcon />
        Copy
      </ToolButton>

      <ToolButton
        onClick={() => onAction("paste")}
        disabled={!hasCopiedAnnotation}
        title="Paste annotation (Ctrl+V)"
      >
        <PasteIcon />
        Paste
      </ToolButton>

      {/* Add Delete Button */}
      <ToolButton
        onClick={() => onAction("delete")}
        disabled={!hasSelectedAnnotation}
        title="Delete selected annotation (Delete)"
      >
        <DeleteIcon />
        Delete
      </ToolButton>

      {category === "discover" && (
        <LabelInput
          type="text"
          placeholder="Enter label..."
          value={customLabel}
          onChange={(e) => setCustomLabel(e.target.value)}
        />
      )}

      {category === "controlled" && (
        <div style={{ fontSize: "14px", color: "#666" }}>
          Label: <strong>{labelName}</strong>
        </div>
      )}
    </ToolbarContainer>
  );
};

export default AnnotationToolbar;
