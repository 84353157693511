import React from "react";
import Modal from "react-modal";
import styled from "styled-components";

Modal.setAppElement("#root");

const ModalContainer = styled.div`
  position: relative;
`;

const ModalHeader = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 8px 8px 0 0;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 4px 8px;
  line-height: 1;
  &:hover {
    color: #333;
  }
`;

const ModalBody = styled.div`
  padding: 24px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 14px;
    color: #666;
    font-weight: 500;
  }

  p {
    margin: 0;
    color: #333;
  }
`;

const LoadingText = styled.div`
  text-align: center;
  padding: 32px 0;
  color: #666;
`;

const ErrorText = styled.div`
  text-align: center;
  padding: 32px 0;
  color: #666;
`;

const RejectedTaskModal = ({ isOpen, onClose, taskDetails, loading }) => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      zIndex: 1000,
      backdropFilter: "blur(8px)",
      WebkitBackdropFilter: "blur(8px)",
    },
    content: {
      position: "relative",
      background: "#fff",
      borderRadius: "8px",
      width: "100%",
      maxWidth: "560px",
      margin: "0 auto",
      padding: "0",
      inset: "auto",
      border: "none",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>Task Details</ModalTitle>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>

        <ModalBody>
          {loading ? (
            <LoadingText>Loading...</LoadingText>
          ) : taskDetails ? (
            <DetailsContainer>
              <DetailItem>
                <label>Description</label>
                <p>{taskDetails.description}</p>
              </DetailItem>

              <DetailItem>
                <label>Your Recording</label>
                {taskDetails.TaskUrl && (
                  <audio controls src={taskDetails.TaskUrl}>
                    Your browser does not support the audio element.
                  </audio>
                )}
              </DetailItem>

              <DetailItem>
                <label>Location</label>
                <p>
                  Latitude: {taskDetails.latitude}
                  <br />
                  Longitude: {taskDetails.longitude}
                </p>
              </DetailItem>

              <DetailItem>
                <label>Completed At</label>
                <p>
                  {new Date(parseInt(taskDetails.Timestamp)).toLocaleString(
                    "en-GB",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    }
                  )}
                </p>
              </DetailItem>
            </DetailsContainer>
          ) : (
            <ErrorText>No details available</ErrorText>
          )}
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};
export default RejectedTaskModal;