import surveyTypes from "../../actions/ImageTask/types";

export function imageTaskDataList(state = [], action) {
    switch (action.type) {
        case surveyTypes.FETCH_IMAGE_TASK_DATA: {
            if (action.nextCursor) {
                return [...state, ...action.payload];
            }
            return [...action.payload];
        }
        case surveyTypes.UPDATE_IMAGE_TASK_DATA: {
            const { imageTasksID, taskUrl } = action.payload;
            const newState = [...state];
            const newData = newState.find((survey) => survey.ImageTasksID == imageTasksID);
            if (newData) {
                newData.imageUrl = taskUrl;
            }
            return newState;
        }
        default:
            return state;
    }
}

export function rejectedImageTaskDataList(state = [], action) {
    switch (action.type) {
        case surveyTypes.FETCH_REJECTED_IMAGE_TASK_DATA: {
            if (action.nextCursor) {
                return [...state, ...action.payload];
            }
            return [...action.payload];
        }
        case surveyTypes.UPDATE_REJECTED_IMAGE_TASK_DATA: {
            const { imageTasksID, taskUrl } = action.payload;
            const newState = [...state];
            const newData = newState.find((survey) => survey.ImageTasksID == imageTasksID);
            if (newData) {
                newData.imageUrl = taskUrl;
            }
            return newState;
        }
        default:
            return state;
    }
}