import { combineReducers } from "redux";
import { surveyQuestionDataList, surveyRejectedQuestionDataList } from "./Survey/index";
import { imageTaskDataList, rejectedImageTaskDataList } from "./ImageTask/index";

export default combineReducers({
    surveyQuestionDataList,
    surveyRejectedQuestionDataList,
    imageTaskDataList,
    rejectedImageTaskDataList,
});
