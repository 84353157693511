import React, { useState, useEffect } from "react";
import moment from "moment";
import api from "../../../api";
import "./UserRatingsDashboardStyles.css";

const NotificationCard = ({ notification }) => (
  <div
    className={`notification-card task-card ${
      notification.readAt ? "read" : "unread"
    }`}
  >
    <div className="notification-content">
      <p className="notification-message">
        <span className="comment-label">Message:</span> {notification.message}
      </p>
      {notification.additionalMessage && (
        <p className="notification-additional">
          {notification.additionalMessage}
        </p>
      )}
      <p className="notification-date">
        <span className="comment-label">Date:</span>{" "}
        {moment(notification.createdAt).format("DD/MM/YYYY, h:mm A")}
      </p>
    </div>
  </div>
);

const NotificationsContent = () => {
  const sessionKey = localStorage.getItem("sessionKey");
  const [notifications, setNotifications] = useState([]);
  const [notificationsMarkedAsRead, setNotificationsMarkedAsRead] =
    useState(false);

  const markNotificationsAsRead = async () => {
    try {
      const response = await api.post(
        "/tasker-dashboard/notifications/mark-as-read",
        {},
        {
          params: { sessionKey },
          headers: { "Cache-Control": "no-cache" },
        }
      );

      if (response.status === 200) {
        setNotificationsMarkedAsRead(true);
      }
    } catch (err) {
      console.error("Error marking notifications as read:", err);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await api.get("/tasker-dashboard/notifications", {
        params: { sessionKey },
        headers: { "Cache-Control": "no-cache" },
      });
      setNotifications(response.data);

      if (response.data.length > 0 && !notificationsMarkedAsRead) {
        setTimeout(() => {
          markNotificationsAsRead();
        }, 5000);
      }
    } catch (err) {
      console.error("Error fetching notifications:", err);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  if (!notifications.length) {
    return <div className="no-tasks">No notifications found</div>;
  }

  return (
    <div className="notifications-section">
      <h2 className="section-title">Notifications</h2>
      <div className="notifications-list">
        {notifications.map((notification) => (
          <NotificationCard
            key={notification.notificationId}
            notification={notification}
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationsContent;
