// src/services/notificationService.ts
import { getToken, onMessage } from "firebase/messaging";
import api from "../api";
import { messaging, analytics } from "../config/firebaseConfig";
import { logEvent } from "firebase/analytics";

const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

// Check if the browser supports Firebase messaging
const isFirebaseSupported = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  return !isSafari && !isIOS && "Notification" in window;
};

// Generate device identifier
function generateDeviceId() {
  const userAgent = navigator.userAgent;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const pixelRatio = window.devicePixelRatio;
  const platform = navigator.platform;

  return btoa(
    `${userAgent}-${screenWidth}-${screenHeight}-${pixelRatio}-${platform}`
  );
}

// Get stored FCM token
function getStoredFCMToken() {
  if (!isFirebaseSupported()) return null;

  const deviceId = generateDeviceId();
  const storedData = localStorage.getItem(`fcm_token_${deviceId}`);
  return storedData ? JSON.parse(storedData) : null;
}

// Store FCM token
function storeFCMToken(token) {
  if (!isFirebaseSupported()) return;

  const deviceId = generateDeviceId();
  localStorage.setItem(
    `fcm_token_${deviceId}`,
    JSON.stringify({
      token,
      timestamp: Date.now(),
    })
  );
}

async function sendTokenToServer(token) {
  if (!isFirebaseSupported()) return;

  try {
    const sessionKey = localStorage.getItem("sessionKey");
    if (!sessionKey) {
      console.error("No session key found");
      return;
    }

    await api.post("/tasker-dashboard/save-fcm-token", {
      token,
      sessionKey,
    });

    logEvent(analytics, "fcm_token_registered", {
      deviceId: generateDeviceId(),
    });

    storeFCMToken(token);
  } catch (error) {
    console.error("Error saving FCM token:", error);
    logEvent(analytics, "fcm_token_registration_failed", {
      deviceId: generateDeviceId(),
      error: error.message,
    });
  }
}

function setupForegroundHandler() {
  if (!isFirebaseSupported()) return;

  onMessage(messaging, (payload) => {
    if (Notification.permission === "granted" && payload.notification) {
      const { title, body } = payload.notification;

      logEvent(analytics, "notification_received", {
        title: title,
        messageId: payload.data?.messageId,
      });

      const notification = new Notification(title || "New Notification", {
        body,
        icon: "../../public/logo3.ico",
        data: payload.data,
      });

      notification.onclick = () => {
        logEvent(analytics, "notification_clicked", {
          title: title,
          messageId: payload.data?.messageId,
        });
      };
    }
  });
}

async function setupFCM() {
  if (!isFirebaseSupported()) return false;

  try {
    const storedToken = getStoredFCMToken();
    const currentToken = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration:
        await navigator.serviceWorker.getRegistration(),
    });

    if (currentToken) {
      if (!storedToken || storedToken.token !== currentToken) {
        await sendTokenToServer(currentToken);
      }
      setupForegroundHandler();
      return true;
    }
    console.error("Failed to get FCM token");
    return false;
  } catch (error) {
    console.error("Error setting up FCM:", error);
    return false;
  }
}

async function initializeNotifications() {
  if (!isFirebaseSupported()) {
    console.log("Firebase messaging not supported in this browser");
    return false;
  }

  try {
    if (Notification.permission === "granted") {
      return await setupFCM();
    } else if (Notification.permission !== "denied") {
      const permission = await Notification.requestPermission();

      logEvent(analytics, "notification_permission_response", {
        permission: permission,
      });

      if (permission === "granted") {
        return await setupFCM();
      }
    }
    return false;
  } catch (error) {
    console.error("Error initializing notifications:", error);
    logEvent(analytics, "notification_initialization_error", {
      error: error.message,
    });
    return false;
  }
}

async function checkNotificationPermission() {
  if (!isFirebaseSupported()) return false;
  return Notification.permission === "granted";
}

export const notificationService = {
  initialize: initializeNotifications,
  checkPermission: checkNotificationPermission,
  getStoredToken: getStoredFCMToken,
  isSupported: isFirebaseSupported,
};
