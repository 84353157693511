import types from "./types"; // Adjust the path as needed
import { getURL } from "../../commons/commons";
import api from '../../api';

export function getSurveyQuestionData(params) {
    const url = getURL("/api/audio-task", params);
    return async function (dispatch) {
        if (!params.nextCursor) {
            dispatch({ type: types.FETCH_SURVEY_QUESTION_DATA, payload: [], nextCursor: null });
        }
        const res = await api.get(url);
        dispatch({
            type: types.FETCH_SURVEY_QUESTION_DATA,
            payload: res.data.data,
            nextCursor: params.nextCursor,
        });
        return res;
    };
}

export function updateSurveyQuestionData(data) {
    return async function (dispatch) {
        const res = await api.post("/api/audio-survey-result", data);
        dispatch({ type: types.UPDATE_SURVEY_QUESTION_DATA, payload: data });
        return res;
    };
}

export function getSurveyRejectedQuestionData(params) {
    const url = getURL("/api/audio-task-rejected", params);
    return async function (dispatch) {
        if (!params.nextCursor) {
            dispatch({ type: types.FETCH_SURVEY_QUESTION_DATA, payload: [], nextCursor: null });
        }
        const res = await api.get(url);
        dispatch({
            type: types.FETCH_SURVEY_REJECTED_QUESTION_DATA,
            payload: res.data.data,
            nextCursor: params.nextCursor,
        });
        return res;
    };
}

export function updateSurveyRejectedQuestionData(data) {
    return async function (dispatch) {
        const res = await api.post("/api/audio-survey-result", data);
        dispatch({ type: types.UPDATE_SURVEY_REJECTED_QUESTION_DATA, payload: data });
        return res;
    };
}